import { createView, session } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { meta } from '@donkeyjs/proxy';
import styles from './ViewArtist.module.css';

export const ViewArtistThumbnail = createView<DataSchema, 'Artist'>(
  { name: () => 'Thumbnail' },

  function ViewArtistThumbnail(props) {
    return () =>
      !props.readonly || meta(props.node).isLoading || props.node.image ? (
        <a
          class={styles.artist}
          href={bind(() =>
            session.router.getPath(
              { route: 'current' },
              {
                updateQuery: {
                  artists: [props.node.id],
                },
              },
            ),
          )}
        >
          <div class={styles.image}>
            {() =>
              !meta(props.node).isLoading && <props.node.$.image ratio={1} />
            }
          </div>
          <div class={styles.name}>
            <props.node.$.name readonly />
          </div>
        </a>
      ) : null;
  },
);
