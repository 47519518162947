import { getTheme, type BlockProps } from '@donkeyjs/client';
import { bind } from '@donkeyjs/proxy';
import styles from './TiqqBlock.module.css';

export interface TiqqSettings {
  tiqqId?: string;
}

export function TiqqBlock(props: BlockProps<TiqqSettings>) {
  const theme = getTheme();

  const onmount = (el: HTMLIFrameElement) => {
    function listener(e: MessageEvent) {
      if ('heightChange' === e.data.type) {
        el.style.height = `${e.data.content}px`;
        el.scrolling = 'no';
      }
    }

    window.addEventListener('message', listener, false);

    return () => {
      window.removeEventListener('message', listener);
    };
  };

  return (
    <div
      class={bind(() => [props.class, theme.class.textBlocks])}
      onmount={props.onmount}
    >
      {() =>
        props.tiqqId ? (
          <iframe
            title="Tiqq"
            class={styles.frame}
            onmount={onmount}
            src={bind(
              () =>
                `https://kiwi.tiqq.io/shop/${props.tiqqId}?lang=nl&accent-color=ae9a63&accent-prime-color=ae9a63`,
            )}
          />
        ) : (
          <div>Vul het ID van de webshop in (zie Properties panel)</div>
        )
      }
    </div>
  );
}
