import { jsxx } from '@donkeyjs/jsx-runtime';
import {
  store,
  type DataNode,
  type NodeFieldInfo,
  type NodeTypename,
} from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { HtmlString } from '../string/HtmlString';

export function HtmlFloat(props: FieldRenderProps<'float'>) {
  return jsxx(
    HtmlString,
    store.clone(props, {
      field: {
        ...props.field,
        get value() {
          return props.field.value?.toString() ?? '';
        },
        set value(value) {
          props.field.value =
            value === '' ? undefined : Number.parseFloat(value);
        },
      } as NodeFieldInfo<
        string,
        DataNode<DataSchema, NodeTypename<DataSchema>>
      >,
    }),
  );
}
