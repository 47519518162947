import { Label, getTheme, type BlockProps } from '@donkeyjs/client';
import styles from './MailchimpBlock.module.css';

export function MailchimpBlock(props: BlockProps) {
  const theme = getTheme();

  return (
    <div class={[styles.mailchimp, props.class]} onmount={props.onmount}>
      <form
        action="https://muzevanzuid.us17.list-manage.com/subscribe/post?u=5677a65f184a4338d971e2ee0&amp;id=4eaf99a8e2&amp;f_id=005a7ee0f0"
        class="mailchimp-form"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
      >
        <div class="mc-field-group">
          <Label label="Onze nieuwsbrief ontvangen? Laat hier je e-mailadres achter.">
            <input
              type="email"
              name="EMAIL"
              class={[theme.class.input, styles.input]}
              id="mce-EMAIL"
              required
              value=""
            />
          </Label>
        </div>
        <div id="mce-responses" class="clear">
          <div
            class="response"
            id="mce-error-response"
            style="display: none;"
          />
          <div
            class="response"
            id="mce-success-response"
            style="display: none;"
          />
        </div>
        <div aria-hidden="true" style="position: absolute; left: -5000px;">
          <input
            type="text"
            name="b_5677a65f184a4338d971e2ee0_4eaf99a8e2"
            tabindex={-1}
            value=""
          />
        </div>
        <div class="clear">
          <input
            type="submit"
            name="subscribe"
            id="mc-embedded-subscribe"
            class={[styles.button, theme.class.button, 'default']}
            value="Inschrijven"
          />
        </div>
      </form>
    </div>
  );
}
