import {
  bindContext,
  getGlobal,
  live,
  onMount,
  setState,
  type Component,
} from '@donkeyjs/jsx-runtime';
import {
  bind,
  meta,
  type Culture,
  type DataNode,
  type Schema,
} from '@donkeyjs/proxy';
import type { InstallablePlugin } from '../apps/bootClientApp';
import { getI18nCultures, setI18n } from '../i18n/getI18n';
import { getNodeContextProviders } from '../layout';
import { Spinner } from '../loaders/Spinner';
import { getMailContext } from '../mail';
import { session } from '../session';
import { LoginDialog } from './LoginDialog';
import { setUserContext } from './getUserContext';

const key = Symbol('backOfficeInstalled');

export function Authentication(props: { readonly children?: JSX.Children }) {
  const mail = getMailContext();

  const backOfficeInstalled = getGlobal(key, () => ({ value: false }));

  const user = mail?.target === 'mail' ? null : session.data.getUser();

  meta(user?.[0])?.request({
    email: true,
    ui: true,
    uiCulture: true,
    theme: true,
    openedChangelogAt: true,
    createdAt: true,
    lastLogin: true,
  });

  const cultures = getI18nCultures();
  setI18n({
    get culture() {
      return cultures.culture;
    },
    get userCulture() {
      return (user?.[0]?.uiCulture as Culture) || cultures.userCulture;
    },
  });

  const state = setState({
    loginRequested: false,
    get isLoginDialogOpen() {
      return state.loginRequested || !!state.confirmRequested;
    },
    confirmRequested: undefined as ((result: boolean) => void) | undefined,
    isWorking: false,
  });

  const context = setUserContext({
    user,
    confirm: async () => {
      return new Promise((resolve) => {
        state.confirmRequested = resolve;
      });
    },
  });

  getNodeContextProviders().register('user', {
    name: () => 'User',
    get: () => user?.[0] as DataNode<Schema>,
  });

  const install = bindContext(
    (backOffice: Required<InstallablePlugin>, plugins: (() => void)[]) => {
      backOffice.install();
      for (const installer of plugins) {
        installer();
      }
    },
  );

  const installBackoffice = async () => {
    if (session.app.backOffice) {
      const [backOffice, ...plugins] = await Promise.all([
        await session.app.backOffice.load(),
        ...(session.app.backOffice.plugins || []).map((loader) => loader()),
      ]);
      const pluginComponents: Component<{
        readonly children?: JSX.Children;
      }>[] = [];
      const pluginInstallers: (() => void)[] = [];
      for (const plugin of plugins) {
        if (typeof plugin === 'function') {
          pluginComponents.push(plugin);
        } else {
          if (plugin.install) pluginInstallers.push(plugin.install);
          if (plugin.plugin) pluginComponents.push(plugin.plugin);
        }
      }
      session.app.plugins.backOffice = pluginComponents;
      install(backOffice, pluginInstallers);
      session.app.addPlugin(backOffice.plugin);
    }
  };

  onMount(() => {
    live(() => {
      if (!session.dom.ssr && context.isAdmin && !backOfficeInstalled.value) {
        backOfficeInstalled.value = true;
        installBackoffice().catch((err) => {
          console.error(
            'An error occurred while installing the backoffice.',
            err,
          );
        });
      }
    });

    live(() => {
      if (session.router.lastMutation.loginRequested) {
        state.loginRequested = true;
      }
    });
  });

  const goHome = () => session.router.navigate('/', { replace: true });

  // const logInOut = () => {
  //   if (!context.isLoggedIn) {
  //     isLoginDialogOpen = true;
  //   } else if ($user && !meta($user).isLoading) {
  //     isWorking = true;
  //     context.logout();
  //   }
  // };

  // useTools(
  //   () => ({
  //     tools: {
  //       logInOut: {
  //         name: <I18n.$$.System.Account.ActionLogin />,
  //         shortcut: 'mod+shift+l',
  //         fn: logInOut,
  //       },
  //     },
  //   }),
  //   [user],
  // );

  onMount(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key?.toLowerCase() === 'l' &&
        (event.metaKey || event.ctrlKey) &&
        event.shiftKey
      ) {
        event.preventDefault();
        if (context.isLoggedIn) {
          state.isWorking = true;
          context.logout();
        } else {
          state.loginRequested = true;
        }
      } else if (event.key === 'Escape' && state.isLoginDialogOpen) {
        event.preventDefault();
        state.loginRequested = false;
      }
    };
    session.dom.body.addEventListener('keydown', handleKeyDown as any);
    return () =>
      session.dom.body.removeEventListener('keydown', handleKeyDown as any);
  });

  return (
    <>
      {() => !!state.isWorking && <Spinner floating delay={false} />}
      {() =>
        state.isLoginDialogOpen && (
          <LoginDialog
            isConfirm={bind(() => !!state.confirmRequested)}
            onLogin={async (login) => {
              if (state.confirmRequested) {
                const result = await context.login(login, true);
                if (result) return result;
                state.confirmRequested(true);
                state.confirmRequested = undefined;
                return;
              }

              if (session.router.pathname === '/') goHome();
              return context.login(login);
            }}
            onClose={() => {
              if (state.confirmRequested) {
                state.confirmRequested(false);
                state.confirmRequested = undefined;
                return;
              }

              state.loginRequested = false;
              if (session.router.pathname === '/') goHome();
            }}
          />
        )
      }
      {props.children}
    </>
  );
}
