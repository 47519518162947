import { jsxx } from '@donkeyjs/jsx-runtime';
import { bind } from '@donkeyjs/proxy';
import type { BlockProps } from '..';
import { getLayoutContext, setLayout } from '../layout/layoutContext';
import { getNodeContext } from '../layout/nodeContext';

export interface NodeFieldBlockProps {
  layout?: 'html' | 'inline';
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
}

export function NodeFieldBlock(props: BlockProps<NodeFieldBlockProps>) {
  return () => jsxx(NodeField, props);
}

function NodeField(props: BlockProps<NodeFieldBlockProps>) {
  const layoutContext = getLayoutContext();
  const node = getNodeContext();

  if (props.layout) {
    setLayout(
      [props.layout, layoutContext.wrap].filter(Boolean).join(':') as any,
    );
  }

  return () => {
    const field = props.block?.type?.split(':')[1];
    if (!field || !node?.fields.includes(field)) return null;

    const Field = (node.node?.$ as any)[field];
    return (
      Field && (
        <Field
          onmount={props.onmount}
          class={bind(() => (props.width ? `width-${props.width}` : ''))}
        />
      )
    );
  };
}
