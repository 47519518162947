import type { RouterPlugin } from '@donkeyjs/core';
import slug from 'slug';

export const FestivalRouterPlugin: RouterPlugin = {
  decode(values) {
    const { route, segments, query } = values;
    if (segments[0] !== 'edition' || !segments[1]) return values;

    return {
      route,
      segments: segments.slice(2),
      query: {
        ...query,
        edition: [segments[1]],
      },
    };
  },

  encode(values) {
    const { route, query } = values;
    const { edition, ...restQuery } = query;

    if (!edition) return values;

    return {
      route,
      segments: ['edition', slug(edition[0]), ...values.segments],
      query: restQuery,
    };
  },

  // routesLoaded(router) {
  //   router
  //     .getRouteByKey('home')
  //     ?.testValues({ hidden: true, sortIndex: 999 });
  // },
};
