import type { NodeTypename } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { session } from '../../../session';
import type { ViewContext, ViewType } from '../../../views';

export const getView = (
  p: FieldRenderProps<'node'> | FieldRenderProps<'nodeList'>,
  viewContext: ViewContext | undefined,
) =>
  (session.app.views?.[p.field.schema?.type as NodeTypename<DataSchema>]?.[
    (p.view as keyof typeof session.app.views) ??
      viewContext?.preferredListView ??
      'default'
  ] as ViewType | undefined) ||
  (session.app.views?.[p.field.schema?.type as NodeTypename<DataSchema>]
    ?.default as ViewType | undefined);
