import { bind, jsxx, live } from '@donkeyjs/jsx-runtime';
import { store } from '@donkeyjs/proxy';
import { createPopper, type Instance } from '@popperjs/core';
import { NativeMenu, type NativeMenuProps } from '../../donkey';
import { Portal, commands } from '../../helpers';
import { session } from '../../session';
import { getTheme } from '../../styles';
import styles from './Select.module.css';
import { SelectChips } from './SelectChips';
import { useSelect, type UseSelectProps } from './useSelect';

export interface SelectProps<T, Mapped = T> extends UseSelectProps<T, Mapped> {
  autofocus?: boolean;
  inputPrefix?: JSX.Children;
}

export function Select<T, Mapped = T>(props: SelectProps<T, Mapped>) {
  const theme = getTheme();

  const select = useSelect(props, () => {
    if (!select.isArray) state.open = false;
  });

  const state = store({
    open: false,
    get prefix() {
      if (select.isArray) return null;
      return props.value ? props.prefix?.(props.value as Mapped) : null;
    },
    inputElement: undefined as undefined | HTMLInputElement,
    popperInstance: undefined as undefined | Instance,
  });

  const menu = store<NativeMenuProps>({
    get open() {
      return state.open;
    },
    children: () => (!state.open ? [] : select.renderOptions()),
  });

  live(() => {
    if (state.open && menu.div && state.inputElement && !state.popperInstance) {
      state.popperInstance = createPopper(state.inputElement, menu.div, {
        placement: 'bottom-start',
        modifiers: [{ name: 'preventOverflow' }],
      });
    } else if (!menu.open && state.popperInstance) {
      state.popperInstance.destroy();
      state.popperInstance = undefined;
    }
  });

  live(() => {
    // Length for reactivity
    if (state.open && select.options.length >= 0) {
      state.popperInstance?.update();
    }
  });

  return (
    <>
      <span class={[styles.select, theme.class.input]}>
        {() => state.prefix}
        {() => props.inputPrefix}
        <SelectChips select={select} />
        <input
          type="text"
          class={styles.input}
          onfocus={(e: FocusEvent) => {
            (e.target as HTMLInputElement).select();
            state.open = true;
          }}
          onblur={() => {
            select.handleBlur();
            state.open = false;
          }}
          oninput={() => {
            state.open = true;
          }}
          value={bind(select, 'searchInput')}
          autofocus={props.autofocus}
          onmount={[
            (el) => {
              state.inputElement = el;
            },
            commands({
              ...select.commands,
              'common.confirm': (el) => {
                select.commands['common.confirm'](el);
                (async () => {})().then(() => {
                  (el as HTMLInputElement).select();
                });
              },
              'common.close': () => {
                state.open = false;
              },
            }),
          ]}
        />
      </span>
      <Portal parent={session.dom.body}>{jsxx(NativeMenu, menu)}</Portal>
    </>
  );
}
