import { bind } from '@donkeyjs/jsx-runtime';
import { map, store } from '@donkeyjs/proxy';
import { getTheme } from '../styles';
import styles from './CheckboxList.module.css';

interface CheckboxListProps<T> {
  values: T[];
  toggle?(value: T): void;
  readonly options: {
    label: string;
    value: T;
    count?: number;
    draft?: boolean;
  }[];
  readonly collapse?: {
    length: number;
    expandText: JSX.Children;
    collapseText: JSX.Children;
  };
  readonly unique?: boolean;
}

export function CheckboxList<T>(props: CheckboxListProps<T>) {
  const theme = getTheme();

  const state = store({
    collapsed: !!props.collapse,
    get canCollapse() {
      return props.collapse
        ? props.options.length > props.collapse.length + 3
        : false;
    },
  });

  return (
    <>
      {map(
        () =>
          state.canCollapse && state.collapsed
            ? [
                ...props.options.slice(0, props.collapse!.length),
                ...props.options
                  .slice(props.collapse!.length)
                  .filter((option) => props.values?.includes(option.value)),
              ]
            : props.options,
        (option) => (
          <label
            class={bind(() => [styles.label, { [styles.draft]: option.draft }])}
          >
            <input
              type="checkbox"
              class={styles.input}
              value={bind(
                () => props.values?.includes(option.value),
                (_) => {
                  if (props.toggle) {
                    props.toggle(option.value);
                  } else if (props.values?.includes(option.value)) {
                    props.values = props.values.filter(
                      (value) => value !== option.value,
                    );
                  } else {
                    if (props.unique) {
                      props.values = [option.value];
                    } else {
                      props.values = [...(props.values || []), option.value];
                    }
                  }
                },
              )}
            />
            <span>{option.label}</span>
            {() =>
              option.count != null && (
                <aside class={styles.aside}>{option.count}</aside>
              )
            }
          </label>
        ),
      )}
      {() =>
        state.canCollapse &&
        !!props.collapse &&
        props.options.length > props.collapse.length + 3 && (
          <div
            class={[styles.toggleCollapse, theme.class.linkButton]}
            onclick={() => (state.collapsed = !state.collapsed)}
          >
            {state.collapsed
              ? props.collapse!.expandText
              : props.collapse!.collapseText}
          </div>
        )
      }
    </>
  );
}
