import { registerInfo } from '@donkeyjs/jsx-runtime';
import {
  DataError,
  fragmentFromFieldStatus,
  meta,
  printFragment,
  type AnonymousFragment,
  type Fragment,
  type Node,
  type NodeSchema,
  type NodeTypename,
  type ResolveManySchemaWhere,
  type ResolveManySchemaWhereField,
  type ResolverSchema,
  type Schema,
  type StatusFragment,
} from '@donkeyjs/proxy';
import { pluralize } from 'inflection';
import type {
  FetchDataOptions,
  FetchDataResult,
  QueryVariable,
  QueryVariables,
} from './createDataClient';

export async function fetchRawGql(body: any) {
  let response: any;

  try {
    response = await fetch('/graphql', {
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    }).then((value) => value.json());
  } catch (error) {
    return { errors: [new DataError('fetch', error)] };
  }

  if (response.errors)
    return {
      errors: response.errors.map(
        (error: any) => new DataError(error.message, error),
      ),
    };

  return response.data;
}

export type FetchDataFunction = <
  S extends Schema,
  Typename extends NodeTypename<S>,
>(
  options: FetchDataOptions<S, Typename> & {
    process?: (data: FetchDataResult) => void;
  },
) => Promise<FetchDataResult>;

export function fetchGqlQuery(fetch = fetchRawGql): FetchDataFunction {
  return async <S extends Schema, Typename extends NodeTypename<S>>({
    fragment,
    options,
    returnsAggregation,
    forUnion,
    resolverName,
    resolver,
    nodeSchema,
    schema,
  }: FetchDataOptions<S, Typename>) => {
    const data = await fetch({
      query: `{ result: ${resolverName}${queryVariables(
        options,
        resolver,
        schema,
        nodeSchema,
      )} { ${printFragment<S, Typename>(fragment, {
        returnsAggregation,
        forUnion,
        includeDrafts: true,
        includeIds: true,
        includeTypenames: true,
      })} } }`,
    });

    return data.result ? { data: data.result } : {};
  };
}

export async function fetchGqlCustomQuery<
  S extends Schema,
  Typename extends NodeTypename<S>,
>(
  fetch: (body: any) => Promise<any>,
  queryName: string,
  args: QueryVariables,
  fragment?: Fragment<S, Typename>,
): Promise<{
  data: any;
  errors?: DataError[];
}> {
  const data = await fetch({
    query: `query { result: ${queryName}${queryVariables(args)}${
      fragment
        ? ` { ${printFragment<S, Typename>(fragment, {
            includeIds: true,
            includeTypenames: true,
            pretty: true,
          })} }`
        : ''
    } }`,
  });

  if (data.errors) return data;

  return { data: data.result };
}

export async function fetchGqlMutation<
  S extends Schema,
  Typename extends NodeTypename<S>,
>(
  fetch: (body: any) => Promise<any>,
  mutationName: string,
  args: QueryVariables,
  fragment?: Fragment<S, Typename>,
): Promise<{
  data: any;
  errors?: DataError[];
}> {
  const data = await fetch({
    query: `mutation { result: ${mutationName}${queryVariables(args)}${
      fragment
        ? ` { ${printFragment<S, Typename>(fragment, {
            includeIds: true,
            includeTypenames: true,
            pretty: true,
          })} }`
        : ''
    } }`,
  });

  if (data.errors) return data;

  return { data: data.result };
}

const queryVariables = (
  options: QueryVariables,
  resolver?: ResolverSchema,
  schema?: Schema,
  nodeSchema?: NodeSchema,
) => {
  const result = variables(options, resolver, schema, nodeSchema, true);
  return result.length ? `(${result})` : '';
};

const variables = (
  vars: QueryVariables,
  resolver?: ResolverSchema,
  schema?: Schema,
  nodeSchema?: NodeSchema,
  isRoot?: boolean,
  isWhere = false,
  isEnum = false,
): string => {
  const result: string[] = [];

  if (vars)
    for (const key in vars) {
      if (key === 'source') continue;
      const value = vars[key];
      const resolverField = resolver?.[key as keyof ResolverSchema];
      if (value !== undefined)
        result.push(
          `${key}: ${variableValue(
            value,
            resolverField,
            schema,
            nodeSchema,
            isRoot && key === 'sort',
            isWhere || (isRoot && key === 'where'),
            isEnum,
          )}`,
        );
    }

  return result.length ? `${result.join(', ')}` : '';
};

const variableValue = (
  value: QueryVariable,
  resolverField?:
    | string
    | Record<string, string>
    | ResolveManySchemaWhere<string>
    | ResolveManySchemaWhereField<string>,
  schema?: Schema,
  nodeSchema?: NodeSchema,
  isSort = false,
  isWhere = false,
  isEnum = false,
): string => {
  if (Array.isArray(value))
    return `[${value.map((value) => variableValue(value)).join(', ')}]`;
  if (value instanceof Date) return `"${value.toISOString()}"`;
  if (isEnum && typeof value === 'string') return value;
  if (
    resolverField &&
    (typeof resolverField === 'string' || Array.isArray(resolverField)) &&
    schema &&
    nodeSchema
  ) {
    let fieldNames: string[] | undefined;
    if (isWhere) {
      fieldNames = (
        typeof resolverField === 'string'
          ? resolverField
          : (resolverField[0] as string)
      ).split('.');
    }
    if (fieldNames) {
      let currentSchema = nodeSchema;
      let field = '';
      for (const fieldName of fieldNames) {
        field = fieldName;
        const nextSchema = schema.nodes[currentSchema.fields[fieldName]?.type];
        if (!nextSchema) break;
        currentSchema = nextSchema;
      }
      if (currentSchema.fields[field]?.enum)
        return typeof value === 'object'
          ? `{${variables(
              value,
              resolverField as any,
              schema,
              nodeSchema,
              false,
              isWhere,
              true,
            )}}`
          : (value as string);
    }
  }
  if (isSort && typeof value === 'string') return value;
  if (value == null || typeof value !== 'object') return JSON.stringify(value);
  if ('__literal' in value) return value.__literal as string;
  return `{${variables(
    value,
    resolverField as any,
    schema,
    nodeSchema,
    false,
    isWhere,
    isEnum,
  )}}`;
};

export function fetchLazyNodes(
  fetch: ReturnType<typeof fetchGqlQuery>,
  nodes: Node[],
) {
  const jobs: {
    [key: string]: {
      typename: string;
      ids: string[];
      fragment: AnonymousFragment;
      printedFragment: string;
      status: { [id: string]: StatusFragment };
    };
  } = {};
  for (const node of nodes) {
    const fieldStatus = meta(node).fieldStatus;
    const fragment = fieldStatus && fragmentFromFieldStatus(fieldStatus);
    if (!fragment) continue;
    const printedFragment = printFragment(fragment as any, { pretty: true });
    const key = `${node.__typename} { ${printedFragment} }`;
    jobs[key] ??= {
      fragment,
      ids: [],
      printedFragment,
      status: {},
      typename: node.__typename,
    };
    jobs[key].ids.push(node.id);
    jobs[key].status[node.id] = fieldStatus;
  }

  for (const job of Object.values(jobs)) {
    const lazyMessage = `⇄ Lazy loading ${job.ids.length} ${(job.ids.length ===
    1
      ? job.typename
      : pluralize(job.typename)
    ).toLowerCase()}`;
    console.info(lazyMessage);
    registerInfo?.('lazy', `${lazyMessage} {\n${job.printedFragment}\n}`);
    fetchLazyFieldsToCache(fetch, job);
  }
}

async function fetchLazyFieldsToCache(
  fetch: ReturnType<typeof fetchGqlQuery>,
  job: {
    typename: string;
    ids: string[];
    fragment: AnonymousFragment;
    status: { [id: string]: StatusFragment };
  },
): Promise<void> {
  try {
    const { errors } = await fetch({
      fragment: job.fragment,
      options: { typename: { __literal: job.typename }, ids: job.ids },
      resolverName: 'nodes',
      forUnion: job.typename,
    });

    // TODO: process errors
    if (errors) {
      console.error(errors);
      return;
    }
  } catch (error) {
    console.error(error);
  }
}
