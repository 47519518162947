import { Heading, createView } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import styles from './ViewArticleDetails.module.css';

export const ViewArticleDetails = createView<DataSchema, 'Article'>(
  { name: () => 'Detail' },

  function ViewArticleDetails(props) {
    return (
      <article
        class={bind(() => [styles.article, props.class])}
        onmount={props.onmount}
      >
        <div class={styles.image}>
          <props.node.$.image ratio={1.5} />
        </div>
        <div class={styles.header}>
          <div class={styles.date}>
            <props.node.$.publishedAt formatter="PPPP" />
          </div>
          <Heading styleAs="heading1">
            <props.node.$.headline />
          </Heading>
          <div class={styles.lead}>
            <props.node.$.lead />
          </div>
        </div>
        <div class={styles.body}>
          <props.node.$.body />
        </div>
      </article>
    );
  },
);
