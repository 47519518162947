import type { DataNode } from '@donkeyjs/proxy';

export const getGoogleMapsUrl = (
  place: DataNode<DataSchema, 'Place'> | null | undefined,
) => {
  if (!place?.googlePlacesId) return undefined;
  const geometry = place.geometry as any;
  if (!geometry?.location) return undefined;

  return `https://www.google.com/maps/search/?api=1&query=${geometry.location.lat},${geometry.location.lng}&query_place_id=${place.googlePlacesId}`;
};
