import { bind } from '@donkeyjs/jsx-runtime';
import type { DataNode, NodeTypename } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { Label } from '../../components/Label';
import { NodeSelect } from '../../components/NodeSelect';

export function HtmlNode(props: FieldRenderProps<'node'>) {
  if (props.mode === 'view') return 'Not implemented';

  // const user = getUserContext();

  return (
    <Label
      class={bind(() => ['input', props.class])}
      label={bind(() => props.label)}
      helper={bind(() => props.helper)}
      onmount={props.onmount}
    >
      <NodeSelect
        typename={bind(
          () => props.field.schema?.type as NodeTypename<DataSchema>,
        )}
        allowEmpty={props.field.schema?.optional}
        value={bind(
          () => props.field.value,
          (value) => {
            props.field.value = value as
              | DataNode<DataSchema>
              | null
              | undefined;
          },
        )}
        // onRequestAdd={
        //   user.can('insert', p.field.schema?.type as NodeTypename<DataSchema>) ?
        //     async () =>
        //       admin.showCreateOrEditNodeDialog?.({
        //         typename: p.field.schema?.type as NodeTypename<DataSchema>,
        //       })
        //   : undefined
        // }
      />
    </Label>
  );
}
