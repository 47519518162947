import { PhBackspace } from '@donkeyjs/phosphor-icons';
import {
  store,
  type DataNode,
  type NodeFieldInfo,
  type NodeTypename,
} from '@donkeyjs/proxy';
import { parse } from 'date-fns';
import type { FieldRenderProps } from '../..';
import { tooltip } from '../../../donkey/tooltip';
import { getI18n } from '../../../i18n/getI18n';
import { HtmlString } from '../string/HtmlString';
import { jsxx } from '@donkeyjs/jsx-runtime';

export interface DateInputProps extends FieldRenderProps<'date'> {
  onremove?: () => void;
}

export function DateInput(props: DateInputProps) {
  const i18n = getI18n();

  const state = store({
    input: undefined as string | undefined,
    get formatter() {
      return props.formatter ?? 'Pp';
    },
  });

  return jsxx(
    HtmlString,
    store.clone<FieldRenderProps<'date'>, FieldRenderProps<'string'>>(props, {
      field: {
        ...props.field,
        get value() {
          const input = state.input ?? props.field.value;
          return input != null
            ? input instanceof Date
              ? i18n.formatDate(input, state.formatter)
              : (input as string)
            : '';
        },
        set value(value) {
          const parsed = value
            ? parse(value as string, state.formatter, new Date(), {
                locale: i18n.dateLocale!,
              })
            : null;
          if (!parsed || Number.isNaN(parsed.getTime())) {
            state.input = value;
          } else {
            state.input = undefined;
            props.field.value = parsed;
          }
        },
      } as NodeFieldInfo<
        string,
        DataNode<DataSchema, NodeTypename<DataSchema>>
      >,
      get suffix() {
        return props.onremove ? (
          <button
            type="button"
            class="suffix remove"
            onclick={props.onremove}
            onmount={tooltip('No end time')}
          >
            <PhBackspace weight="duotone" />
          </button>
        ) : null;
      },
      mask: createMask(
        i18n.formatDate(new Date(3333, 10, 22, 21, 44, 55), state.formatter),
      ),
    }),
  );
}

const createMask = (sample: string) =>
  sample
    .replace('3333', `${number}${number}${number}${number}`)
    .replace('33', `${number}${number}`)
    .replace('11', `[01]${number}`)
    .replace('22', `[0123]${number}`)
    .replace('21', `[012]${number}`)
    .replace('09', `[01]${number}`)
    .replace('44', `[012345]${number}`)
    .replace('55', `[012345]${number}`)
    .replace('PM', '[AP]M')
    .replace('pm', '[ap]m');

const number = '[0123456789]';
