import { createI18nCulture } from '@donkeyjs/core';

export const I18nBlocksEnGB = createI18nCulture({
  values: {
    Actions: {
      // AlignCenter: 'Align Center',
      // AlignLeft: 'Align Left',
      // AlignRight: 'Align Right',
      // Bold: 'Bold',
      // Columns: 'Toggle Columns',
      // ConvertTo: 'Convert to #{type}',
      // Grid: 'Toggle Grid',
      // InsertEmbed: 'Embed Media',
      // InsertGridCellAfter: 'Insert Grid Cell After',
      // InsertGridCellBefore: 'Insert Grid Cell Before',
      // InsertImage: 'Insert Image',
      // InsertModule: 'Insert Module',
      // Italic: 'Italic',
      // LinkCreate: 'Create Link',
      // LinkCreateDisabled: 'Create Link (select text first)',
      // LinkEdit: 'Edit Link',
      // LinkRemove: 'Remove Link',
      // ModuleSettings: 'Module Settings',
      // ReadMore: 'Toggle Read More',
      // RemoveModule: 'Remove Module',
      // Strikethrough: 'Strikethrough',
    },
    BlockTypes: {
      CELL: 'Grid Cell',
      EMBED: 'Embed',
      H1: 'Heading 1',
      H2: 'Heading 2',
      IMAGE: 'Image',
      LAYOUT: 'Layout',
      MODULE: 'Module',
      OL: 'Ordered List',
      P: 'Paragraph',
      QUOTE: 'Quote',
      T1: 'Title',
      T2: 'Subtitle',
      UL: 'Unordered List',
    },
    Links: {
      Create: 'Create Link',
      Edit: 'Edit Link',
      Remove: 'Remove Link',
      Instructions:
        'Enter a URL or email address, or choose an item from the list.',
    },
    Markup: {
      Align: {
        Center: 'Align Center',
        Left: 'Align Left',
        Right: 'Align Right',
      },
      Bold: 'Bold',
      Italic: 'Italic',
      Strikethrough: 'Strikethrough',
    },
  },
});
