import { WithHeading, createView, session } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { PhX } from '@donkeyjs/phosphor-icons';
import styles from './ViewArtistDetails.module.css';

export const viewArtistDetails = createView<DataSchema, 'Artist'>(
  { name: () => 'Details' },

  function ViewArtistDetails(props) {
    return (
      <div class={styles.container}>
        <article class={styles.artist}>
          <WithHeading
            styleAs="title"
            heading={
              <>
                <a
                  href={bind(() =>
                    session.router.getPath({ route: 'current' }),
                  )}
                  class={styles.close}
                >
                  <PhX weight="bold" />
                </a>
                <props.node.$.name />
              </>
            }
          >
            <div class={styles.description}>
              <props.node.$.biography />
            </div>
          </WithHeading>
        </article>
      </div>
    );
  },
);
