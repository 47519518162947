import { Heading, createView, session } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { PhCaretRight } from '@donkeyjs/phosphor-icons';
import styles from './ViewArticleHome.module.css';

export const ViewArticleHome = createView<DataSchema, 'Article'>(
  { name: () => 'Home' },

  function ViewArticleHome(props) {
    return (
      <a
        class={styles.news}
        href={bind(() =>
          session.router.getPath({
            node: props.node,
            onRoute: session.router.getRouteByKey('news'),
          }),
        )}
      >
        <article class={styles.article}>
          <div class={styles.image}>
            <props.node.$.image ratio={2.5} />
          </div>
          <Heading styleAs="heading1">
            <props.node.$.headline readonly />
          </Heading>
          <div class={styles.lead}>
            <props.node.$.lead readonly />
          </div>
          <div class={styles.chevron}>
            <PhCaretRight weight="bold" />
          </div>
        </article>
      </a>
    );
  },
);
