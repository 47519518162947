import { createView } from '@donkeyjs/client';
import { Tag } from '../components/Tag';

export const ViewEventTag = createView<DataSchema, 'EventTag'>(
  { name: () => 'Default' },

  function ViewEventTag(props) {
    return <Tag>{() => props.node.tag.name}</Tag>;
  },
);
