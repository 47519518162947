import { createClientSchemaMeta } from '@donkeyjs/client';
import { getLocale } from '@donkeyjs/core';
import {
  PhMapTrifold,
  PhUserCircle,
  PhUserCirclePlus,
} from '@donkeyjs/phosphor-icons';
import { meta, type SchemaMeta } from '@donkeyjs/proxy';

export const schemaMeta: SchemaMeta<DataSchema> = {
  Artist: {
    searchVectors: {
      name: 'A',
    },
  },

  EventArtist: {
    join: {
      artist: 'event',
      event: 'artist',
    },
  },

  EventTag: {
    join: {
      tag: 'event',
      event: 'tag',
    },
  },
};

export const clientSchemaMeta = createClientSchemaMeta(schemaMeta, {
  Article: {
    asString: (node) => node.headline,
    seo: (node) => ({
      description: node.lead
        .map((block) => block.text)
        .join(' ')
        .replace(/\s+/g, ' ')
        .replace(/\r?\n/g, ' '),
    }),
  },

  Artist: {
    icon: () => PhUserCircle,
    addIcon: PhUserCirclePlus,
  },

  District: {
    icon: () => PhMapTrifold,
  },

  Event: {
    asString: (node) =>
      [getLocale(meta(node).culture).formatDate(node.starts, 'PP'), node.name]
        .filter(Boolean)
        .join(' '),
    seo: (node) => ({
      description: node.description
        .map((block) => block.text)
        .join(' ')
        .replace(/\s+/g, ' ')
        .replace(/\r?\n/g, ' '),
    }),
  },
});
