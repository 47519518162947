import type { ClientApp } from './bootClientApp';

import type { AppBase } from '@donkeyjs/core';
import { store } from '@donkeyjs/proxy';
import type { MergedModules } from '../helpers/mergeModules';

export function composeClientApp(
  appBase: AppBase,
  merged: MergedModules,
): ClientApp {
  return {
    ...appBase,
    backOffice: merged.backOffice,
    blockPresets: merged.blockPresets,
    blocks: merged.blocks,
    changelog: merged.changelog,
    clientSchemaMeta: merged.clientSchemaMeta,
    data: undefined as any,
    locales: merged.locales,
    plugins: store({ app: merged.plugins || [] }),
    theme: merged.theme,
    tz: merged.tz,
    views: merged.views,
    addPlugin(plugin) {
      this.plugins.app = [...this.plugins.app, plugin];
    },
  };
}
