import type { EventsSchemaInput } from '@donkeyjs/module-events';
import {
  type SchemaFromInput,
  type SchemaInputWithExtensions,
  createSchemaExtension,
} from '@donkeyjs/proxy';

export const festivalSchemaExtension =
  createSchemaExtension<EventsSchemaInput>()({
    nodes: {
      Edition: {
        name: ['string', { i18n: true }],
        firstDay: 'date',
        lastDay: 'date',
        published: 'boolean?',
      },
    },

    extensions: {
      Event: {
        add: {
          edition: ['Edition', { reverse: 'events' }],
        },
      },
    },

    resolvers: {
      Edition: {
        editions: {
          return: 'many',
          sort: [{ name: 'DESC', order: ['lastDay:desc'] }],
        },
      },
    },

    i18n: {
      nl: () => import('./i18n/nlNL').then((m) => m.SchemaNl),
    },
  } as const);

export type FestivalSchema = SchemaFromInput<
  SchemaInputWithExtensions<EventsSchemaInput, typeof festivalSchemaExtension>
>;
