import { baseSchema, blocksSort } from '@donkeyjs/core';
import { schemaEnGB } from '@donkeyjs/i18n-en-gb';
import { schemaNlNL } from '@donkeyjs/i18n-nl-nl';
import { eventsSchemaExtension } from '@donkeyjs/module-events';
import { festivalSchemaExtension } from '@donkeyjs/module-festival';
import { gallerySchemaExtension } from '@donkeyjs/module-gallery';
import { socialButtonsSchemaExtension } from '@donkeyjs/module-social-buttons';
import { createSchema, extendSchema } from '@donkeyjs/proxy';

const { result } = extendSchema(baseSchema)
  .with({ i18n: { 'en-GB': schemaEnGB, nl: schemaNlNL } })
  .with(eventsSchemaExtension)
  .with(festivalSchemaExtension)
  .with(socialButtonsSchemaExtension)
  .with(gallerySchemaExtension)
  .with({
    nodes: {
      Article: {
        publishedAt: 'date',
        headline: ['string', { i18n: true }],
        image: ['FileRef?', { reverse: 'articles' }],
        featured: 'boolean?',
        archived: 'boolean?',
      },

      Artist: {
        name: 'string',
        image: ['FileRef?', { reverse: 'artists' }],
      },

      District: {
        name: 'string',
      },

      EventArtist: {
        artist: ['Artist', { reverse: 'events', cascadeDelete: true }],
        event: ['Event', { reverse: 'artists', cascadeDelete: true }],
        role: ['string?', { i18n: true }],
      },

      EventTag: {
        tag: ['Tag', { reverse: 'events', cascadeDelete: true }],
        event: ['Event', { reverse: 'tags', cascadeDelete: true }],
      },
    },

    extensions: {
      Edition: {
        add: {
          accentColor: 'string?',
          archived: 'boolean?',
        },
      },

      Event: {
        add: {
          district: ['District?', { reverse: 'events' }],
          featured: 'boolean?',
          image: ['FileRef?', { reverse: 'events' }],
          linkToUrl: ['string?', { i18n: true }],
          linkToUrlText: ['string?', { i18n: true }],
        },
      },

      Block: {
        add: {
          onArticleLead: [
            'Article?',
            { reverse: 'lead', sort: blocksSort, cascadeDelete: true },
          ],
          onArticleBody: [
            'Article?',
            { reverse: 'body', sort: blocksSort, cascadeDelete: true },
          ],
          onArtistBiography: [
            'Artist?',
            { reverse: 'biography', sort: blocksSort, cascadeDelete: true },
          ],
          onDistrictDescription: [
            'District?',
            { reverse: 'description', sort: blocksSort, cascadeDelete: true },
          ],
          onEventDescription: [
            'Event?',
            { reverse: 'description', sort: blocksSort, cascadeDelete: true },
          ],
          onEventMoreInfo: [
            'Event?',
            { reverse: 'moreInfo', sort: blocksSort, cascadeDelete: true },
          ],
          onEventProgram: [
            'Event?',
            { reverse: 'program', sort: blocksSort, cascadeDelete: true },
          ],
          onEventPhotoCredits: [
            'Event?',
            { reverse: 'photoCredits', sort: blocksSort, cascadeDelete: true },
          ],
        },
      },

      FileRef: {
        add: {
          featured: 'boolean?',
          onEventGallery: [
            'Event?',
            {
              reverse: 'gallery',
              sort: [
                {
                  name: 'default',
                  order: ['sortIndex:auto'],
                },
              ],
              cascadeDelete: true,
            },
          ],
        },
      },
    },

    resolvers: {
      Article: {
        news: {
          return: 'many',
          where: {
            featured: ['featured', ['eq']],
          },
          sort: [{ name: 'DATE_DESC', order: ['createdAt:desc'] }],
        },
      },

      Event: {
        events: {
          return: 'many',
          where: {
            edition: ['edition', ['eq']],
            artists: ['artists.artist', ['in']],
            featured: ['featured', ['eq']],
            tags: ['tags.tag', ['in']],
            district: ['district', ['eq']],
            starts: ['starts', ['gte']],
          },
          sort: [{ name: 'DATE_ASC', order: ['starts:asc'] }],
        },
      },

      FileRef: {
        photos: {
          return: 'many',
          where: {
            featured: ['featured', ['eq']],
          },
        },
      },
    },

    i18n: {
      nl: () => import('./i18n/nlNL').then((m) => m.SchemaNlNL),
    },
  } as const);

export const schema = createSchema(result, { cultures: ['nl', 'en-GB'] });

export type AppSchema = typeof schema;
