import { onMount } from '@donkeyjs/jsx-runtime';
import { usePopper } from '../../helpers/usePopper';
import { getTheme } from '../../styles/applyTheme';
import styles from './usePopup.module.css';

export interface PopupProps {
  hasFocus?: boolean;
  children?: JSX.Children;
  onClose?: () => void;
}

export type Popup = ReturnType<typeof usePopup>;

export const usePopup = (props: PopupProps) => {
  const popper = usePopper(Popup);

  return {
    show: (target: HTMLElement) => {
      popper.show(target, props, { placement: 'bottom-start' });
    },
    hide: () => {
      popper.hide();
    },
    toggle: (target: HTMLElement) => {
      popper.toggle(target, props, { placement: 'bottom-start' });
    },
    get isVisible() {
      return popper.isVisible();
    },
  };
};

function Popup(props: PopupProps) {
  onMount(() => {
    const focusin = (e: FocusEvent) => {
      if (e.target instanceof HTMLElement) {
        props.hasFocus = !!e.target.closest('.popup');
        if (!props.hasFocus) {
          props.onClose?.();
        }
      }
    };

    document.addEventListener('focusin', focusin);

    return () => {
      document.removeEventListener('focusin', focusin);
    };
  });

  return (
    <div class={[styles.popup, getTheme().themeClass]}>{props.children}</div>
  );
}
