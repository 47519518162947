import { setState } from '@donkeyjs/jsx-runtime';
import { bind, type DataNode, type NodeTypename } from '@donkeyjs/proxy';
import { getI18n } from '../../i18n/getI18n';
import { session } from '../../session';
import { Select } from './Select';

export interface NodeSelectProps<
  T extends NodeTypename<DataSchema>,
  Mapped extends NodeTypename<DataSchema> = T,
> {
  value?: DataNode<DataSchema, T> | DataNode<DataSchema, T>[] | null;
  readonly typename: Mapped;
  readonly mapping?: {
    get(node: DataNode<DataSchema, T> | null): DataNode<DataSchema, Mapped>;
    set(
      node: DataNode<DataSchema, Mapped>,
      previous: DataNode<DataSchema, T> | null,
    ): DataNode<DataSchema, T> | null;
    remove?(node: DataNode<DataSchema, T>): boolean;
  };
  readonly filter?: (node: DataNode<DataSchema, Mapped>) => boolean;
  readonly allowEmpty?: boolean;
  onRequestAdd?(): Promise<DataNode<DataSchema, Mapped> | null | undefined>;
  onchange?(
    value:
      | DataNode<DataSchema, T>
      | DataNode<DataSchema, T>[]
      | null
      | undefined,
  ): boolean | void;
}

export function NodeSelect<
  T extends NodeTypename<DataSchema>,
  Mapped extends NodeTypename<DataSchema> = T,
>(props: NodeSelectProps<T, Mapped>) {
  const i18n = getI18n();

  const state = setState({
    opened: false,
    search: '',
  });

  const options = session.data.useNodes({
    typename: props.typename,
    drafts: false,
    get skipExecution() {
      return !state.opened;
    },
    get search() {
      return state.search
        ? {
            text: `${state.search.trim().split(/\s+/g).join(' & ')}:*`,
            culture:
              session.app.schema.cultures.length > 1 ? i18n.culture : undefined,
            mode: { __literal: 'TOKENS' } as const,
          }
        : undefined;
    },
  });

  return (
    <Select
      search={bind(state, 'search')}
      options={() => {
        state.opened = true;
        if (props.filter) return options.filter(props.filter);
        return options;
      }}
      allowEmpty={bind(() => props.allowEmpty)}
      value={bind(props, 'value')}
      mapping={bind(() => props.mapping)}
      onchange={() => props.onchange?.(props.value)}
      onRequestAddLabel={bind(() =>
        i18n.get('Common.CreateNode', i18n.getNodeName(props.typename)),
      )}
      onRequestAdd={bind(() => props.onRequestAdd)}
    />
  );
}
