import type { LocaleInput } from '@donkeyjs/core';
import { formatLocale } from 'd3-format';
import { format } from 'date-fns';
import dateLocale from 'date-fns/locale/en-GB';

export * from './Blocks';
export * from './System';

const d3Locale = {
  decimal: '.',
  thousands: ',',
  grouping: [3],
  currency: ['£', ''],
};

const formatNumber = formatLocale(d3Locale as any).format;

export const enGB: Required<LocaleInput> = {
  formatDate: (date, specifier) =>
    format(date, specifier, { locale: dateLocale }),
  formatNumber: (number, specifier) => formatNumber(specifier)(number),
  dateLocale,
};

export const schemaEnGB = () => import('./Nodes').then((m) => m.BaseSchemaEnGB);

export type DefaultLocale = typeof enGB;
