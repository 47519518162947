import { session } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { map } from '@donkeyjs/proxy';
import { getEventsContext } from '../../helpers/getEventsContext';
import { ClickAway } from './ClickAway';
import styles from './DaysMenu.module.css';

interface DaysMenuProps {
  open: boolean;
  onmount: JSX.OnMount<HTMLDivElement>;
}

export function DaysMenu(props: DaysMenuProps) {
  const events = getEventsContext();

  return (
    <>
      <div
        class={bind(() => [styles.days, { [styles.open]: props.open }])}
        onmount={props.onmount}
      >
        {map(
          () => Array.from(events.headings || []),
          (heading) => (
            <a
              href={session.router.getPath({ route: 'current' })}
              onclick={(ev: MouseEvent) => {
                ev.preventDefault();
                props.open = false;
                window.scrollTo({
                  top: heading.element.offsetTop,
                  behavior: 'smooth',
                });
              }}
            >
              {() => heading.name}
            </a>
          ),
        )}
      </div>
      {() => props.open && <ClickAway onclick={() => (props.open = false)} />}
    </>
  );
}
