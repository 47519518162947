import type { BlockPreset, BlocksType, DataSettings } from '@donkeyjs/client';
import { PhEnvelope, PhNewspaper, PhTicket } from '@donkeyjs/phosphor-icons';
import { MailchimpBlock } from './MailchimpBlock';
import { TiqqBlock } from './TiqqBlock';

export const blocks: BlocksType = {
  mailchimp: { component: MailchimpBlock },
  tiqq: { component: TiqqBlock },
};

export const blockPresets: BlockPreset[] = [
  {
    name: () => 'News Articles',
    icon: () => <PhNewspaper weight="duotone" />,
    hue: 56,
    values: {
      type: 'data',
      settings: {
        resolver: 'news',
        view: 'default',
      } as DataSettings,
    },
  },
  {
    name: () => 'Mailchimp Signup',
    icon: () => <PhEnvelope weight="duotone" />,
    hue: 56,
    values: {
      type: 'mailchimp',
    },
  },
  {
    name: () => 'Tiqq iframe',
    icon: () => <PhTicket weight="duotone" />,
    hue: 56,
    values: {
      type: 'tiqq',
    },
  },
];
