import {
  Image,
  getMenu,
  getUserContext,
  session,
  text,
} from '@donkeyjs/client';
import { bind, live, setState } from '@donkeyjs/jsx-runtime';
import { useEditions } from '@donkeyjs/module-festival';
import {
  PhCaretRight,
  PhCoins,
  PhInfo,
  PhList,
  PhNewspaper,
  PhVideoCamera,
  type Icon,
} from '@donkeyjs/phosphor-icons';
import { meta } from '@donkeyjs/proxy';
import { createPopper } from '@popperjs/core';
import { I18nApp } from '../../../i18n';
import { getEventsContext } from '../../helpers/getEventsContext';
import { useFeatured } from '../../helpers/getFeaturedContext';
import { DaysMenu } from './DaysMenu';
import { EditionsMenu } from './EditionsMenu';
import { Filter } from './Filter';
import styles from './Nav.module.css';
import { PopperMenu } from './PopperMenu';

const icon: Record<string, Icon> = {
  news: PhNewspaper,
  video: PhVideoCamera,
  donate: PhCoins,
};

export function Nav() {
  const edition = useEditions();
  const user = getUserContext();

  const events = getEventsContext();
  const featured = useFeatured();

  meta(edition.all).request({ accentColor: true });

  const state = setState({
    get isFestival() {
      return session.router.route.node?.key === 'festival';
    },
    menuOpen: undefined as HTMLElement | undefined,
    get menu() {
      return getMenu({
        get activeRoute() {
          return session.router.route;
        },
        user,
      });
    },
    menuElement: undefined as HTMLElement | undefined,
    editionsOpen: undefined as HTMLElement | undefined,
    editionsMenu: undefined as HTMLElement | undefined,
    daysOpen: undefined as HTMLElement | undefined,
    daysMenu: undefined as HTMLElement | undefined,
  });

  live(() => {
    if (state.daysOpen && state.daysMenu) {
      const popper = createPopper(state.daysOpen, state.daysMenu, {
        placement: 'bottom-start',
      });
      return () => popper.destroy();
    }
  });

  live(() => {
    if (state.editionsOpen && state.editionsMenu) {
      const popper = createPopper(state.editionsOpen, state.editionsMenu, {
        placement: 'bottom-start',
      });
      return () => popper.destroy();
    }
  });

  live(() => {
    if (state.menuOpen && state.menuElement) {
      const popper = createPopper(state.menuOpen, state.menuElement, {
        placement: 'bottom-end',
      });
      return () => popper.destroy();
    }
  });

  return (
    <nav
      class={bind(() => [
        styles.nav,
        {
          [styles.festival]: state.isFestival,
          [styles.editionsOpen]: state.editionsOpen,
        },
      ])}
    >
      <span class={styles.feature}>
        {() => {
          const length = featured.events.length;
          return (
            <span
              class={styles.photos}
              style={bind(() => ({ 'max-width': `${length * 60 + 20}px` }))}
            >
              <span class={styles.photosContent}>
                {() => {
                  const length = featured.events.length;
                  return featured.events.map(
                    (event, index) =>
                      event.image && (
                        <span
                          style={{
                            background: `rgba(var(--accent-rgb), ${
                              (1 - index / length) * 0.5
                            })`,
                          }}
                        >
                          <Image
                            file={event.image}
                            ratio={1}
                            style={{ opacity: (1 - index / length) * 0.5 }}
                          />
                        </span>
                      ),
                  );
                }}
              </span>
            </span>
          );
        }}
        {() =>
          !!edition.current && (
            <>
              <a
                href={bind(() =>
                  session.router.getPath({ routeKey: 'festival' }),
                )}
                onclick={(ev: MouseEvent) => {
                  if (state.isFestival) {
                    ev.preventDefault();
                    state.editionsOpen = ev.currentTarget as HTMLElement;
                  }
                }}
                class={styles.edition}
              >
                <span class={styles.label}>
                  {() =>
                    edition.isPast
                      ? text(I18nApp, 'Retrospect')
                      : text(I18nApp, 'Programme')
                  }
                </span>
                <span class={styles.value}>{() => edition.current?.name}</span>
                <span class={styles.chevron}>
                  <PhCaretRight weight="bold" />
                </span>
              </a>
              {() =>
                state.isFestival &&
                (events.headings?.length || 0) > 1 && (
                  <button
                    type="button"
                    class={styles.day}
                    onclick={(ev: MouseEvent) => {
                      ev.preventDefault();
                      state.daysOpen = ev.currentTarget as HTMLElement;
                    }}
                  >
                    <span class={styles.value}>
                      {() => events.currentHeading?.name}
                    </span>
                    <span class={styles.chevron}>
                      <PhCaretRight weight="bold" />
                    </span>
                  </button>
                )
              }
              {() =>
                state.isFestival && (
                  <>
                    <Filter key="district" label="Alle wijken" />,
                    <Filter
                      key="tagGroup_group_CNeGI20vwZzGn8n5vu"
                      label="Alle activiteiten"
                    />
                  </>
                )
              }
            </>
          )
        }
        <DaysMenu
          open={bind(
            () => !!state.daysOpen,
            (_) => (state.daysOpen = undefined),
          )}
          onmount={(el) => {
            state.daysMenu = el;
          }}
        />
        <EditionsMenu
          open={bind(
            () => !!state.editionsOpen,
            (_) => (state.editionsOpen = undefined),
          )}
          onmount={(el) => {
            state.editionsMenu = el;
          }}
        />
      </span>
      <span class={styles.menu}>
        {() =>
          state.menu
            .filter(
              (route) =>
                route.route.node?.key !== 'home' &&
                route.route.node?.key !== 'festival',
            )
            .map((route) => (
              <a
                href={bind(() =>
                  session.router.getPath({ route: route.route }),
                )}
                class={bind(() => ({ [styles.active]: route.isActive }))}
              >
                {() =>
                  (
                    (route.route.node?.key && icon[route.route.node?.key]) ||
                    PhInfo
                  )({
                    weight: 'fill',
                  })
                }
                <span>{() => route.route.name}</span>
              </a>
            ))
        }
        <button
          type="button"
          onclick={(ev) => {
            ev.preventDefault();
            state.menuOpen = ev.currentTarget as HTMLElement;
          }}
        >
          <PhList weight="bold" />
        </button>
      </span>
      <PopperMenu
        open={bind(
          () => !!state.menuOpen,
          (_) => (state.menuOpen = undefined),
        )}
        onmount={(el) => {
          state.menuElement = el;
        }}
        routes={bind(() => state.menu)}
      />
    </nav>
  );
}
