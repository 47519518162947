export function BlTitleOne() {
  return (
    <svg viewBox="0 0 256 256" width="1em" height="1em" fill="currentColor">
      <title>Title</title>
      <rect width="256" height="256" fill="none" />
      <line
        x1="157.24"
        y1="56.018"
        x2="53.24"
        y2="56.018"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      />
      <line
        x1="105.24"
        y1="56"
        x2="105.24"
        y2="176"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      />
      <polyline
        points="169.52 124 193.52 108 193.52 200"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      />
    </svg>
  );
}

export function BlTitleTwo() {
  return (
    <svg viewBox="0 0 256 256" width="1em" height="1em" fill="currentColor">
      <title>Subtitle</title>
      <rect width="256" height="256" fill="none" />
      <line
        x1="157.24"
        y1="56.018"
        x2="53.24"
        y2="56.018"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      />
      <line
        x1="105.24"
        y1="56"
        x2="105.24"
        y2="176"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      />
      <path
        d="M 163.839 118.957 C 171.043 101.945 193.962 99.111 205.094 113.856 C 208.226 118.005 209.926 123.059 209.939 128.257 C 209.96 133.036 208.53 137.709 205.839 141.657 L 161.939 200.257 L 209.939 200.257"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="24"
      />
    </svg>
  );
}

export function BlColumns() {
  return (
    <svg viewBox="0 0 256 256" width="1em" height="1em" fill="currentColor">
      <title>Columns</title>
      <rect width="256" height="256" fill="none" />
      <line
        x1="40"
        y1="68"
        x2="112"
        y2="68"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="16"
        style=""
      />
      <line
        x1="40"
        y1="108"
        x2="112"
        y2="108"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="16"
        style=""
      />
      <line
        x1="40"
        y1="148"
        x2="112"
        y2="148"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="16"
        style=""
      />
      <line
        x1="40"
        y1="188"
        x2="112"
        y2="188"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="16"
        style=""
      />
      <line
        x1="144"
        y1="68"
        x2="216"
        y2="68"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="16"
        style=""
      />
      <line
        x1="144"
        y1="108"
        x2="216"
        y2="108"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="16"
        style=""
      />
      <line
        x1="144"
        y1="148"
        x2="216"
        y2="148"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="16"
        style=""
      />
    </svg>
  );
}
