import {
  PhListBullets,
  PhListNumbers,
  PhQuotes,
  PhTextAa,
  PhTextHOne,
  PhTextHTwo,
} from '@donkeyjs/phosphor-icons';
import { BlTitleOne, BlTitleTwo } from '../../../../icons/BlockIcons';

export const getBlockIcon = (blockType: string | null | undefined) => {
  switch (blockType) {
    case 'T1':
      return BlTitleOne;
    case 'T2':
      return BlTitleTwo;
    case 'H1':
      return PhTextHOne;
    case 'H2':
      return PhTextHTwo;
    case 'UL':
      return PhListBullets;
    case 'OL':
      return PhListNumbers;
    case 'QUOTE':
      return PhQuotes;
    default:
      return PhTextAa;
  }
};
