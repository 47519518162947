import {
  Blocks,
  DataBlock,
  WithHeading,
  hex2rgb,
  session,
  type DataSettingsFilter,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { initializeEditions } from '@donkeyjs/module-festival';
import { useGallerySettings } from '@donkeyjs/module-gallery';
import { store } from '@donkeyjs/proxy';
import styles from './Main.module.css';
import { Header } from './components/Header';
import { Home } from './components/Home';
import { Nav } from './components/nav/Nav';
import { initializeFeatured } from './helpers/getFeaturedContext';

export function Main() {
  const edition = initializeEditions();
  initializeFeatured();

  useGallerySettings({
    addFileRefProperties(props) {
      return <props.node.$.featured label="Featured on homepage" />;
    },
  });

  const state = store({
    get festivalStyle() {
      return edition.current.accentColor
        ? `--festival-theme: ${
            edition.current.accentColor
          }; --festival-theme-rgb: ${hex2rgb(edition.current.accentColor)}`
        : '';
    },
  });

  return (
    <>
      <header class={styles.header} style={bind(() => state.festivalStyle)}>
        <a href="/">
          <span>Home</span>
        </a>
        <Header />
      </header>
      <Nav />
      <main
        style={bind(() => state.festivalStyle)}
        class={bind(() => [
          styles.main,
          {
            [styles.home]: session.router.route.node?.key === 'home',
            [styles.festival]: session.router.route.node?.key === 'festival',
          },
        ])}
      >
        <section>
          <WithHeading heading={bind(() => session.router.route.name)}>
            {() =>
              session.router.route.node?.key === 'home' ? (
                <Home />
              ) : session.router.route.node?.key === 'festival' ? (
                <DataBlock
                  resolver="events"
                  view="default"
                  selection="url"
                  workspace="festival"
                  group="date"
                  filters={filters}
                />
              ) : (
                <Blocks segment="main" />
              )
            }
          </WithHeading>
        </section>
      </main>
    </>
  );
}

const filters: DataSettingsFilter[] = [
  { key: 'edition', value: 'edition' },
  {
    key: 'CNeGI20vwZzGn8n5vu',
    kind: 'tag-group',
    fieldName: 'tags',
    input: 'in',
  },
  { key: 'district', input: 'eq' },
  { key: 'artists', input: 'in' },
];
