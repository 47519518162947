import type { AppClientModule } from '@donkeyjs/client';
import type { InstallScript } from '@donkeyjs/core';
import { PhFrameCorners } from '@donkeyjs/phosphor-icons';
import { ViewGalleryFileRef } from './client/views/ViewGalleryFileRef';

export * from './client';
export * from './schema';

export const clientModuleGallery: AppClientModule = {
  blockPresets: [
    {
      name: () => 'Gallery',
      group: () => 'Widgets',
      hue: 25,
      icon: () => <PhFrameCorners weight="duotone" />,
      values: {
        type: 'data',
        settings: {
          resolver: 'gallery',
          view: 'gallery',
          adding: 'top',
          paging: 'infinite-scroll',
          selection: 'url',
          filters: [{ key: 'id', value: '=self.id' }],
          name: 'Gallery',
        },
      },
    },
  ],
  views: {
    FileRef: {
      gallery: ViewGalleryFileRef,
    },
  },
};

export const __install: InstallScript<typeof import('.')> = [
  {
    action: 'add-schema-extensions',
    names: ['gallerySchemaExtension'],
  },
  {
    action: 'add-client-modules',
    names: ['clientModuleGallery'],
  },
];
