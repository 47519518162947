import type { I18nSystemEnGB } from '@donkeyjs/i18n-en-gb';

export const I18nSystemNl: typeof I18nSystemEnGB = {
  values: {
    Account: {
      ActionChangePassword: 'Wachtwoord wijzigen',
      ActionLogin: 'Inloggen',
      ActionLogout: 'Uitloggen',
      ActionRegister: 'Registeren',
      ActionResetPassword: 'Wachtwoord resetten',
      ConfirmNewPassword: 'Bevestig nieuw wachtwoord',
      ConfirmPassword: 'Bevestig wachtwoord',
      CurrentPassword: 'Huidig wachtwoord',
      EmailAddress: 'E-mailadres',
      ForgotPassword: 'Wachtwoord vergeten',
      HeaderLogin: 'Inloggen',
      HeaderRegister: 'Registreren',
      NewAccountEmail:
        'Vul het e-mailadres in waarmee je een account wilt aanmaken.',
      NewPassword: 'Nieuw wachtwoord',
      Password: 'Wachtwoord',
      PasswordUpdated:
        'Je wachtwoord is aangepast. Gebruik voortaan het nieuwe wachtwoord om in te loggen.',
      ResetPasswordExpired:
        'De link die je gebruikte om je wachtwoord te resetten is ongeldig of verlopen.',
      ResetPasswordInstruction: 'Voer hieronder je nieuwe wachtwoord in.',
      ResetPasswordRequested:
        'Als er een account gevonden is met het opgegeven e-mailadres dan ontvang je een link in je inbox om het wachtwoord opnieuw in te stellen.',
      WarnPasswordEmpty: 'Vul je wachtwoord in.',
      WarnPasswordInvalid: 'Ongeldige gebruikersnaam of wachtwoord.',
      WarnPasswordNoMatch:
        'De ingevoerde wachtwoorden komen niet overeen. Vul twee keer hetzelfde wachtwoord in.',
      WarnUsernameEmpty: 'Vul je gebruikersnaam in.',
    },
    Common: {
      Add: 'Toevoegen',
      And: 'en',
      Apply: 'Toepassen',
      BackToList: 'Terug naar lijst',
      Cancel: 'Annuleren',
      Change: 'Wijzigen',
      Clear: 'Wissen',
      Close: 'Sluiten',
      Continue: 'Verder',
      Create: 'Maken',
      CreateNode: (type: string) => `${type} toevoegen`,
      Default: 'Standaard',
      Delete: 'Verwijderen',
      Download: 'Downloaden',
      DeleteNode: (type: string) => `${type} verwijderen`,
      Draft: 'Concept',
      DraftsCount: (count: number, open?: boolean) =>
        [
          open === false ? 'toon' : 'verberg',
          `${count} concept${count === 1 ? '' : 'en'}`,
        ]
          .filter(Boolean)
          .join(' '),
      DraftXofY: (x: number, y: number) => `Concept ${x} van ${y}`,
      Edit: 'Bewerken',
      Find: 'Zoek',
      Insert: 'Invoegen',
      Loading: 'Laden',
      MoreInfo: 'Meer informatie',
      MoreInfoShort: 'Meer info',
      Next: 'Volgende',
      None: 'Geen',
      Ok: 'Ok',
      Or: 'of',
      Previous: 'Vorige',
      Publish: 'Publiceren',
      ReadMore: 'Lees meer',
      Remove: 'Verwijderen',
      Save: 'Opslaan',
      Select: 'Selecteren',
      ScrollToTop: 'Scroll naar boven',
      Search: 'Zoeken',
      Send: 'Verzenden',
      Share: 'Delen',
      ShowLess: 'Toon minder',
      ShowMore: 'Toon meer',
      Submit: 'Verzend',
      Unknown: 'Onbekend',
      Update: 'Bijwerken',
    },
    ContactForm: {
      Email: 'E-mailadres',
      Message: 'Bericht',
      Name: 'Naam',
      Subject: 'Onderwerp',
      OptinMailingList: 'Ik wil graag de nieuwsbrief ontvangen.',
      SentMessage: 'Bedankt voor je bericht!',
    },
    Data: {
      CloseFilters: 'Filters sluiten',
      Filters: 'Filters',
    },
  },
  errors: {
    'auth-invalid-login': 'De ingevulde gegevens zijn onjuist',
    boundary: 'Er is een fout opgetreden bij het weergeven van deze sectie.',
    // 'network-error':
    //   'Er is een netwerkfout opgetreden. Controleer of je internetverbinding werkt en probeer het nog eens.',
    'no-embed-found': 'Er is geen embed gevonden voor deze URL.',
    // 'page-not-found': 'Pagina niet gevonden.',
    unexpected: 'Er is een onverwachte fout opgetreden.',

    // Validation
    'expected-array': 'Dit veld verwacht een reeks waarden.',
    'invalid-email': 'Het e-mailadres is ongeldig.',
    'invalid-operation': 'Ongeldige bewerking.',
    'invalid-phone-number': 'Het telefoonnummer is ongeldig.',
    required: 'Dit veld is verplicht.',
  },
};
