import { Blocks, Heading, Image, session } from '@donkeyjs/client';
import { bind, onMount } from '@donkeyjs/jsx-runtime';
import { map, store } from '@donkeyjs/proxy';
import { useFeatured } from '../helpers/getFeaturedContext';
import styles from './Home.module.css';

const eventsCount = 6;
const countDown = 6000;

export function Home() {
  // const editions = useEditions();
  const featured = useFeatured();

  const state = store({
    index: 0,
    countDown,

    get featured() {
      const photos =
        featured.photos
          /*?.filter(
        (e) => e.onEventGallery?.edition?.id === editions.current?.id,
      )*/
          .sort(() => Math.random() - 0.5)
          .slice(0, eventsCount) || [];

      const events =
        featured.events
          ?.filter((e) => e.image)
          .sort(() => Math.random() - 0.5)
          .slice(0, eventsCount) || [];

      return photos?.length
        ? ({
            type: 'photos',
            items: photos,
          } as const)
        : ({
            type: 'events',
            items: events,
          } as const);
    },
  });

  onMount(() => {
    if (session.dom.ssr) return;

    const interval = setInterval(() => {
      state.countDown -= 500;
      if (state.countDown < 0) {
        state.countDown = countDown;
        state.index = (state.index + 1) % state.featured.items.length;
      }
    }, 500);

    return () => clearInterval(interval);
  });

  return (
    <div class={styles.container}>
      <div class={styles.news}>
        <Blocks segment="main" />
      </div>
      {() => {
        const event =
          state.featured.type === 'photos'
            ? state.featured.items[state.index].onEventGallery
            : state.featured.items[state.index];

        return (
          !!event && (
            <>
              <a
                class={styles.event}
                href={session.router.getPath({
                  node: event,
                  onRoute: session.router.getRouteByKey('festival'),
                })}
              >
                <Heading styleAs="title">{event.name}</Heading>
                <div class={styles.date}>
                  <event.$.starts formatter="PPPPp" noRange readonly />
                </div>
                <div class={styles.dots}>
                  {map(
                    () => state.featured.items as any[],
                    (_, i) => (
                      <button
                        type="button"
                        class={bind(() => ({
                          [styles.active]: i() === state.index,
                        }))}
                        onclick={(ev) => {
                          ev.preventDefault();
                          state.index = i();
                          state.countDown = countDown;
                        }}
                      />
                    ),
                  )}
                  <div
                    class={styles.countdown}
                    style={bind(() => ({
                      width: `${(state.countDown / countDown) * 100}%`,
                    }))}
                  />
                </div>
              </a>
              <div class={styles.eventImage}>
                <Image
                  file={bind(() =>
                    state.featured.type === 'photos'
                      ? state.featured.items[state.index]
                      : event.image,
                  )}
                  ratio="adaptive"
                />
                <div class={styles.preload}>
                  <Image
                    file={bind(() =>
                      state.featured.type === 'photos'
                        ? state.featured.items[state.index]
                        : event.image,
                    )}
                    ratio="adaptive"
                  />
                </div>
              </div>
            </>
          )
        );
      }}
    </div>
  );
}
