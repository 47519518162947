import type { DataNode } from '@donkeyjs/proxy';
import { session } from '../session';

export const uploadFiles = async (options?: {
  files?: FileList;
  tag?: DataNode<DataSchema, 'Tag'>;
  hideFromFilesPanel?: boolean;
  onCreated?: (node: DataNode<DataSchema, 'File'>) => void;
}): Promise<PromiseSettledResult<DataNode<DataSchema, 'File'>>[]> => {
  return new Promise<PromiseSettledResult<DataNode<DataSchema, 'File'>>[]>(
    (resolve, reject) => {
      const upload = (files: FileList) => {
        startUpload(
          files,
          options?.tag,
          options?.hideFromFilesPanel,
          options?.onCreated,
        )
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      };

      if (options?.files) upload(options?.files);
      else {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.style.display = 'none';

        input.onchange = (ev) => upload((ev.target as HTMLInputElement).files!);

        document.body.appendChild(input);
        input.click();
        document.body.removeChild(input);
      }
    },
  );
};

const startUpload = async (
  files: FileList,
  tag?: DataNode<DataSchema, 'Tag'>,
  hideFromFilesPanel?: boolean,
  onCreated?: (node: DataNode<DataSchema, 'File'>) => void,
): Promise<PromiseSettledResult<DataNode<DataSchema, 'File'>>[]> => {
  if (!files.length) return [];

  const filesArray = Array.from(files);
  return Promise.allSettled(
    filesArray.map(async (file) => {
      const parts = file.name.split(/\./g);
      const fileExtension = parts.length > 1 ? parts.pop()! : '';
      const name = parts.join('.');

      const { data, errors } = await session.data.mutation.upload(
        {
          fileExtension,
          name,
          fileType: file.type,
          tagId: tag?.id,
          hideFromFilesPanel,
        },
        {
          fileExtension: true,
          fileType: true,
          name: true,
          uploadStatus: true,
          createdAt: true,
          draft: true,
          tags: {
            id: true,
            createdAt: true,
            updatedAt: true,
            tag: {
              id: true,
            },
            file: {
              id: true,
            },
          },
        },
        { source: 'filesPanel' },
      );
      if (errors) throw errors;
      onCreated?.(data);

      return new Promise<DataNode<DataSchema, 'File'>>((resolve) => {
        upload(
          data.uploadStatus!,
          file,
          (err) => {
            console.error(err);
            data.uploadStatus = 'error';
            data.uploadError = true;
            resolve(data);
          },
          async (progress) => {
            if (progress === 100) {
              const { data: processed } =
                await session.data.mutation.processUpload(
                  { filename: `${data.id}.${fileExtension}` },
                  {
                    uploadStatus: true,
                    width: true,
                    height: true,
                    pages: true,
                    credits: true,
                  },
                );
              resolve(processed || data);
            } else {
              session.data.processNodeData({
                __typename: 'File',
                id: data.id,
                uploadStatus: progress.toString(),
              });
            }
          },
        );
      });
    }),
  );
};

const upload = (
  url: string,
  file: File,
  error: (message?: string) => void,
  progress: (value: number) => void,
) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', url, true);
  xhr.setRequestHeader('X-File-Name', file.name);
  xhr.setRequestHeader('X-File-Size', file.size.toString());
  xhr.setRequestHeader('Content-Type', file.type);
  xhr.upload.onprogress = (e) => {
    if (e.lengthComputable) {
      const percentage = Math.round((e.loaded * 100) / e.total);
      progress(percentage >= 100 ? 99 : percentage);
    }
  };
  xhr.onload = function () {
    if (this.status === 200) progress(100);
    else error(this.responseText);
  };
  xhr.onerror = () => {
    error();
  };
  xhr.send(file);
};
