import type { ViewProps } from '@donkeyjs/client';
import { getGlobal, setGlobal } from '@donkeyjs/jsx-runtime';

const key = Symbol('gallerySettings');

export interface GallerySettings {
  addFileRefProperties?: (
    props: ViewProps<DataSchema, 'FileRef'>,
  ) => JSX.Children;
}

export const useGallerySettings = (settings?: GallerySettings) => {
  if (settings) {
    setGlobal(key, settings);
    return settings;
  }

  return getGlobal<GallerySettings | undefined>(key);
};
