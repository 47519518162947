import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {
  NodeRoutingPlugin,
  createClientApp,
  type BackOfficeSettings,
} from '@donkeyjs/client';
import { clientModuleEvents } from '@donkeyjs/module-events';
import {
  FestivalRouterPlugin,
  clientModuleFestival,
} from '@donkeyjs/module-festival';
import { clientModuleGallery } from '@donkeyjs/module-gallery';
import { clientModuleSocialButtons } from '@donkeyjs/module-social-buttons';
import { clientSchemaMeta, schemaMeta } from '../schemaMeta';
import { settings } from '../settings';
import { Main } from './Main';
import { blockPresets, blocks } from './blocks';
import { theme } from './theme';
import { views } from './views';

const backOffice: BackOfficeSettings = {
  plugins: [
    () => import('./backoffice').then((m) => m.plugin),
    // () => import('@donkeyjs/module-events').then((m) => m.CalendarPlugin),
  ],
  load: () => import('@donkeyjs/backoffice').then((m) => m.BackOffice),
};

export const app = createClientApp({
  ...settings,

  schemaMeta,
  clientSchemaMeta,

  theme,
  views,
  blocks,
  blockPresets,

  root: Main,
  routerPlugins: [FestivalRouterPlugin, NodeRoutingPlugin],
  backOffice,
  nodeRouting: {
    Event: [{ routeKey: 'festival' }],
  },

  tz: {
    utcToZonedTime,
    zonedTimeToUtc,
    defaultTimeZone: 'Europe/Amsterdam',
  },

  modules: [
    clientModuleEvents,
    clientModuleFestival({
      backOffice: { editionPanelFields: ['accentColor', 'archived'] },
    }),
    clientModuleSocialButtons,
    clientModuleGallery,
  ],

  changelog: {
    scope: 'www.muzevanzuid.nl',
    log: () => import('../changelog').then((m) => m.log),
  },
});
