interface LogoProps {
  year: string;
}

export function Logo(props: LogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xml:space="preserve"
      style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2"
      viewBox="0 0 462 462"
    >
      <title>Logo</title>
      <path
        d="M-393.528-162.994h326.019v325.98h-326.019z"
        style="fill:#fff"
        transform="scale(-1 1) rotate(44.997 -508.802 115.258)"
      />
      <path
        d="M320.727 918.881h200.448v7.101H320.727z"
        style="fill:currentColor"
        transform="translate(-190.434 -788.578)"
      />
      <path
        d="M320.727 725.554h200.448v7.097H320.727z"
        style="fill:currentColor"
        transform="translate(-190.434 -401.92)"
      />
      <path
        d="M0-87.087V0h-7.354v-77.05l-12.894 11.783-12.897-11.783V0h-7.351v-87.087h7.285l12.963 11.79 12.961-11.79H0Z"
        style="fill-rule:nonzero;fill:currentColor"
        transform="translate(170.789 240.336)"
      />
      <path
        d="M0-45.128v25.166C0-8.554-8.563 0-19.973 0c-11.408 0-19.966-8.554-19.966-19.962v-25.166h7.475v25.166c0 7.416 5.145 12.677 12.491 12.677 7.294 0 12.492-5.261 12.492-12.677v-25.166H0Z"
        style="fill-rule:nonzero;fill:currentColor"
        transform="translate(227.843 198.377)"
      />
      <path
        d="M0 30.171v7.1h-35.43v-6.97L-10.14-.001h-24.594V-7.1H-.693v6.975l-25.362 30.296H0Z"
        style="fill-rule:nonzero;fill:currentColor"
        transform="translate(278.491 160.349)"
      />
      <path
        d="M0-72.633v9.635h17.13v7.1H0V-.002h28.985v7.229H-7.41V-79.86h36.395v7.227H0Z"
        style="fill-rule:nonzero;fill:currentColor"
        transform="translate(301.756 233.109)"
      />
      <path
        d="M0 18.28h5.068L11.1-4.297h4.645L8.569 22.577H-3.486l-7.181-26.874h4.6L0 18.28Z"
        style="fill-rule:nonzero;fill:currentColor"
        transform="translate(196.275 217.759)"
      />
      <path
        d="M0 2.15-2.729-8.063h-6.414L-11.865 2.15H0Zm1.15 4.222h-14.123l-2.193 8.14h-4.647l7.218-26.874H.757l7.189 26.874h-4.61L1.15 6.372Z"
        style="fill-rule:nonzero;fill:currentColor"
        transform="translate(237.145 225.824)"
      />
      <path
        d="M0 26.874h-4.498V13.86l-15.201-8.752v21.766h-4.491V0h4.414l15.278 8.831V0H0v26.874Z"
        style="fill-rule:nonzero;fill:currentColor"
        transform="translate(278.504 213.462)"
      />
      <path
        d="M0 35.091v8.256h-41.207v-8.11L-11.799.003H-40.41v-8.259H-.805v8.111l-29.497 35.236H0Z"
        style="fill-rule:nonzero;fill:currentColor"
        transform="translate(171.5 264.442)"
      />
      <path
        d="M0-52.485v29.267C0-9.95-9.953 0-23.225 0c-13.272 0-23.222-9.95-23.222-23.218v-29.267h8.701v29.267c0 8.625 5.968 14.74 14.521 14.74 8.482 0 14.527-6.115 14.527-14.74v-29.267H0Z"
        style="fill-rule:nonzero;fill:currentColor"
        transform="translate(234.909 308.671)"
      />
      <path
        d="M445.254 748.496h8.621v51.603h-8.621z"
        style="fill:currentColor"
        transform="translate(-190.434 -492.31)"
      />
      <path
        d="M0 .001c0-10.104-7.231-17.472-17.259-17.472h-13.78v34.943h13.78C-7.231 17.472 0 10.171 0 .001m8.547 0c0 14.742-11.13 25.801-25.806 25.801h-22.407v-51.603h22.407C-2.583-25.801 8.547-14.67 8.547.001"
        style="fill-rule:nonzero;fill:currentColor"
        transform="translate(323.757 281.988)"
      />
      <text
        x="231"
        y="388"
        style='font-family:"mvz","Roboto";font-size:30px;fill:currentColor;text-anchor:middle'
      >
        {() => props.year}
      </text>
    </svg>
  );
}
