import {
  Blocks,
  DataFilters,
  View,
  WithHeading,
  routerNode,
  session,
} from '@donkeyjs/client';
import { bind, live, onMount } from '@donkeyjs/jsx-runtime';
import { useEditions } from '@donkeyjs/module-festival';
import {
  meta,
  store,
  watch,
  type DataList,
  type DataNode,
} from '@donkeyjs/proxy';
import { getEventsContext } from '../helpers/getEventsContext';
import styles from './FestivalContainer.module.css';
import { ViewArtistThumbnail } from './ViewArtist';
import { viewArtistDetails } from './ViewArtistDetails';
import { viewEventDetails } from './ViewEventDetails';

interface FestivalContainerProps {
  data: DataList<DataSchema, 'Event'>;
  children?: JSX.Children | undefined;
}

export function FestivalContainer(props: FestivalContainerProps) {
  const selected = routerNode();
  const editions = useEditions();

  meta(props.data).request({ featured: true, edition: { id: true } });
  live(() => {
    if (session.dom.ssr) return;
    editions.checkStartsEnds(props.data);
  });

  const context = getEventsContext();
  context.data = props.data;

  const state = store({
    containerElement: undefined as HTMLElement | undefined,
    get event() {
      return (
        selected['node-typename'] === 'Event' &&
        props.data.find((e) => e.id === selected['node-id'])
      );
    },
    get artist() {
      return (
        session.router.query.artists?.length === 1 &&
        (props.data.aggregate?.artists?.values.find(
          (v) => v.value?.id === session.router.query.artists?.[0],
        )?.value as DataNode<DataSchema, 'Artist'> | undefined)
      );
    },
    get district() {
      return (
        session.router.query.district?.length === 1 &&
        (props.data.aggregate?.district?.values.find(
          (v) => v.value?.id === session.router.query.district?.[0],
        )?.value as DataNode<DataSchema, 'District'> | undefined)
      );
    },
  });

  // live(() => {
  //   if (dom.ssr) return;

  //   props.data.length;
  //   if (state.containerElement) {
  //     tick(() => {
  //       const headings = state.containerElement?.querySelectorAll(
  //         '.events > .heading',
  //       ) as NodeListOf<HTMLElement>;
  //       context.headings = headings;
  //     }, true);
  //   }
  // });

  onMount(() => {
    if (session.dom.ssr) return;

    const listener = () => {
      if (context.headings?.length) {
        const headings = Array.from(context.headings).reverse();
        const currentHeading = headings.find(
          (h) => h.element.getBoundingClientRect().top < 500,
        );
        context.currentHeading =
          currentHeading || headings[headings.length - 1];
      }
    };
    session.dom.window.addEventListener('scroll', listener);

    return () => {
      session.dom.window.removeEventListener('scroll', listener);
    };
  });

  watch((initial) => {
    if (session.dom.ssr) return;

    if (
      selected['node-id'] &&
      state.containerElement &&
      state.containerElement.clientWidth <= 1000 &&
      !initial
    )
      document.scrollingElement?.scrollTo(0, 0);
  });

  return (
    <div
      class={bind(() => [
        styles.festival,
        { [styles.hasSelection]: !!state.event || !!state.artist },
      ])}
      onmount={(el) => {
        state.containerElement = el;
      }}
    >
      <div class={styles.events}>
        <div class={styles.blocks}>
          <Blocks segment="events" />
        </div>
        <div class={styles.blocks}>
          <Blocks segment={bind(() => `events-${editions.current.id}`)} />
        </div>
        <div class={styles.filters}>
          <DataFilters mobile />
        </div>
        {() => props.children}
      </div>
      <div class={styles.content}>
        {() =>
          state.event ? (
            <View view={viewEventDetails} node={state.event} />
          ) : state.artist ? (
            <View view={viewArtistDetails} node={state.artist} />
          ) : state.district ? (
            <div>
              <WithHeading styleAs="title" heading={<state.district.$.name />}>
                <state.district.$.description />
              </WithHeading>
            </div>
          ) : (
            <div class={styles.artists}>
              {() =>
                props.data.aggregate?.artists?.values?.map(
                  (v) =>
                    v.value && (
                      <View
                        view={ViewArtistThumbnail}
                        node={
                          v.value as unknown as DataNode<DataSchema, 'Artist'>
                        }
                      />
                    ),
                )
              }
            </div>
          )
        }
      </div>
    </div>
  );
}
