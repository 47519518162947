import { createView } from '@donkeyjs/client';

export const ViewEventArtistProperties = createView<DataSchema, 'EventArtist'>(
  { name: () => 'Properties' },

  (props) => (
    <>
      <props.node.$.artist />
      <props.node.$.role />
    </>
  ),
);
