import { getTheme, getUserContext, session } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { useEditions } from '@donkeyjs/module-festival';
import { map } from '@donkeyjs/proxy';
import { ClickAway } from './ClickAway';
import styles from './EditionsMenu.module.css';

interface EditionsMenuProps {
  open: boolean;
  onmount: JSX.OnMount<HTMLDivElement>;
}

export function EditionsMenu(props: EditionsMenuProps) {
  const editions = useEditions();
  const user = getUserContext();
  const theme = getTheme();

  return (
    <>
      <div
        class={bind(() => [styles.editions, { [styles.open]: props.open }])}
        onmount={props.onmount}
      >
        {map(
          () =>
            editions.all.filter(
              (e) => !e.archived && (e.published || user.isAdmin),
            ),
          (edition) => (
            <a
              href={bind(() =>
                session.router.getPath(
                  { routeKey: 'festival' },
                  edition === editions.featured
                    ? undefined
                    : { query: { edition: [edition.name] } },
                ),
              )}
              onclick={() => {
                props.open = false;
              }}
              class={bind(() => [
                theme.class.link,
                { active: editions.current === edition },
              ])}
            >
              {() => edition.name}
            </a>
          ),
        )}
      </div>
      {() => props.open && <ClickAway onclick={() => (props.open = false)} />}
    </>
  );
}
