import { getGlobal } from '@donkeyjs/jsx-runtime';
import { store, type DataList } from '@donkeyjs/proxy';

export type EventsContext = {
  data: DataList<DataSchema, 'Event'> | undefined;
  headings?: {
    date: Date;
    name: string;
    element: HTMLElement;
  }[];
  currentHeading:
    | {
        date: Date;
        name: string;
        element: HTMLElement;
      }
    | undefined;
} & {
  [showPast: string]: boolean | undefined;
};

export const getEventsContext = () =>
  getGlobal<EventsContext>('events', () => {
    return store<EventsContext>({
      data: undefined,
      headings: undefined,
      currentHeading: undefined,
    });
  });
