import {
  batch,
  meta,
  signal,
  type DataList,
  type DataNode,
  type Signal,
} from '@donkeyjs/proxy';
import { session } from '../../../../session';

export interface GroupedBlocks {
  items: (
    | DataNode<DataSchema, 'Block'>
    | Signal<DataNode<DataSchema, 'Block'>[]>
  )[];
  index: Map<
    DataNode<DataSchema, 'Block'>,
    DataNode<DataSchema, 'Block'> | BlockGroup
  >;
  hasPersistentItems: boolean;
}

type BlockGroup = Signal<DataNode<DataSchema, 'Block'>[]>;

export const groupBlocks = (
  cache: WeakMap<DataNode<DataSchema, 'Block'>, BlockGroup> | undefined,
  blocks: DataNode<DataSchema, 'Block'>[] | DataList<DataSchema, 'Block'>,
  segment?: string | null,
  parent?: DataNode<DataSchema, 'Block'>,
): GroupedBlocks => {
  const result: GroupedBlocks = {
    items: [],
    index: new Map(),
    hasPersistentItems: false,
  };
  let current: BlockGroup | undefined;

  batch(() => {
    for (const block of blocks) {
      const include =
        (block.segment || undefined) === (segment || undefined) &&
        (block.parent || undefined) === (parent || undefined);
      if (!include) continue;

      if (!meta(block).isTest) {
        result.hasPersistentItems = true;
      }

      const component = block.type && session.app.blocks[block.type]?.component;

      if (
        (block.type === 'columns' ||
          block.type === 'file' ||
          block.type === 'contact-form' ||
          block.type === 'readmore' ||
          block.type === 'embed' ||
          !component) &&
        !block.type?.startsWith('field:')
      ) {
        if (!current) {
          const fromCache = cache?.get(block);
          if (fromCache) {
            current = fromCache;
            current.value = [];
          } else {
            current = signal([]);
            cache?.set(block, current);
          }
        }
        current.value.push(block);
        result.index.set(block, current);
      } else {
        if (current?.value.length) {
          result.items.push(current);
          current = undefined;
        }
        const group = block;
        result.items.push(group);
        result.index.set(block, group);
      }
    }

    if (current?.value.length) result.items.push(current);
  });

  return result;
};

export const groupBlocksAllSegments = (
  cache: WeakMap<DataNode<DataSchema, 'Block'>, BlockGroup>,
  blocks: DataNode<DataSchema, 'Block'>[] | DataList<DataSchema, 'Block'>,
  parent?: DataNode<DataSchema, 'Block'>,
): { [segment: string]: GroupedBlocks } => {
  const result: { [segment: string]: GroupedBlocks } = {};
  for (const block of blocks) {
    result[block.segment || '-'] ??= groupBlocks(
      cache,
      blocks,
      block.segment,
      parent,
    );
  }
  for (const segment in result) {
    if (!result[segment].items.length) delete result[segment];
  }
  return result;
};
