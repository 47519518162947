import { store } from '../store';

export type Markup = MarkupEntity[];

interface MarkupBounds {
  i: number;
  l: number;
}

export interface MarkupFormatting extends MarkupBounds {
  f: 'b' | 'i' | 's';
}

export interface MarkupLinkInput {
  to: string;
  tg?: '_blank';
}

export interface MarkupLinkType extends MarkupBounds, MarkupLinkInput {}

export interface MarkupImageInput {
  id: string;
}

export interface MarkupImage extends MarkupBounds, MarkupImageInput {}

export type MarkupEntity = MarkupFormatting | MarkupLinkType | MarkupImage;

export type MarkupTreeType = MarkupTreeEntity[];

interface MarkupTreeCursor {
  index: number;
  onChange: (index: number) => void;
}

export type MarkupTreeEntity = (
  | { kind: 'text'; text: string }
  | { kind: 'formatting'; entity: MarkupFormatting }
  | { kind: 'link'; entity: MarkupLinkType }
  | { kind: 'image'; entity: MarkupImage }
) & {
  start: number;
  end: number;
  weight: number;
  children: MarkupTreeType;
  parent?: MarkupTreeEntity;
};

export type MarkupString = string & { markup: Markup };

export const createMarkupString = (text: string, markup: Markup) => {
  const result = new String(text) as MarkupString;
  result.markup = markup;
  return result;
};

export class MarkupStringProcessor {
  private _store: { value: MarkupString | string | null };
  private _state: {
    plainText: boolean;
    value: MarkupString;
    tree: MarkupTreeType;
  };

  public readonly destroy: () => void = () => {};
  public readonly cursors: MarkupTreeCursor[] = [];

  constructor(value: { value: MarkupString | string | null }) {
    const self = this;
    this._store = value;
    this._state = store({
      get plainText() {
        return (value.value as MarkupString)?.markup === undefined;
      },
      get value() {
        return value.value == null
          ? createMarkupString('', [])
          : this.plainText
            ? createMarkupString(self._store.value as string, [])
            : (value.value as MarkupString);
      },
      set value(val) {
        value.value = this.plainText ? val.toString() : val;
      },
      get tree() {
        const value = this.value;
        return createTree(value).tree;
      },
    });
  }

  public get value() {
    return this._state.value;
  }

  public get tree() {
    return this._state.tree;
  }

  public get isPlainText() {
    return this._state.plainText;
  }

  public watchCursor(index: number, action: () => void): number {
    let value = index;
    const cursor: MarkupTreeCursor = {
      index: index,
      onChange: (index: number) => {
        value = index;
      },
    };
    this.cursors.push(cursor);
    action();
    this.cursors.splice(this.cursors.indexOf(cursor), 1);
    return value;
  }

  public watchSelection(
    range: { element: Element; from: number; to: number },
    action: () => void,
  ): { from: number; to: number } {
    const rangeFrom = range.from;
    const rangeTo = range.to;
    let to = rangeTo;
    const from = this.watchCursor(rangeFrom, () => {
      to = this.watchCursor(rangeTo, action);
    });
    if (from === rangeFrom && to === rangeTo) return range;
    return { from, to };
  }

  public updateSelection<T>(
    range: {
      element: Element;
      from: number;
      to: number;
      readonly outdated: boolean;
      set(from: number, to: number): void;
    },
    action: () => T,
  ): T {
    let result: T;

    const newSelection = this.watchSelection(range, () => {
      result = action();
    });
    if (!range.outdated) {
      range.set(newSelection.from, newSelection.to);
    }

    return result!;
  }

  private setValue(text: string, markup: Markup) {
    const normalized = normalize(markup);
    this._state.value = createMarkupString(text, normalized);
  }

  public splice(
    index: number,
    count: number,
    add?: string | MarkupString,
  ): this {
    const indx = Math.min(Math.max(index, 0), this._state.value.length);
    const cnt = count + Math.min(index, 0);

    const text = `${this._state.value.slice(0, indx)}${
      add || ''
    }${this._state.value.slice(indx + cnt)}`;

    for (const cursor of this.cursors) {
      if (cursor.index >= indx) {
        cursor.index = Math.max(cursor.index - cnt, indx) + (add?.length || 0);
        cursor.onChange(cursor.index);
      }
    }

    const difference = (add?.length ?? 0) - cnt;
    let hasChanged = false;

    const markup = this._state.value.markup;
    let newMarkup = markup
      .map((entity) => {
        if (entity.i > indx) {
          hasChanged = true;
          const diffBefore = Math.max(indx - entity.i, difference);
          const diffInside = difference - diffBefore;
          const newLength = entity.l + diffInside;
          return newLength > 0
            ? { ...entity, i: entity.i + diffBefore, l: newLength }
            : undefined;
        }
        if (entity.i + entity.l >= indx) {
          hasChanged = true;
          const newLength = Math.max(indx - entity.i, entity.l + difference);
          return newLength > 0 ? { ...entity, l: newLength } : undefined;
        }
        return entity;
      })
      .filter(Boolean) as MarkupEntity[];

    if ((add as MarkupString)?.markup?.length) {
      const addMarkup = (add as MarkupString).markup.map((entity) => ({
        ...entity,
        i: entity.i + indx,
      }));
      newMarkup.push(...addMarkup);
      newMarkup = normalize(newMarkup);
      hasChanged = true;
    }

    this.setValue(text, hasChanged ? newMarkup : markup);

    return this;
  }

  public insertText(index: number, add: string | MarkupString) {
    return this.splice(index, 0, add);
  }

  public appendText(add: string | MarkupString) {
    return this.splice(this._state.value.length, 0, add);
  }

  public replaceText(fromIndex: number, toIndex: number, add: string) {
    const normalized = normalizeMarkupIndexes(fromIndex, toIndex);
    return this.splice(normalized[0], normalized[1] - normalized[0], add);
  }

  public removeText(index: number, count: number) {
    return this.splice(index, count);
  }

  public removeTextForward(from: number, to: number) {
    if (from === to) this.replaceText(from, from + 1, '');
    else this.replaceText(from, to, '');
  }

  public removeTextBackward(from: number, to: number) {
    if (from === to) this.replaceText(from - 1, from, '');
    else this.replaceText(from, to, '');
  }

  public extract(from: number, to = Number.POSITIVE_INFINITY, remove = false) {
    if (from === to) return createMarkupString('', []);

    const normalized = normalizeMarkupIndexes(from, to);
    const text = this._state.value.slice(normalized[0], normalized[1]);
    const markup = this._state.value.markup
      .filter((entity) => {
        return entity.i < normalized[1] && entity.i + entity.l > normalized[0];
      })
      .map((entity) => {
        const i = Math.max(entity.i - normalized[0], 0);
        const to = entity.i + entity.l - normalized[0];
        return {
          ...entity,
          i,
          l: Math.min(to, text.length) - i,
        };
      });

    if (remove) this.replaceText(from, to, '');

    return createMarkupString(text, markup);
  }

  public getEntities(index: number) {
    return this.value.markup.filter((entity) => {
      return entity.i <= index && entity.i + entity.l > index;
    });
  }

  private removeRangeFromEntity(
    entity: MarkupEntity,
    from: number,
    to: number,
  ) {
    if (entity.i < from && entity.i + entity.l > to) {
      // Split entity
      const lastTo = entity.i + entity.l;
      this.setValue(this._state.value, [
        ...this._state.value.markup.map((e) =>
          e === entity ? { ...e, l: from - e.i } : e,
        ),
        { ...entity, i: to, l: lastTo - to },
      ]);
      return;
    }

    if (from <= entity.i && entity.i + entity.l <= to) {
      // Remove entity
      this.setValue(
        this._state.value,
        this._state.value.markup.filter((e) => e !== entity),
      );
      return;
    }

    if (entity.i < from) {
      // Remove from entity start
      this.setValue(
        this._state.value,
        this._state.value.markup.map((e) =>
          e === entity ? { ...e, l: from - e.i } : e,
        ),
      );
      return;
    }

    if (entity.i + entity.l > to) {
      // Remove from entity end
      this.setValue(
        this._state.value,
        this._state.value.markup.map((e) =>
          e === entity ? { ...e, i: to, l: e.i + e.l - to } : e,
        ),
      );
      return;
    }
  }

  public getFormatting(index: number): MarkupFormatting['f'][] {
    const entities = this.getEntities(index);
    return entities
      .map((entity) => (entity as MarkupFormatting).f)
      .filter(Boolean);
  }

  public applyFormatting(
    index: number,
    toIndex: number,
    formatting: MarkupFormatting['f'],
  ) {
    const normalized = normalizeMarkupIndexes(index, toIndex);
    const from = Math.min(Math.max(normalized[0], 0), this._state.value.length);
    const to = Math.min(Math.max(normalized[1], 0), this._state.value.length);

    if (from !== to) {
      const entity: MarkupFormatting = {
        f: formatting,
        i: to < from ? to : from,
        l: Math.max(from - to, to - from),
      };

      this.setValue(this._state.value, [...this._state.value.markup, entity]);
    }

    return this;
  }

  public removeFormatting(
    index: number,
    toIndex: number,
    formatting: MarkupFormatting['f'],
  ) {
    const normalized = normalizeMarkupIndexes(index, toIndex);
    const from = Math.min(Math.max(normalized[0], 0), this._state.value.length);
    const to = Math.min(Math.max(normalized[1], 0), this._state.value.length);

    if (from !== to) {
      const entity = this._state.value.markup.find((entity) => {
        return (
          entity.i < to &&
          entity.i + entity.l > from &&
          'f' in entity &&
          entity.f === formatting
        );
      });

      if (entity) {
        this.removeRangeFromEntity(entity, from, to);
      }
    }

    return this;
  }

  public toggleFormatting(
    index: number,
    toIndex: number,
    formatting: MarkupFormatting['f'],
  ) {
    const enitities = this.getEntities(index);
    const hasFormatting = enitities.some(
      (entity) => 'f' in entity && entity.f === formatting,
    );
    if (hasFormatting) {
      this.removeFormatting(index, toIndex, formatting);
    } else {
      this.applyFormatting(index, toIndex, formatting);
    }
    return this;
  }

  public applyLink(index: number, toIndex: number, link: MarkupLinkInput) {
    const normalized = normalizeMarkupIndexes(index, toIndex);
    const from = Math.min(Math.max(normalized[0], 0), this._state.value.length);
    const to = Math.min(Math.max(normalized[1], 0), this._state.value.length);

    if (from !== to) {
      // Don't make overlapping links. Shorten existing links if needed.
      const overlapping = this._state.value.markup.filter((entity) => {
        return entity.i < to && entity.i + entity.l > from && 'to' in entity;
      });
      for (const entity of overlapping) {
        this.removeRangeFromEntity(entity, from, to);
      }

      // Create new link
      const entity: MarkupLinkType = {
        i: from,
        l: to - from,
        ...link,
      };
      this.setValue(this._state.value, [...this._state.value.markup, entity]);
    }

    return this;
  }

  public updateEntity<T extends MarkupEntity>(
    entity: T,
    update: Partial<Omit<T, 'i' | 'l'>>,
  ) {
    this.setValue(
      this._state.value,
      this._state.value.markup.map((e) =>
        e === entity ? { ...e, ...update } : e,
      ),
    );
  }

  public removeEntity(entity: MarkupEntity) {
    this.setValue(
      this._state.value,
      this._state.value.markup.filter((e) => e !== entity),
    );
  }
}

const normalize = (markup: Markup) => {
  const result = markup.filter((entity) => 'id' in entity || entity.l > 0);
  if (!result.length) return result;

  for (let index = result.length - 1; index >= 0; index--) {
    const entity = result[index];
    if (!('id' in entity)) {
      const mergeIndex = result.findIndex((other) => {
        return (
          other !== entity &&
          isSimilar(entity, other) &&
          connects(entity, other)
        );
      });
      if (mergeIndex < 0) continue;

      const merge = result[mergeIndex];
      result[mergeIndex] = {
        ...merge,
        i: Math.min(merge.i, entity.i),
        l: Math.max(
          merge.l + Math.max(merge.i - entity.i, 0),
          entity.l + Math.max(entity.i - merge.i, 0),
        ),
      };
      result.splice(index, 1);
    }
  }

  return result;
};

export const normalizeMarkupIndexes = (from: number, to: number) =>
  from <= to ? [from, to] : [to, from];

const isSimilar = (
  entity: MarkupFormatting | MarkupLinkType,
  other: MarkupEntity,
) =>
  ('f' in entity && 'f' in other && entity.f === other.f) ||
  ('to' in entity &&
    'to' in other &&
    entity.to === other.to &&
    entity.tg === other.tg);

const connects = (entity: MarkupEntity, other: MarkupEntity) =>
  entity.i <= other.i
    ? other.i <= entity.i + entity.l
    : entity.i <= other.i + other.l;

export const isEmptyMarkupTree = (tree: MarkupTreeType | null | undefined) =>
  !tree?.length || (tree.length === 1 && tree[0].end === 0);

export const isMarkupLink = (entity: MarkupEntity): entity is MarkupLinkType =>
  'to' in entity;

const createTree = (
  value: MarkupString,
): { tree: MarkupTreeType; firstText: MarkupTreeEntity } => {
  const markup = value.markup;
  if (!value.length || !markup.length) {
    const text: MarkupTreeEntity = {
      children: [],
      end: value.length,
      kind: 'text',
      start: 0,
      text: value.toString() || '',
      weight: 0,
    };
    return { tree: [text], firstText: text };
  }

  // const map: TreeEntity[][][] = [];
  // const scheduleEntity = (entity: MarkupEntity) => {
  //   const treeEntity = getTreeEntity(entity);
  //   ((map[entity.i] ??= [])[treeEntity.weight] ??= []).push(treeEntity);
  // };
  // for (const entity of markup) scheduleEntity(entity);

  const fragmentTree: MarkupTreeEntity[][] = [];
  const fragments: MarkupTreeEntity[] = [];
  let firstText: MarkupTreeEntity | undefined;

  const process = (insert: MarkupTreeEntity) => {
    const todo: MarkupTreeEntity[] = [];
    for (const existing of fragments) {
      if (
        (insert.start < existing.end && insert.end > existing.start) ||
        (insert.end > existing.start && insert.start < existing.end)
      ) {
        // Overlapping
        if (insert.weight <= existing.weight) {
          // Insert on top of existing
          if (insert.start < existing.start) {
            todo.push({ ...insert, children: [], end: existing.start });
            insert.start = existing.start;
          }
          if (insert.end > existing.end) {
            todo.push({ ...insert, children: [], start: existing.end });
            insert.end = existing.end;
          }
          if (insert.start === existing.start) insert.weight += 0.0001;
        } else {
          // Insert under existing
          if (existing.start < insert.start) {
            todo.push({ ...existing, children: [], end: insert.start });
            existing.start = insert.start;
          }
          if (existing.end > insert.end) {
            todo.push({ ...existing, children: [], start: insert.end });
            existing.end = insert.end;
          }
        }
      }
    }

    fragments.push(insert);
    (fragmentTree[insert.start] ?? (fragmentTree[insert.start] = [])).push(
      insert,
    );

    for (const insert of todo) process(insert);
  };

  for (const entity of markup)
    process({
      ...getEntityInfo(entity),
      children: [],
      entity: entity as any,
      start: entity.i,
      end: entity.i + entity.l,
    });

  const result: MarkupTreeType = [];

  let current: MarkupTreeEntity | undefined;
  let characterIndex = 0;

  const addText = (end: number) => {
    const text: MarkupTreeEntity = {
      children: [],
      end,
      kind: 'text',
      text: value.slice(characterIndex, end),
      parent: current,
      start: characterIndex,
      weight: 0,
    };
    if (!firstText) firstText = text;
    (current?.children || result).push(text);

    characterIndex = end;
  };

  const processRunning = (to: number) => {
    while (current && current.end <= to) {
      if (current.end > characterIndex) addText(current.end);
      current = current.parent;
    }
    if (characterIndex < to) addText(to);
  };

  fragmentTree.forEach((starting, index) => {
    if (index > characterIndex) processRunning(index);

    starting.sort((a, b) => a.weight - b.weight);
    for (const fragment of starting) {
      fragment.parent = current;
      (current?.children || result).push(fragment);
      current = fragment;
    }
  });

  if (characterIndex < value.length) processRunning(value.length);

  return { tree: result, firstText: firstText! };
};

const getEntityInfo = (entity: MarkupEntity) =>
  'to' in entity
    ? { kind: 'link' as const, weight: 5 }
    : 'id' in entity
      ? { kind: 'image' as const, weight: 10 }
      : { kind: 'formatting' as const, weight: 1 };
