import type { AppModule, Changelog } from '@donkeyjs/core';
import type {
  AppClientModule,
  ClientApp,
  ClientAppInput,
} from '../apps/bootClientApp';
import { defaultBlockPresets, defaultBlocks } from '../blocks/defaultBlocks';
import { baseClientSchemaMeta } from '../schema/baseClientSchemaMeta';
import { mergeClientSchemaMeta } from '../schema/clientSchemaMetaUtils';
import { createTheme } from '../styles';
import { defaultViews } from '../views/defaultViews';
import { mergeViews } from '../views/viewUtils';

export type MergedModules = Omit<
  Required<ClientAppInput>,
  keyof AppModule | 'backOffice' | 'changelog' | 'tz' | 'theme' | 'nodeRouting'
> &
  Pick<ClientAppInput, 'backOffice' | 'tz'> &
  Pick<ClientApp, 'changelog' | 'theme'>;

export function mergeModules(input: ClientAppInput): MergedModules {
  const mergedModules = (input.modules || []).reduce<
    Omit<AppClientModule, 'changelog'> & { changelog?: Changelog[] }
  >(
    (acc, module) => {
      return {
        plugins: [...(acc.plugins || []), ...(module.plugins || [])],
        backOfficePlugins: [
          ...(acc.backOfficePlugins || []),
          ...(module.backOfficePlugins || []),
        ],
        clientSchemaMeta: mergeClientSchemaMeta(
          acc.clientSchemaMeta,
          module.clientSchemaMeta,
        ),
        blocks: {
          ...acc.blocks,
          ...module.blocks,
        },
        blockPresets: [
          ...(acc.blockPresets || []),
          ...(module.blockPresets || []),
        ],
        changelog: module.changelog
          ? [...(acc.changelog || []), module.changelog]
          : acc.changelog,
        localRoutes: [
          ...(acc.localRoutes || []),
          ...(module.localRoutes || []),
        ],
        views: mergeViews(acc.views, module.views),
      };
    },
    { clientSchemaMeta: baseClientSchemaMeta },
  );

  const merged: Omit<
    Required<ClientAppInput>,
    | keyof AppModule
    | 'backOffice'
    | 'changelog'
    | 'tz'
    | 'theme'
    | 'nodeRouting'
  > &
    Pick<ClientAppInput, 'backOffice' | 'tz'> &
    Pick<ClientApp, 'changelog' | 'theme'> = {
    ...input,
    install: input.install || (() => {}),
    routerFollowsCultures: !!input.routerFollowsCultures,
    modules: [],
    routerPlugins: input.routerPlugins || [],
    plugins: [...(mergedModules.plugins || []), ...(input.plugins || [])],
    theme: input.theme?.() || createTheme(undefined),
    localRoutes: [
      ...(mergedModules.localRoutes || []),
      ...(input.localRoutes || []),
    ],
    backOffice: input.backOffice && {
      ...input.backOffice,
      plugins: [
        ...(mergedModules.backOfficePlugins || []),
        ...(input.backOffice?.plugins || []),
      ],
    },
    clientSchemaMeta: mergeClientSchemaMeta(
      mergedModules.clientSchemaMeta,
      input.clientSchemaMeta,
    ),
    blocks: {
      ...defaultBlocks,
      ...mergedModules.blocks,
      ...input.blocks,
    },
    blockPresets: [
      ...defaultBlockPresets,
      ...(mergedModules.blockPresets || []),
      ...(input.blockPresets || []),
    ],
    changelog: input.changelog
      ? [...(mergedModules.changelog || []), input.changelog]
      : mergedModules.changelog,
    views: mergeViews(
      defaultViews,
      mergeViews<DataSchema>(mergedModules.views, input.views),
    ),
  };
  return merged;
}
