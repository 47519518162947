import {
  List,
  View,
  dropZone,
  getUserContext,
  type DropPosition,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { store, type DataList } from '@donkeyjs/proxy';
import { renderGalleryContainer } from './GalleryContainer';
import { ViewGalleryFileRef } from './views/ViewGalleryFileRef';

export interface EmbeddedGalleryProps {
  node: { gallery: DataList<DataSchema, 'FileRef'> };
  mode: 'masonry' | 'square' | 'portrait' | 'landscape';
}

export function EmbeddedGallery(props: EmbeddedGalleryProps) {
  const user = getUserContext();

  const state = store({
    get mode() {
      return (
        ViewGalleryFileRef.modes!.find((m) => m.key === props.mode) ??
        ViewGalleryFileRef.modes![0]
      );
    },
    get dropZone() {
      const values = ViewGalleryFileRef.containerDragDrop?.(props.node.gallery);
      if (!values) return null;
      return {
        key: 'gallery',
        accept: (item: any): DropPosition[] =>
          values.accept === false
            ? []
            : values.accept === true
              ? ['inside']
              : values.accept!(item)
                ? ['inside']
                : [],
        onHover: values.interceptHover,
        onDrop: values.interceptDrop,
      };
    },
  });

  return () =>
    !props.node.gallery.length && !user.can('insert', 'FileRef') ? null : (
      <div onmount={dropZone(state.dropZone as any)}>
        <List<DataSchema, 'FileRef'>
          data={props.node.gallery}
          render={(node) => {
            return (
              <View
                node={node}
                view={ViewGalleryFileRef}
                mode={bind(() => state.mode)}
              />
            );
          }}
          renderContainer={renderGalleryContainer}
          onContainerMount={bind(() =>
            ViewGalleryFileRef.onContainerMount?.(state.mode),
          )}
          renderAdd={() => null}
        />
      </div>
    );
}
