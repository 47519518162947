import { jsxx } from '@donkeyjs/jsx-runtime';
import { PhLinkBreak } from '@donkeyjs/phosphor-icons';
import { bind, store, type MarkupLinkInput } from '@donkeyjs/proxy';
import { getUserContext } from '../../../authentication';
import { Dialog } from '../../../components/Dialog/Dialog';
import { applyDonkeyTheme } from '../../../donkey';
import { I18nBlocks } from '../../../i18n/I18nBlocks';
import { text } from '../../../i18n/i18n';
import { Form } from '../../components/Form';
import { fixLinkSelectInput } from '../../components/LinkSelect';
import { setLayout } from '../../layoutContext';
import { LinkEditor } from '../forms/LinkEditor';

type LinkEditorDialogProps = Partial<MarkupLinkInput> & {
  onDelete?: () => void;
  onClose: (value?: MarkupLinkInput) => void;
};

export function LinkEditorDialog(props: LinkEditorDialogProps) {
  setLayout('html');

  const user = getUserContext();
  const theme = applyDonkeyTheme(user);

  const editorProps = store({
    to: props.to,
    tg: props.tg,
  });

  const submit = () => {
    props.onClose({
      to: fixLinkSelectInput(editorProps.to || ''),
      tg: editorProps.tg,
    });
  };

  const cancel = () => {
    props.onClose();
  };

  return (
    <Dialog
      onClose={(confirm) => {
        if (confirm) {
          submit();
        } else {
          cancel();
        }
      }}
      buttons={
        <>
          <button
            type="button"
            class={bind(() => [theme().class.button, 'default'])}
            onclick={submit}
          >
            {text('Common.Ok')}
          </button>
          <button
            type="button"
            class={bind(() => theme().class.button)}
            onclick={cancel}
          >
            {text('Common.Cancel')}
          </button>
          {() =>
            !!props.onDelete && (
              <button
                type="button"
                class={bind(() => theme().class.button)}
                onclick={() => {
                  props.onDelete!();
                  cancel();
                }}
                style="margin-left: auto"
              >
                <PhLinkBreak weight="bold" />
                <span>{text(I18nBlocks, 'Links.Remove')}</span>
              </button>
            )
          }
        </>
      }
    >
      <Form>{jsxx(LinkEditor, editorProps)}</Form>
    </Dialog>
  );
}
