import { createTheme } from '@donkeyjs/client';
import woff from './assets/mvz-webfont.woff';
import woff2 from './assets/mvz-webfont.woff2';
import styles from './theme.module.css';

export const theme = () =>
  createTheme({
    colors: {
      accent: '#3452ab',
      secondary: '#ae9a63',
      background: '#ffffff',
      color: '#333333',
      error: '#a82b1c',
      'text-on-error': '#ffffff',
      'text-on-accent': '#ffffff',
      'festival-theme': '#d4c1b1',
    },

    font: "11pt/1.6 'Roboto', sans-serif",

    webFonts: {
      google: { families: ['Roboto:ital,wght@0,400;0,700;1,400;1,700'] },
      custom: { mvz: { woff, woff2 } },
    },

    styles,
  });
