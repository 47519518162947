import { bind, live, setState } from '@donkeyjs/jsx-runtime';
import type { MarkupLinkInput, Node } from '@donkeyjs/proxy';
import { useForm } from '../../../data/useForm';
import { I18nBlocks } from '../../../i18n/I18nBlocks';
import { text } from '../../../i18n/i18n';
import { session } from '../../../session';
import { LinkSelect } from '../../components/LinkSelect';
import { setLayout } from '../../layoutContext';
import { resolveLink } from '../MarkupLink';

const key = Symbol('link-editor');

export function LinkEditor(props: Partial<MarkupLinkInput>) {
  setLayout('html');

  const state = setState({
    get node(): Node | null {
      const resolved = resolveLink(values.link || '');
      if (resolved?.type === 'internal') {
        return session.data.ensureNode({
          __typename: resolved.typename,
          id: resolved.id,
        });
      }
      return null;
    },

    set node(value: Node | null) {
      values.link = value
        ? `internal://${
            Object.entries(session.app.schema.nodes.BlockLink.fields).find(
              ([, field]) => field.type === value.__typename,
            )?.[0] || ''
          }/${value.id}`
        : '';
    },

    get value() {
      return state.node || values.link || null;
    },

    set value(value: Node | string | null) {
      if (!value || typeof value === 'string') {
        values.link = value || '';
      } else {
        state.node = value;
      }
    },
  });

  const { values } = useForm(key, {
    link: 'string',
    openInNewTab: 'boolean',
  });
  values.link = props.to?.startsWith('mailto:')
    ? props.to.slice(7)
    : props.to || '';
  values.openInNewTab = props.tg === '_blank';

  live(() => {
    if (props.to !== values.link) {
      props.to = values.link;
    }
  });

  live(() => {
    const tg = values.openInNewTab ? '_blank' : undefined;
    if (props.tg !== tg) {
      props.tg = tg;
    }
  });

  return (
    <>
      {text(I18nBlocks, 'Links.Instructions')}
      <LinkSelect value={bind(state, 'value')} autofocus />
      <values.$.openInNewTab />
    </>
  );
}
