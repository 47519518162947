import { getTheme, session } from '@donkeyjs/client';
import { bind, live, setState } from '@donkeyjs/jsx-runtime';
import { PhCaretRight } from '@donkeyjs/phosphor-icons';
import { createPopper } from '@popperjs/core';
import { getEventsContext } from '../../helpers/getEventsContext';
import { ClickAway } from './ClickAway';
import styles from './Filter.module.css';

interface FilterProps {
  label: string;
  key: string;
}

export function Filter(props: FilterProps) {
  const events = getEventsContext();

  const state = setState({
    menuOpen: undefined as HTMLElement | undefined,
    menu: undefined as HTMLElement | undefined,
    get selection() {
      return session.router.query[props.key.replace('tagGroup_group_', '')]
        ?.length
        ? (events.data?.aggregate as any)?.[props.key]?.values?.filter(
            (v: any) =>
              session.router.query[
                props.key.replace('tagGroup_group_', '')
              ]?.includes(v.value?.id || v.value),
          )
        : undefined;
    },
  });

  live(() => {
    if (state.menuOpen && state.menu) {
      const popper = createPopper(state.menuOpen, state.menu, {
        placement: 'bottom-start',
      });
      return () => popper.destroy();
    }
  });

  return (
    <>
      <button
        type="button"
        class={bind(() => [
          styles.button,
          'filter',
          { [styles.open]: !!state.menuOpen },
        ])}
        onclick={(ev) => {
          ev.preventDefault();
          state.menuOpen = ev.currentTarget;
        }}
      >
        <span class={styles.value}>
          {() =>
            state.selection?.length
              ? state.selection[0].value?.toString()
              : props.label
          }
        </span>
        <span class={styles.chevron}>
          <PhCaretRight weight="bold" />
        </span>
      </button>
      <Menu
        open={bind(
          () => !!state.menuOpen,
          (_) => {
            state.menuOpen = undefined;
          },
        )}
        onmount={(el) => {
          state.menu = el;
        }}
        key={bind(() => props.key)}
        label={bind(() => props.label)}
        selection={bind(() => state.selection)}
      />
    </>
  );
}

interface MenuProps {
  open: boolean;
  onmount: JSX.OnMount<HTMLDivElement>;
  key: string;
  label: string;
  selection: any;
}

function Menu(props: MenuProps) {
  const theme = getTheme();
  const events = getEventsContext();

  return (
    <>
      <div
        class={bind(() => [styles.menu, { [styles.open]: props.open }])}
        onmount={props.onmount}
      >
        {() =>
          props.selection ? (
            <a
              href={session.router.getPath(
                { route: 'current' },
                {
                  query: {
                    ...session.router.query,
                    'node-typename': undefined,
                    'node-id': undefined,
                    [props.key.replace('tagGroup_group_', '')]: undefined,
                  },
                },
              )}
              onclick={() => {
                props.open = false;
              }}
              class={bind(() => theme.class.link)}
            >
              {() => props.label}
            </a>
          ) : null
        }
        {() =>
          (events.data?.aggregate as any)?.[props.key]?.values?.map((v: any) =>
            props.selection?.includes(v) ? null : (
              <a
                href={session.router.getPath(
                  { route: 'current' },
                  {
                    query: {
                      ...session.router.query,
                      'node-typename': undefined,
                      'node-id': undefined,
                      [props.key.replace('tagGroup_group_', '')]: [
                        v.value?.id || v.value,
                      ],
                    },
                  },
                )}
                onclick={() => {
                  props.open = false;
                }}
                class={bind(() => theme.class.link)}
              >
                {() => v.value?.toString()}
              </a>
            ),
          )
        }
      </div>
      {() => props.open && <ClickAway onclick={() => (props.open = false)} />}
    </>
  );
}
