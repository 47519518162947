import type { AppClientModule } from '@donkeyjs/client';
import type { InstallScript } from '@donkeyjs/core';
import type { AppServerModule } from '@donkeyjs/server';
import type { BackOfficeFestivalPluginOptions } from './backoffice';
import { permissions } from './permissions';
import { clientSchemaMeta } from './schemaMeta';
import { blockPresets, blocks } from './ui/blocks';

export * from './festivalRouterPlugin';
export * from './helpers';
export * from './schema';

export const clientModuleFestival = (options?: {
  backOffice?: BackOfficeFestivalPluginOptions;
}): AppClientModule => ({
  blocks,
  blockPresets,
  permissions,
  clientSchemaMeta,
  backOfficePlugins: [
    () =>
      import('./backoffice').then((m) =>
        m.backOfficeFestivalPlugin(options?.backOffice),
      ),
  ],
});

export const serverModuleFestival: AppServerModule = {
  permissions,
};

export const __install: InstallScript<typeof import('.')> = [
  {
    action: 'add-schema-extensions',
    names: ['festivalSchemaExtension'],
  },
  {
    action: 'add-client-modules',
    names: ['clientModuleFestival'],
  },
  {
    action: 'add-server-modules',
    names: ['serverModuleFestival'],
  },
];
