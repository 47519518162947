import { View, routerNode } from '@donkeyjs/client';
import { bind, setContext } from '@donkeyjs/jsx-runtime';
import { store, type DataList, type DataNode } from '@donkeyjs/proxy';
import styles from './NewsContainer.module.css';
import { ViewArticleDetails } from './ViewArticleDetails';

export interface NewsContext {
  feature: DataNode<DataSchema, 'Article'> | undefined;
}

interface NewsContainerProps {
  data: DataList<DataSchema, 'Article'> | DataNode<DataSchema, 'Article'>[];
  children?: JSX.Children | undefined;
}

export function NewsContainer(props: NewsContainerProps) {
  const selected = routerNode();

  const state = setContext(
    'news',
    store({
      get feature() {
        return (
          (selected['node-typename'] === 'Article' &&
            props.data.find((e) => e.id === selected['node-id'])) ||
          props.data.find((e) => e.featured)
        );
      },
    }),
  );

  return (
    <div class={styles.container}>
      <div
        class={bind(() => [
          styles.news,
          { [styles.hasFeature]: !!state.feature },
        ])}
      >
        {() =>
          !!state.feature && (
            <div class={styles.feature}>
              <View view={ViewArticleDetails} node={state.feature} />
            </div>
          )
        }
        <div class={styles.list}>{props.children}</div>
      </div>
    </div>
  );
}
