import { componentContext, jsxx, mount } from '@donkeyjs/jsx-runtime';
import { store, watch } from '@donkeyjs/proxy';
import { createPopper, type Instance } from '@popperjs/core';
import { Loading } from '../../loaders';
import { getTheme, type Theme } from '../../styles';
import { MenuItem, type MenuItemProps } from './MenuItem';
import { NativeMenu, type NativeMenuProps } from './NativeMenu';

interface DropDownMenuOptions {
  open?: boolean;
  theme?: Theme;
  showOnClick?: boolean;
  onClose?(): void;
}

export const dropDownMenu = (
  items: (MenuItemProps | string)[] | (() => (MenuItemProps | string)[]),
  options?: DropDownMenuOptions,
) => {
  const open = store({ value: false });
  return {
    attach: (anchor: HTMLElement) => {
      const theme = getTheme();
      const parentContext = componentContext.current;

      let popperInstance: Instance | undefined;
      let disposeMenu: (() => void) | undefined;
      let menuElement: HTMLElement | undefined;

      const state = store({
        get items() {
          return typeof items === 'function' ? items() : items;
        },
      });

      const menu = store<NativeMenuProps>({
        get open() {
          return (
            (options?.open == null ? true : options.open) &&
            !!state.items.length
          );
        },
        onClickAway: () => {
          open.value = false;
          options?.onClose?.();
        },

        get class() {
          return theme.themeClass;
        },
      });

      const show = () => {
        const mounted = mount(
          parentContext!.dom,
          NativeMenu,
          menu,
          [
            () =>
              state.items.flatMap((item) =>
                item === '-' ? (
                  <hr />
                ) : item === '~' ? (
                  <Loading type="inline-small" />
                ) : typeof item === 'string' ? (
                  <h2>{item}</h2>
                ) : (
                  jsxx(
                    MenuItem,
                    store.clone<MenuItemProps, Pick<MenuItemProps, 'onclick'>>(
                      store(item),
                      {
                        onclick: (ev, target) => {
                          ev?.preventDefault();
                          hide();
                          open.value = false;
                          if (typeof item.onclick === 'function')
                            item.onclick(ev, target);
                        },
                      },
                    ),
                  )
                ),
              ),
          ],
          document.body,
          parentContext,
        );
        disposeMenu = mounted[0];
        menuElement = mounted[1] as HTMLElement;
        popperInstance = createPopper(anchor, menuElement, {
          placement: 'bottom-start',
        });
      };

      const hide = () => {
        popperInstance?.destroy();
        popperInstance = undefined;
        disposeMenu?.();
        disposeMenu = undefined;
      };

      const unwatch = [
        watch(() => {
          if (open.value) {
            show();
          } else {
            hide();
          }
        }).dispose,
        watch(() => {
          if (options?.open != null && options.open !== open.value) {
            open.value = options.open;
          }
        }).dispose,
      ];

      const handleClick = (ev: MouseEvent) => {
        ev.preventDefault();
        open.value = true;
      };

      if (options?.showOnClick) {
        anchor.addEventListener('click', handleClick);
      }

      return () => {
        for (const fn of unwatch) fn();
        if (options?.showOnClick) {
          anchor.removeEventListener('click', handleClick);
        }
        hide();
      };
    },
  };
};
