import type { AppClientModule } from '@donkeyjs/client';
import { permissions } from '../permissions';
import { clientSchemaMeta } from './clientSchemaMeta';
import { blockPresets, blocks } from './ui/blocks';

export * from './ui/blocks/SocialButtonsBlock';

export const clientModuleSocialButtons: AppClientModule = {
  blocks,
  blockPresets,
  permissions,
  clientSchemaMeta,
  backOfficePlugins: [
    () =>
      import('./backoffice/plugin').then(
        (m) => m.backOfficeSocialButtonsPlugin,
      ),
  ],
};
