import {
  meta,
  sortNodes,
  store,
  type DataList,
  type NodeTypename,
  type ResolverArgsSearch,
} from '@donkeyjs/proxy';
import { session } from '../session';

export type UseNodeSelectProps = {
  types: NodeTypename<DataSchema>[];
  search?: ResolverArgsSearch;
  autoLoad?: boolean;
  // permanent?: boolean;
};

export function useNodeSelect(props: UseNodeSelectProps) {
  const requests: DataList<DataSchema>[] = [];

  const state = store({
    loaded: !!props.autoLoad,
  });
  const load = () => {
    if (state.loaded) return;
    state.loaded = true;
  };

  for (const type of props.types) {
    const request = session.data.useNodes<NodeTypename<DataSchema>>({
      typename: type,
      drafts: false,
      get skipExecution() {
        return !state.loaded;
      },
      get search() {
        return props.search;
      },
    });
    meta(request).request({ createdAt: true });
    requests.push(request);
  }

  const result = store({
    load,
    requests,
    get merged() {
      const nodes = requests.flat();
      sortNodes(nodes, ['createdAt:desc'] as any);
      return nodes;
    },
  });

  return result;
}
