import { setState } from '@donkeyjs/jsx-runtime';
import type { FieldRenderProps } from '../..';
import { getViewContext } from '../../../views';
import { View } from '../../../views/View';
import { getView } from './getView';

export function InlineNode(props: FieldRenderProps<'node'>) {
  const viewContext = getViewContext();

  const state = setState({
    get view() {
      return getView(props, viewContext);
    },
  });

  return () => {
    if (!props.field.value) return null;

    if (state.view)
      return (
        <View
          node={props.field.value!}
          readonly={true}
          view={state.view!}
          onmount={props.onmount}
        />
      );

    return (
      <div class="view" onmount={props.onmount}>
        {() =>
          `No view available for ${props.field.schema?.type} with key ${
            viewContext?.preferredListView
              ? `'${viewContext?.preferredListView}' or 'default'`
              : "'default'"
          }`
        }
      </div>
    );
  };
}
