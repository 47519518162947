import type { Icon } from '@donkeyjs/phosphor-icons';
import type {
  DataList,
  DataNode,
  DataNodeFields,
  IntrinsicNodeFields,
  NodeTypename,
  ResolverArgsSearch,
  Schema,
} from '@donkeyjs/proxy';
import type { BlockPreset } from './blocks';
import type { MenuItemProps } from './donkey';
import type {
  DraggableOptions,
  DraggedItem,
  UseDropZoneOptions,
} from './dragdrop';
import type { ViewType } from './views';

interface SelectFileOptions {
  readonly multiple?: boolean;
}

export interface CreateOrEditNodeProps<
  S extends Schema,
  T extends NodeTypename<S>,
> {
  typename: T;
  defaultValues?: Omit<Partial<IntrinsicNodeFields>, '__typename'> &
    Partial<DataNodeFields<S, T>>;
  node?: DataNode<S, T>;
  isolatedFields?: (keyof DataNode<S, T>)[];
  addIsolatedFields?: (keyof DataNode<S, T>)[];
  hideIsolatedFields?: (keyof DataNode<S, T>)[];
}

export interface GlobalSelection {
  selection: OutlineEntry<Schema>[];
  locked: OutlineEntry<Schema>[];
  hover: OutlineEntry<Schema> | undefined;
  defaultProperties?: OutlineEntry<Schema>;
  root?: {
    node: DataNode<Schema>;
    blocks: DataList<DataSchema, 'Block'>;
  };

  select<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
    options?: { append?: boolean },
  ): void;
  deselect<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
  isSelected<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): boolean;
  lock<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
  unlock<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
  isLocked<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): boolean;
  setHover<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type> | undefined,
  ): void;
  unsetHover<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
  isHovering<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): boolean;
  setDefaultProperties<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
  unsetDefaultProperties<S extends Schema, Type extends NodeTypename<S>>(
    outline: OutlineEntry<S, Type>,
  ): void;
}

export interface UseOutlineOptions<
  S extends Schema,
  Type extends NodeTypename<S>,
> {
  readonly context?: DataList<S, Type> | DataNode<S, Type>[];
  readonly view?: ViewType<S, Type>;
  readonly parent?: DataNode<S> | OutlineEntry<S>;
  readonly preventSelection?: boolean;
  readonly withoutInterface?: boolean;
  readonly overrideLock?: boolean;
}

export interface OutlineEntryInput<
  S extends Schema,
  Type extends NodeTypename<S> = NodeTypename<S>,
> {
  readonly name?: JSX.Children | string | null;
  readonly descriptor?: JSX.Children | string | null;
  readonly text?: JSX.Children;
  readonly icon?: () => JSX.Element;
  readonly defaultClosed?: boolean;
  readonly onResize?: (width: number | null) => void;
  readonly onAttach?: (element: EventTarget) => (() => void) | void;
  readonly onEnter?: (ev: KeyboardEvent) => void;
  readonly preventFirstClick?: boolean;
  taskbar?: JSX.Children;
  readonly properties?: () => JSX.Children;
  readonly emphasis?: 'low' | 'medium' | 'high';
  readonly draggable?: S extends DataSchema
    ? Type extends NodeTypename<DataSchema>
      ? DraggableOptions<Type>
      : never
    : never;
  readonly accept?:
    | boolean
    | {
        accept?: boolean | ((item: DraggedItem) => boolean);
        interceptDrop?: (
          item: DraggedItem,
          target: DataNode<DataSchema, 'Block'>,
        ) => boolean;
        interceptHover?: (
          item: DraggedItem,
          target: DataNode<DataSchema, 'Block'>,
        ) => (() => void) | void;
      }
    | UseDropZoneOptions;
  readonly disableAccept?: () => boolean;
  readonly hideFromPanel?: boolean;
  readonly setAsDefaultProperties?: boolean;
  readonly contextMenu?: () => (MenuItemProps | '-' | undefined)[];
  readonly visibleWhenChildSelected?: boolean;
  readonly children?: OutlineEntry<S>[];
}

export interface OutlineEntry<
  S extends Schema = Schema,
  Type extends NodeTypename<S> = NodeTypename<S>,
> extends Omit<OutlineEntryInput<S, Type>, 'accept'> {
  node: DataNode<S, Type>;
  selected: boolean;
  selectionLocked: boolean;
  readonly childSelected: boolean;
  readonly instanceSelected: boolean;
  element?: HTMLElement;
  hovering: boolean;
  readonly active: boolean;
  readonly disabled: boolean;
  readonly locked: boolean;
  parent: OutlineEntry<S> | undefined;
  accept?: UseDropZoneOptions;
}

export type MappedBlockPreset = Omit<BlockPreset, 'name' | 'group'> & {
  name: string;
  group: string;
};

export interface UseInsertState {
  searchText: string;
  searchQuery: ResolverArgsSearch | undefined;
  expandedGroup: string | undefined;
  load(): void;
  readonly presets: Record<string, MappedBlockPreset[]>;
  readonly nodes: {
    typename: NodeTypename<DataSchema>;
    name: string;
    icon: Icon;
    nodes: DataList<DataSchema, NodeTypename<DataSchema>>;
  }[];
}

export interface ClientAdmin {
  useSelection?: () => GlobalSelection;
  useOutline?: <
    S extends Schema,
    Type extends NodeTypename<S> = NodeTypename<S>,
  >(
    nodeInput: DataNode<S, Type>,
    options?: UseOutlineOptions<S, Type>,
  ) => OutlineEntry<S, Type>;
  useInsert?: (options?: { autoLoad?: boolean }) => UseInsertState;
  selectFile?: (
    options?: SelectFileOptions,
  ) => Promise<DataNode<DataSchema, 'File'> | undefined>;
  showCreateOrEditNodeDialog?: <S extends Schema, T extends NodeTypename<S>>(
    props: CreateOrEditNodeProps<S, T>,
  ) => Promise<DataNode<S, T> | null>;
}

export const admin: ClientAdmin = {};
