import { createView } from '@donkeyjs/client';
import { meta } from '@donkeyjs/proxy';
import styles from './ViewEventArtistThumbnail.module.css';

export const ViewEventArtistThumbnail = createView<DataSchema, 'EventArtist'>(
  { name: () => 'Thumbnail' },

  function ViewEventArtistThumbnail(props) {
    return (
      <div class={styles.artist} onmount={props.onmount}>
        <div class={styles.image}>
          {() =>
            !meta(props.node).isLoading && (
              <props.node.artist.$.image ratio={1} />
            )
          }
        </div>
        <div class={styles.name}>
          <props.node.artist.$.name />
        </div>
        <div class={styles.role}>
          <props.node.$.role />
        </div>
      </div>
    );
  },
);
