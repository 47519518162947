import type {
  DatabaseRouter,
  NodeRouteMappings,
  RouteDefinition,
  RouterPlugin,
  UseRouterOptions,
} from '@donkeyjs/core';
import {
  createApp,
  registerLocales,
  type AppBase,
  type AppModule,
  type CreateAppInput,
} from '@donkeyjs/core';
import {
  createRenderContext,
  mount,
  withSsrResume,
  type Component,
  type Dom,
} from '@donkeyjs/jsx-runtime';
import type {
  Culture,
  DataList,
  DataNode,
  Schema,
  TimeZoneConfig,
} from '@donkeyjs/proxy';
import type { BlockDefinition, BlockPreset, PartialBlocks } from '../blocks';
import { createDataClient } from '../data/createDataClient';
import type { Changelog } from '../donkey';
import { mergeModules } from '../helpers/mergeModules';
import { getRouterDataBlocks } from '../routing/getRouterDataBlocks';
import { useSystemRoutes } from '../routing/useSystemRoutes';
import type { ClientSchemaMeta } from '../schema/clientSchemaMetaUtils';
import { initalizeSession, type ClientSession } from '../session';
import type { Theme } from '../styles/Theme';
import type { Views } from '../views';
import { AppRoot } from './AppRoot';
import { composeClientApp } from './composeClientApp';
import { startTrace } from './startTrace';

export interface InstallablePlugin {
  install?: () => void;
  plugin?: Component<{ children?: JSX.Children }>;
}

export interface ClientAppInput extends CreateAppInput {
  root: Component;
  install?: () => void;
  tz?: TimeZoneConfig;
  plugins?: Component<{ children?: JSX.Children }>[];
  modules?: AppClientModule[];
  backOffice?: BackOfficeSettings;
  routerPlugins?: RouterPlugin<DatabaseRouter>[];
  nodeRouting?: NodeRouteMappings;
  clientSchemaMeta?: ClientSchemaMeta<DataSchema>;
  theme?: () => Theme;
  blocks?: Record<string, BlockDefinition>;
  blockPresets?: BlockPreset[];
  views?: Views;
  localRoutes?: RouteDefinition[];
  changelog?: Changelog;
}

export interface AppClientModule<S extends Schema = DataSchema>
  extends AppModule {
  backOfficePlugins?: (() => Promise<
    InstallablePlugin | Component<{ readonly children?: JSX.Children }>
  >)[];
  blockPresets?: BlockPreset[];
  blocks?: Record<string, BlockDefinition>;
  changelog?: Changelog;
  clientSchemaMeta?: ClientSchemaMeta<DataSchema>;
  plugins?: Component<{ children?: JSX.Children }>[];
  localRoutes?: RouteDefinition[];
  views?: Views<S>;
}

export interface BackOfficeSettings {
  readonly load: () => Promise<Required<InstallablePlugin>>;
  readonly plugins?: (() => Promise<
    InstallablePlugin | Component<{ readonly children?: JSX.Children }>
  >)[];
  readonly routeProperties?: Component<{
    readonly route: DataNode<DataSchema, 'Route'>;
  }>;
}

interface PluginStore {
  app: Component<{ children?: JSX.Children }>[];
  backOffice?: Component<{ children?: JSX.Children }>[];
}

export interface ClientApp extends AppBase {
  data: DataList<DataSchema, 'App'>;
  theme: Theme;
  plugins: PluginStore;
  clientSchemaMeta?: ClientSchemaMeta<DataSchema>;
  blocks: PartialBlocks;
  blockPresets: BlockPreset[];
  views?: Views;
  backOffice?: BackOfficeSettings;
  addPlugin: (plugin: Component<{ children?: JSX.Children }>) => void;
  changelog?: Changelog[];
  tz: ClientAppInput['tz'];
}

export function bootClientApp(
  session: { current: ClientSession },
  input: ClientAppInput,
  dom: Dom,
  useRouter: (options: UseRouterOptions) => DatabaseRouter,
  fetch?: any,
  global?: Record<string | symbol, any>,
) {
  const merged = mergeModules(input);

  const appBase = createApp(merged, input.modules);
  const renderContext = createRenderContext(dom, global);
  const client = createDataClient(
    renderContext,
    appBase.schema,
    merged.clientSchemaMeta,
    merged.tz,
    fetch,
  );

  if (!dom.ssr) startTrace(client);
  registerLocales(merged.locales);
  initalizeSession({
    app: composeClientApp(appBase, merged),
    data: client,
    dom,
    session,
    defaultCulture: appBase.schema.defaultCulture as Culture,
  });

  const routerDataBlocks = getRouterDataBlocks();

  const result = withSsrResume(
    dom,
    renderContext,
    (data) => client.deserialize(data),
    () =>
      mount(
        dom,
        AppRoot,
        {
          root: input.root,
          install: merged.install,
          useRouter: () => {
            const systemRoutes = useSystemRoutes();
            return useRouter({
              schema: client.schema,
              app: session.current.app.data,
              singleCulture: !appBase.routerFollowsCultures,
              get systemRoutes() {
                return [...merged.localRoutes, ...systemRoutes.routes];
              },
              getRouterDataBlocks: routerDataBlocks,
              plugins: merged.routerPlugins,
              nodeRouting: input.nodeRouting,
            });
          },
        },
        [],
        dom.body,
        renderContext,
      ),
  );

  return result;
}
