import { setState } from '@donkeyjs/jsx-runtime';
import type { DataList, DataNode, MarkupLinkType, Node } from '@donkeyjs/proxy';
import { getAssetUrl } from '../../helpers';
import { session } from '../../session';
import { getTheme } from '../../styles';

interface MarkupLinkProps {
  entity: MarkupLinkType;
  links?: DataList<DataSchema, 'BlockLink'>;
  readonly?: boolean;
  children?: JSX.Children;
  selected?: boolean;
}

export function resolveLink(to: string) {
  if (to.startsWith('internal://')) {
    const [key, id] = to.slice(11).split('/');
    if (!key || !id) {
      return null;
    }
    const typename = (
      session.app.schema.nodes.BlockLink.fields as Record<
        string,
        { type: string }
      >
    )[key]?.type;
    return typename
      ? ({
          type: 'internal',
          key: key as keyof DataNode<DataSchema, 'BlockLink'>,
          typename: (
            session.app.schema.nodes.BlockLink.fields as Record<
              string,
              { type: string }
            >
          )[key].type,
          id,
        } as const)
      : null;
  }
  return {
    type: 'external',
    to,
  } as const;
}

export function MarkupLink(props: MarkupLinkProps) {
  const theme = getTheme();

  const state = setState({
    get resolved() {
      return resolveLink(props.entity.to);
    },

    get href() {
      if (!this.resolved) return undefined;
      if (this.resolved?.type === 'external') return this.resolved.to;
      const link = props.links?.find((link) => {
        const node =
          this.resolved && ((link as any)[this.resolved.key as any] as Node);
        return (
          !!node &&
          node.__typename === this.resolved?.typename &&
          node.id === this.resolved?.id
        );
      });
      if (!link) return undefined;
      const node = link[this.resolved.key!] as Node;
      if (node.__typename === 'File') {
        return getAssetUrl(node as any);
      }
      return session.router.getPath(
        node.__typename === 'Route'
          ? { route: node as any }
          : { node: node as any },
      );
    },
  });

  return () =>
    !state.href ? (
      <span>{props.children}</span>
    ) : (
      <a class={theme.class.link} href={state.href} target={props.entity.tg}>
        {props.children}
      </a>
    );
}
