import { bind, live } from '@donkeyjs/jsx-runtime';
import { PhPlus } from '@donkeyjs/phosphor-icons';
import { store } from '@donkeyjs/proxy';
import { MenuItem } from '../../donkey';
import { useSearchInput } from '../../helpers';
import { getI18n } from '../../i18n/getI18n';

export interface UseSelectProps<T, Mapped = T> {
  value?: T | T[] | null;
  search: string;
  readonly options: () => Mapped[] | { value: Mapped[] };
  readonly allowEmpty?: boolean;
  readonly allowSearchAfterBlur?: boolean;

  readonly mapping?: {
    get(value: T | null): Mapped;
    set(value: Mapped | null, previous: T | null): T | null;
    remove?(value: T): boolean;
  };
  readonly asString?: (value: Mapped) => string;
  readonly icon?: (value: Mapped) => JSX.Children;

  readonly prefix?: (value: Mapped) => JSX.Children;
  readonly suffix?: (value: Mapped) => JSX.Children;

  onblur?(): void;
  onchange?(value: T | T[] | null): boolean | void;
  onRequestAdd?(): Promise<Mapped | null | undefined>;
  onRequestAddLabel?: string;
  afterSelect?(): void;
}

export type SelectState<T, Mapped = T> = ReturnType<
  typeof useSelect<T, Mapped>
>;

export const useSelect = <T, Mapped = T>(
  props: UseSelectProps<T, Mapped>,
  afterSelect?: () => void,
) => {
  const i18n = getI18n();
  const search = useSearchInput({ initialValue: props.search });

  // let options: Mapped[] | { value: Mapped[] };

  const state = store({
    get isArray() {
      return Array.isArray(props.value);
    },

    get options() {
      const options = props.options();
      return 'value' in options ? options.value : options;
    },

    get searchInput() {
      return search.input;
    },

    set searchInput(value) {
      search.input = value;
    },

    values: [] as {
      mapped: Mapped | null | undefined;
      value: T | null | undefined;
      label: string;
    }[],

    index: undefined as number | undefined,

    select(value: Mapped | null) {
      const selected =
        props.mapping && value
          ? props.mapping.set(value, state.isArray ? null : (props.value as T))
          : (value as T);
      if (props.value && Array.isArray(props.value)) {
        if (selected) props.value.push(selected);
      } else {
        props.value = selected;
      }
      setValues();

      if (props.onchange?.(props.value) === false) {
        search.input = '';
        props.value = null;
        // session.dom.tick(() => {
        //   state.popperInstance?.update();
        // });
        return;
      }

      props.afterSelect?.();
      afterSelect?.();

      if (state.isArray) {
        search.input = '';
        // session.dom.tick(() => {
        //   state.popperInstance?.update();
        // });
      }
    },

    remove(value: T) {
      if (Array.isArray(props.value)) {
        const index = props.value.indexOf(value);
        if (props.mapping?.remove?.(value)) return;
        if (index !== -1) props.value.splice(index, 1);
      }
    },

    handleBlur() {
      if (!props.allowSearchAfterBlur) {
        if (
          search.input === '' &&
          !state.isArray &&
          props.value &&
          props.allowEmpty
        ) {
          props.value = null;
        } else {
          search.input = state.isArray ? '' : state.values[0]?.label || '';
        }
      }
      props.onblur?.();
    },

    get commands() {
      return {
        'common.confirm': (el: HTMLElement) => {
          const node = state.index != null && state.options[state.index];
          if (node) state.select(node);
          (el as HTMLInputElement).select();
        },
        'nav.prevItem': () => {
          if (state.options.length)
            state.index =
              ((state.index ?? 0) - 1 + state.options.length) %
              state.options.length;
        },
        'nav.nextItem': () => {
          if (state.options.length)
            state.index = ((state.index ?? -1) + 1) % state.options.length;
        },
      } as const;
    },

    renderOptions() {
      return () => {
        state.index = state.options.length && props.search ? 0 : undefined;
        const options = state.options.map((option, i) => (
          <MenuItem
            label={
              <>
                {() => props.prefix?.(option) || null}
                {() => transform(option)}
                {() => props.suffix?.(option) || null}
              </>
            }
            icon={props.icon?.(option)}
            focused={bind(() => state.index === i)}
            onclick={() => {
              state.select(option);
            }}
          />
        ));
        if (props.onRequestAdd) {
          if (options.length) options.push(<hr />);
          options.push(
            <MenuItem
              label={props.onRequestAddLabel || i18n.get('Common.Create')}
              icon={PhPlus({ weight: 'fill' })}
              onclick={async () => {
                const node = await props.onRequestAdd?.();
                if (node) state.select(node);
              }}
            />,
          );
        }
        return options;
      };
    },
  });

  const transform = (value: Mapped | null | undefined) =>
    (value && props.asString?.(value)) ?? (value?.toString() || '');

  const setValues = () => {
    const values = Array.isArray(props.value) ? props.value : [props.value];
    const valuesWithLabel = values.map((value) => {
      const mapped =
        props.mapping && value ? props.mapping.get(value) : (value as Mapped);
      return {
        value,
        mapped,
        label: transform(mapped),
      };
    });
    state.values = valuesWithLabel;
    if (!state.isArray && valuesWithLabel[0]?.value)
      search.input = valuesWithLabel[0]?.label || '';
  };

  live(setValues);

  live(() => {
    if (state.isArray) {
      props.search = search.value;
    } else {
      props.search =
        !search.value ||
        !state.values[0]?.value ||
        search.value !== state.values[0]?.label
          ? search.value
          : '';
    }
  });

  return state;
};
