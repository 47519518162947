import {
  createSchemaInput,
  extendSchema,
  type SchemaFromInput,
} from '@donkeyjs/proxy';

export const blocksSort = [
  { name: 'AUTO', order: ['sortIndex:auto'] },
] as const;

export const baseSchema = extendSchema(
  createSchemaInput({
    enums: {
      ActionType: ['SENDMAIL'],

      ImageAlign: ['LEFT', 'CENTER', 'RIGHT'],
      ImageFit: ['FIT', 'CROP'],
      ImageTextWrap: ['BREAK', 'WRAP'],

      MessageEventType: ['BOUNCE', 'COMPLAINT', 'DELIVERY'],
      MessageStatus: ['ERROR', 'READY', 'SENT'],

      Theme: ['DARK', 'LIGHT'],

      TraceEventSource: ['CLIENT', 'SSR', 'API', 'GRAPHQL'],
      TraceEventType: ['EXCEPTION', 'ROUTING', 'CLICK', 'INPUT', 'CONSOLE'],

      UserEmailVerification: ['SENT', 'VERIFIED'],
    },

    nodes: {
      Action: {
        hook: 'string',
        messageTemplate: ['MessageTemplate?', { reverse: 'actions' }],
        sortIndex: 'float',
        type: 'ActionType',
      },

      App: {
        allowUserRegistration: 'boolean?',
        defaultImage: 'File?',
        defaultTimeZone: 'string?',
        favicon: ['File?', { reverse: 'onAppFavicon' }],
        gaTrackingId: 'string?',
        googleMapsEmbedKey: 'string?',
        robots: 'string?',
        hostname: ['string?', { unique: true }],
        name: ['string', { i18n: true }],
        sendMailFrom: 'string?',
        time12Hour: 'boolean?',
        verifyUserEmails: 'boolean?',
      },

      Block: {
        onAppBlocks: [
          'App?',
          {
            embeddedList: true,
            reverse: 'blocks',
            cascadeDelete: true,
            sort: blocksSort,
          },
        ],
        onRouteBlocks: [
          'Route?',
          {
            embeddedList: true,
            reverse: 'blocks',
            cascadeDelete: true,
            sort: blocksSort,
          },
        ],
        onRouteSummary: [
          'Route?',
          {
            embeddedList: true,
            reverse: 'summary',
            cascadeDelete: true,
            sort: blocksSort,
          },
        ],
        onViewBlocks: [
          'View?',
          {
            embeddedList: true,
            reverse: 'blocks',
            cascadeDelete: true,
            sort: blocksSort,
          },
        ],
        culture: ['string', { size: 10 }],
        locked: 'boolean?',
        segment: ['string?', { size: 20 }],
        type: ['string?', { size: 20 }],
        settings: 'json?',
        sortIndex: 'float?',
        text: ['string?', { markup: true }],
        parent: [
          'Block?',
          {
            embeddedList: true,
            reverse: 'children',
            cascadeDelete: true,
            sort: blocksSort,
          },
        ],
      },

      BlockLink: {
        block: ['Block', { reverse: 'links', cascadeDelete: true }],
        route: ['Route?', { reverse: 'links' }],
        file: ['File?', { reverse: 'links' }],
      },

      File: {
        credits: ['string?', { i18n: true }],
        description: ['string?', { i18n: true }],
        fileExtension: ['string', { size: 20 }],
        fileType: ['string', { size: 50 }],
        height: 'float?',
        hideFromFilesPanel: 'boolean?',
        name: ['string', { i18n: true }],
        pages: 'int?',
        dominant: ['string?', { size: 6 }],
        sortIndex: 'float?',
        uploadError: 'boolean',
        uploadStatus: 'string',
        width: 'float?',
        crop: 'json?',
      },

      FileRef: {
        align: 'ImageAlign?',
        app: [
          'App?',
          { embeddedList: true, reverse: 'images', cascadeDelete: true },
        ],
        description: ['string', { i18n: true }],
        file: [
          'File',
          { reverse: 'references', cascadeDelete: true, embeddedList: true },
        ],
        fit: 'ImageFit?',
        maxWidth: 'int?',
        name: ['string?', { i18n: true }],
        sortIndex: 'float?',
        textWrap: 'ImageTextWrap?',

        place: ['Place?', { reverse: 'images', cascadeDelete: true }],
        block: ['Block?', { reverse: 'files', cascadeDelete: true }],
      },

      Message: {
        fromUser: ['User?', { reverse: 'messagesSent' }],
        toUser: ['User?', { reverse: 'messagesReceived' }],
        body: 'string',
        fromEmail: 'string?',
        fromName: 'string?',
        inlineCssOnSend: 'boolean?',
        isHtml: 'boolean?',
        name: 'string',
        providerId: ['string?', { unique: true }],
        sendingError: 'string?',
        sentAt: 'date?',
        status: 'MessageStatus?',
        subject: 'string',
        template: ['MessageTemplate?', { reverse: 'messages' }],
        toEmail: 'string?',
        toName: 'string?',
        topic: 'string?',
      },

      MessageEvent: {
        message: ['Message', { reverse: 'log' }],
        providerId: ['string?', { unique: true }],
        providerTopic: 'string?',
        topic: 'string?',
        type: 'MessageEventType',
        temporary: 'boolean?',
        info: 'json?',
      },

      MessageTemplate: {
        // fromUser: ['User', { reverse: 'onMessageTemplateFromUser' }],
        // toUser: ['User', { reverse: 'onMessageTemplateToUser' }],
        body: ['string', { i18n: true }],
        fromEmail: 'string?',
        fromName: 'string?',
        name: 'string',
        subject: ['string', { i18n: true }],
        toEmail: 'string?',
        toName: 'string?',
      },

      Place: {
        geometry: ['json?', { hideFromForms: true }],
        googlePlacesId: ['string?', { hideFromForms: true }],
        icon: ['string?', { hideFromForms: true }],
        phoneNumber: 'string?',
        postalCode: 'string?',
        website: 'string?',
        name: ['string', { i18n: true }],
        city: ['string?', { i18n: true }],
        country: ['string?', { i18n: true }],
        region: ['string?', { i18n: true }],
        address: ['string?', { i18n: true }],
        timeZone: ['string?', { hideFromForms: true }],
      },

      Role: {
        key: 'string',
        name: ['string', { i18n: true }],
      },

      Route: {
        name: ['string', { i18n: true }],
        image: ['FileRef?', { reverse: 'routeImages' }],

        metaTitle: ['string?', { i18n: true }],
        metaDescription: ['string?', { i18n: true }],
        metaImage: ['FileRef?', { reverse: 'routeMetaImages' }],

        hidden: 'boolean?',
        sortIndex: 'float?',
        segment: ['string?', { size: 20 }],
        key: ['string?', { size: 20 }],
        template: ['string?', { size: 30 }],
        accessibleToRoles: 'string?',
        visibleToRoles: 'string?',

        pathPrefix: 'string?',
        redirect: 'string?',

        app: [
          'App',
          {
            reverse: 'routes',
            cascadeDelete: true,
            sort: [{ name: 'AUTO', order: ['sortIndex:auto'] }],
          },
        ],
        parentRoute: [
          'Route?',
          {
            embeddedList: true,
            reverse: 'children',
            reverseLookup: 'cache',
            cascadeDelete: true,
            sort: [{ name: 'AUTO', order: ['sortIndex:auto'] }],
          },
        ],
      },

      Tag: {
        group: ['TagGroup?', { reverse: 'tags' }],
        name: ['string', { i18n: true }],
        segment: ['string?', { size: 50 }],
        sortIndex: 'float?',
        typename: ['string', { size: 30 }],
      },

      TagGroup: {
        name: ['string', { i18n: true }],
        typename: ['string', { size: 30 }],
        sortIndex: 'float?',
      },

      TagFiles: {
        tag: ['Tag', { reverse: 'files', cascadeDelete: true }],
        file: ['File', { reverse: 'tags', cascadeDelete: true }],
      },

      TagRoutes: {
        tag: ['Tag', { reverse: 'routes', cascadeDelete: true }],
        route: ['Route', { reverse: 'tags', cascadeDelete: true }],
      },

      Task: {
        name: 'string',
        dueDate: 'date?',
        completedAt: 'date?',
        assignee: ['User?', { reverse: 'assignedTasks' }],
        parent: ['Task?', { reverse: 'children', cascadeDelete: true }],
        sortIndex: 'float?',
      },

      TaskMember: {
        task: ['Task', { reverse: 'members', cascadeDelete: true }],
        user: ['User', { reverse: 'tasks', cascadeDelete: true }],
        seenAt: 'date?',
      },

      User: {
        roles: 'string?',
        email: ['string', { unique: true, size: 100 }],
        emailVerification: 'UserEmailVerification?',
        password: 'string?',
        title: ['string?', { size: 20 }],
        firstName: ['string?', { size: 80 }],
        lastName: ['string?', { size: 80 }],
        middleName: ['string?', { size: 80 }],

        lastLogin: 'date?',
        openedChangelogAt: 'date?',
        resetPasswordSecretId: ['string?', { size: 10 }],
        resetPasswordRequestedAt: 'date?',

        theme: 'Theme?',
        ui: 'json?',
        uiCulture: ['string?', { size: 10 }],
      },

      View: {
        typename: ['string', { size: 30 }],
        name: ['string', { i18n: true }],
      },
    },

    resolvers: {
      App: {
        app: {
          return: 'one',
          where: { hostname: 'hostname' },
        },
        apps: {
          return: 'many',
          sort: [{ name: 'NAME', order: ['name:asc'] }],
        },
      },

      Action: {
        actions: {
          return: 'many',
        },
      },

      File: {
        files: {
          return: 'many',
          sort: [
            { name: 'CREATED_DESC', order: ['createdAt:desc'] },
            { name: 'CREATED', order: ['createdAt:asc'] },
            { name: 'NAME', order: ['name:asc'] },
            { name: 'NAME_DESC', order: ['name:desc'] },
          ],
          where: {
            hideFromFilesPanel: ['hideFromFilesPanel', ['notEq']],
            tags: ['tags.tag', ['eq']],
          },
        },
      },

      Message: {
        messages: {
          return: 'many',
          sort: [{ name: 'LATEST', order: ['createdAt:desc'] }],
        },
      },

      Tag: {
        tags: {
          return: 'many',
          sort: [
            { name: 'SORTED', order: ['sortIndex:auto'] },
            { name: 'NAME', order: ['name:asc'] },
          ],
          where: { typename: ['typename', ['eq', 'notEq', 'in']] },
        },
      },

      TagGroup: {
        tagGroups: {
          return: 'many',
          where: { typename: ['typename', ['eq', 'notEq', 'in']] },
          sort: [
            { name: 'SORTED', order: ['sortIndex:auto'] },
            { name: 'NAME', order: ['name:asc'] },
          ],
        },
      },

      Task: {
        tasks: {
          return: 'many',
          sort: [{ name: 'AUTO', order: ['sortIndex:auto'] }],
        },
      },

      User: {
        user: { return: 'one' },
        users: {
          return: 'many',
          sort: [
            {
              name: 'NAME',
              order: [
                'lastName:asc',
                'firstName:asc',
                'middleName:asc',
                'email:asc',
              ],
            },
            { name: 'EMAIL', order: ['email:asc'] },
            { name: 'EMAIL_DESC', order: ['email:desc'] },
          ],
        },
      },

      View: {
        views: {
          return: 'many',
          where: { typename: ['typename', ['eq']] },
        },
      },
    },

    customResolvers: {
      queries: {
        fetchEmbed: {
          args: { url: 'string' },
          returns: 'json',
        },
        signedFileUrl: {
          args: {
            fileId: 'string',
          },
          returns: 'string?',
        },
        searchGooglePlaces: {
          args: { query: 'string' },
          returns: 'json',
        },
      },

      mutations: {
        login: {
          args: {
            email: 'string',
            password: 'string',
          },
          returns: 'User',
        },
        logout: {
          returns: 'boolean',
        },
        resetPassword: {
          args: {
            email: 'string',
          },
          returns: 'string',
        },
        processUpload: {
          args: {
            filename: 'string',
          },
          returns: 'File',
        },
        submitContactForm: {
          args: {
            id: 'string',
            name: 'string',
            email: 'string',
            subject: 'string?',
            message: 'string',
          },
          returns: 'boolean',
        },
        upload: {
          args: {
            fileExtension: 'string',
            fileType: 'string',
            name: 'string',
            tagId: 'string?',
            hideFromFilesPanel: 'boolean?',
          },
          returns: 'File',
        },
        performMaintenance: {
          returns: 'boolean',
        },
      },
    },
  } as const),
).with({
  databaseSchema: 'admin',

  nodes: {
    Deployment: {
      appKey: 'string',
      at: 'date',
    },

    Exception: {
      appKey: 'string',
      message: 'string',
      filename: 'string',
      line: 'int',
      col: 'int',
      fixed: 'boolean?',
    },

    Trace: {
      appKey: 'string',
      userAgent: 'string',
      ip: 'string',
      user: ['User?', { reverse: 'exceptions' }],
      userEmail: 'string?',
    },

    TraceEvent: {
      appKey: 'string',
      type: 'TraceEventType',
      source: 'TraceEventSource',
      at: 'date',
      target: 'string?',
      data: 'json?',
      trace: [
        'Trace',
        {
          reverse: 'events',
          sort: [{ name: 'DESC', order: ['at:asc'] }],
          cascadeDelete: true,
        },
      ],
      exception: [
        'Exception?',
        {
          reverse: 'events',
        },
      ],
    },
  },

  customResolvers: {
    mutations: {
      registerException: {
        args: {
          message: 'string',
          filename: 'string',
          line: 'int',
          col: 'int',
        },
        returns: 'Exception',
      },
    },
  },
} as const).result;

export type BaseSchemaInput = typeof baseSchema;
export type BaseSchema = SchemaFromInput<BaseSchemaInput>;

export interface UrlMetadata {
  title?: string;
  description?: string;
  thumbnail?: string;
  url: string;
}
