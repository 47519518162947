import { session } from '@donkeyjs/client';
import { getContext, setContext } from '@donkeyjs/jsx-runtime';
import { useEditions } from '@donkeyjs/module-festival';
import { meta } from '@donkeyjs/proxy';

const key = Symbol('featured');

export const initializeFeatured = () => {
  const editions = useEditions();

  const events = session.data.getEvents({
    get skipExecution() {
      return !editions.current || meta(editions.current).isLoading;
    },

    get where() {
      return {
        featured: { eq: true },
        edition: { eq: editions.current.id },
      };
    },
  });

  meta(events).request({ name: true });

  const photos = session.data.getPhotos({
    get skipExecution() {
      return (
        !editions.current ||
        meta(editions.current).isLoading ||
        !editions.isPast
      );
    },

    where: { featured: { eq: true } },
  });

  return setContext(key, {
    events,
    photos,
  });
};

export const useFeatured = (): ReturnType<typeof initializeFeatured> =>
  getContext(key);
