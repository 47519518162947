import { bind, bindContext, getGlobal, live } from '@donkeyjs/jsx-runtime';
import { PhFaders } from '@donkeyjs/phosphor-icons';
import {
  meta,
  store,
  type DataList,
  type DataNode,
  type FieldSchema,
  type NodeTypename,
  type ResolverSchema,
} from '@donkeyjs/proxy';
import type { BlockProps } from '..';
import { CheckboxList } from '../../components/CheckboxList';
import { formulaContext, valueFromFilter } from '../../data/filters';
import { useSearchInput } from '../../helpers/useSearchInput';
import { getI18n, type I18nContext } from '../../i18n/getI18n';
import { text } from '../../i18n/i18n';
import { SlideOut } from '../../layout';
import { Loader } from '../../loaders';
import { session } from '../../session';
import { WithHeading, getTheme } from '../../styles';
import type { DataSettings, DataSettingsFilter } from '../DataBlock';
import styles from './DataFilters.module.css';
import { DateFilter } from './DateFilter/DateFilter';

export interface DataFilter {
  type: 'in' | 'eq' | 'gt' | 'gte' | 'lt' | 'lte' | 'search';
  key: string;
  dataType: string;
  label: () => string;
  options?:
    | NodeTypename<DataSchema>
    | (() => { value: string; label: string }[]);
}

interface DataFiltersList {
  key: string;
  fieldName: string | undefined;
  heading: string;
  default: string[];
  filter: DataSettingsFilter;
  options: {
    label: string;
    value: string;
    count?: number;
    draft?: boolean;
  }[];
}

interface DataFiltersContext {
  typename?: NodeTypename<DataSchema>;
  isLoading?: boolean;
  settings?: DataSettings;
  resolverSchema: ResolverSchema | undefined;
  tagGroups?: DataList<DataSchema, 'TagGroup'>;
  filters?: (DataFiltersList | 'search')[];
}

const key = Symbol('DataFilters');
const getContext = () =>
  getGlobal<{ current: DataFiltersContext | undefined }>(key, () =>
    store({ current: undefined }),
  );

interface DataFiltersProps {
  mobile?: boolean;
}

export function DataFilters(props: DataFiltersProps) {
  const theme = getTheme();
  const i18n = getI18n();

  const search = useSearchInput({
    initialValue: session.router.query.search?.[0],
  });

  live((initial) => {
    const value = search.value;
    if (!initial) session.router.query.search = [value];
  });

  const wrapMobile = (children: JSX.Children) =>
    props.mobile ? <MobileFilters>{children}</MobileFilters> : children;

  return () => {
    const context = getContext().current;
    if (!context) return null;
    return (
      <Loader loading={bind(() => context?.isLoading || false)} type="none">
        {() =>
          !context.filters?.length
            ? null
            : wrapMobile(
                <div>
                  {() =>
                    context.filters!.map((list) =>
                      list === 'search' ? (
                        <div class={[styles.filter, 'search']}>
                          <input
                            class={theme.class.input}
                            placeholder={bind(() => i18n.get('Common.Search'))}
                            value={bind(search, 'input')}
                          />
                        </div>
                      ) : list.filter.kind === 'date' ? (
                        <div class={['filter', styles.filter, list.key]}>
                          <DateFilter
                            key={list.key}
                            value={bind(
                              () => session.router.query[list.key]?.[0],
                              (value) => {
                                session.router.query[list.key] = value
                                  ? [value]
                                  : undefined;
                              },
                            )}
                            values={list.options.map((option) => option.value)}
                          />
                        </div>
                      ) : (
                        <div class={['filter', styles.filter, list.key]}>
                          <WithHeading
                            heading={() => list.heading}
                            styleAs="heading1"
                          >
                            <div class="filter-options">
                              <CheckboxList
                                values={bind(
                                  () => {
                                    const result =
                                      session.router.query[list.key];
                                    return !result || !result.length
                                      ? list.default
                                      : result;
                                  },
                                  (value) => {
                                    const defaultValue = list.default;
                                    session.router.query[list.key] =
                                      value &&
                                      value.length === defaultValue.length &&
                                      value.every(
                                        (v, i) => v === defaultValue[i],
                                      )
                                        ? undefined
                                        : (value as any);
                                  },
                                )}
                                options={list.options}
                                collapse={{
                                  length: 6,
                                  collapseText: text('Common.ShowLess'),
                                  expandText: text('Common.ShowMore'),
                                }}
                                unique={
                                  list.filter.input === 'eq' ||
                                  (list.filter.kind !== 'tag-group' &&
                                    !context.resolverSchema?.where?.[
                                      list.key
                                    ]?.[1]?.includes('in'))
                                }
                              />
                            </div>
                          </WithHeading>
                        </div>
                      ),
                    )
                  }
                </div>,
              )
        }
      </Loader>
    );
  };
}

export const setDataFiltersContext = (
  props:
    | {
        readonly data: DataList<DataSchema>;
        readonly dataProps: BlockProps<DataSettings>;
        readonly where: any;
      }
    | undefined,
) => {
  const i18n = getI18n();

  const defaultValue = bindContext((filter: DataSettingsFilter) => {
    if (!props) return [];
    const { value } = valueFromFilter(
      filter,
      formulaContext(props.dataProps.block),
    );
    if (!value) return [];
    return Array.isArray(value) ? value : [value];
  });

  const state = props
    ? store<DataFiltersContext>({
        typename: meta(props.data).typename,
        settings: props.dataProps,

        get isLoading() {
          return meta(props.data).isLoading;
        },

        get resolverSchema() {
          return (
            props.dataProps.resolver &&
            this.typename &&
            (session.app.schema.nodes as any)[this.typename].resolvers[
              props.dataProps.resolver!
            ]
          );
        },

        get tagGroups() {
          return (
            props &&
            session.data.getTagGroups({
              get skipExecution() {
                return !props.dataProps.filters?.some(
                  (f) => f.kind === 'tag-group',
                );
              },
            })
          );
        },

        get filters(): (DataFiltersList | 'search')[] | undefined {
          return props.dataProps.filters
            ?.map((filter) => {
              if (filter.kind === 'tag-group') {
                const group = this.tagGroups?.find(
                  (tg: DataNode<DataSchema, 'TagGroup'>) =>
                    tg.id === filter.key,
                );
                if (!group || !props.data) return null;
                // const values = (props.data.aggregate as any).tags?.values;
                const options = (props.data as any)!.aggregate[
                  `tagGroup_group_${filter.key}`
                ]?.values;
                if (!options?.length) return null;
                if (filter.inputSort === 'native')
                  meta(options[0]?.value)?.request?.({ sortIndex: true });
                return {
                  key: group.id,
                  filter,
                  fieldName: filter.fieldName,
                  heading: group.name,
                  get default() {
                    return defaultValue(filter);
                  },
                  options: (filter.inputSort === 'native'
                    ? [...options].sort(
                        (a: any, b: any) =>
                          a.value.sortIndex - b.value.sortIndex,
                      )
                    : options
                  ).map((value: any) => ({
                    value: value.value.id,
                    // value: `${value.value.id}_${filter.key}`,
                    label: value.value.toString(),
                    count: value.count,
                    draft: value.value.draft,
                  })),
                };
              }
              if (filter.key === 'text-search') return 'search';

              if (
                !filter.options?.length &&
                ((filter.input ?? 'none') === 'none' ||
                  !(props.data as any)?.aggregate?.[
                    filter.fieldName || filter.key
                  ]?.values?.length)
              )
                return undefined;

              return {
                key: filter.key,
                filter,
                heading:
                  filter.name ||
                  i18n.getFieldName(
                    this.typename!,
                    filter.fieldName || (filter.key as any),
                  ),
                get default() {
                  return defaultValue(filter);
                },
                options: filter.options
                  ? filter.options.map((option) => ({
                      label: option.name,
                      value: option.key,
                    }))
                  : options(
                      i18n,
                      this.typename!,
                      filter.fieldName || filter.key,
                      (props.data as any)!.aggregate[
                        filter.fieldName || filter.key
                      ].values,
                    ),
              };
            })
            .filter(Boolean) as DataFiltersList[] | undefined;
        },
      })
    : undefined;

  getContext().current = state;

  // Trigger field loading
  const filters = state?.filters;

  return (
    (!filters || filters) &&
    (() => {
      if (getContext().current === state) {
        getContext().current = undefined;
      }
    })
  );
};

function MobileFilters(props: { readonly children?: JSX.Children }) {
  const theme = getTheme();

  const state = store({
    open: false,
  });

  return (
    <div class={styles.mobile}>
      <button
        type="button"
        class={[styles.mobileButton, theme.class.button, 'default']}
        onclick={() => (state.open = !state.open)}
      >
        <PhFaders weight="fill" />
        <span>
          {() => text(state.open ? 'Data.CloseFilters' : 'Data.Filters')}
        </span>
      </button>
      <SlideOut open={bind(() => state.open)}>{props.children}</SlideOut>
    </div>
  );
}

export interface FiltersState {
  readonly typename: NodeTypename<DataSchema> | undefined;
  readonly resolverSchema: ResolverSchema | undefined;
  // possibleFilters: DataFilter[];
  // possibleOptions: { value: string; label: string }[];
}

export const getFiltersState = (settings: {
  readonly settings: DataSettings | undefined;
}) =>
  store<FiltersState>({
    get typename() {
      return (
        settings.settings?.resolver &&
        session.app.schema.resolvers[settings.settings?.resolver]
      );
    },

    get resolverSchema(): ResolverSchema | undefined {
      return (
        settings.settings?.resolver &&
        this.typename &&
        (session.app.schema.nodes[this.typename] as any).resolvers[
          settings.settings?.resolver
        ]
      );
    },
  });

const options = (
  i18n: I18nContext,
  typename: string,
  key: string,
  values: { count: number; value: any }[],
) => {
  const nodeSchema = (session.app.schema.nodes as any)[typename];
  const fieldSchema: FieldSchema | undefined = (nodeSchema.fields[key] ||
    nodeSchema.reverseFields[key]) as FieldSchema;
  if (!fieldSchema) return [];

  if (fieldSchema.enum) {
    const type = fieldSchema.type as keyof DataSchema['enums'];
    return values.map((value) => ({
      value: (value.value as string).toLowerCase(),
      label: i18n.getEnumValue(type, value.value),
      count: value.count,
      draft: value.value.draft,
    }));
  }

  if (fieldSchema.scalar) {
    return values.map((value) => ({
      value: value.value,
      label: value.value,
      count: value.count,
      draft: value.value.draft,
    }));
  }

  return values.map((value) => ({
    value: value.value.id,
    label: value.value.toString(),
    count: value.count,
    draft: value.value.draft,
  }));
};
