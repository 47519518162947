import {
  PhLink,
  PhTextAlignCenter,
  PhTextAlignLeft,
  PhTextAlignRight,
  PhTextB,
  PhTextItalic,
  PhTextStrikethrough,
} from '@donkeyjs/phosphor-icons';

import {
  bind,
  documentSelection,
  jsxx,
  preventDefault,
  type ScopedDocumentSelection,
} from '@donkeyjs/jsx-runtime';
import { meta, store, type MarkupLinkType } from '@donkeyjs/proxy';
import { dropDownMenu, tooltip } from '../../donkey';
import { I18nBlocks } from '../../i18n/I18nBlocks';
import { getI18n } from '../../i18n/getI18n';
import { text } from '../../i18n/i18n';
import { getBlockIcon } from '../fields/blocks/helpers/getBlockIcon';
import { textBlockTypes } from '../fields/blocks/helpers/getTextBlockElement';
import { resolveLink } from './MarkupLink';
import type { MarkupTreeProps } from './MarkupTree';
import { LinkEditorDialog } from './dialogs/LinkEditorDialog';

interface MarkupToolbarProps {
  locked: boolean;
  readonly editor: MarkupTreeProps;
  readonly selection: ScopedDocumentSelection | null;
}

export function MarkupToolbar(props: MarkupToolbarProps) {
  return () =>
    props.editor.processor.isPlainText ? null : (
      <>
        {(textBlockTypes as string[]).includes(
          props.editor.interface.getType() || '',
        ) && jsxx(Paragraph, props)}
        {jsxx(Formatting, props)}
        {jsxx(Entities, props)}
      </>
    );
}

function Paragraph(props: MarkupToolbarProps) {
  const i18n = getI18n(true);

  // let cursor: [any, any] | null = null;

  return (
    <>
      <div role="group">
        <button
          type="button"
          onmousedown={(ev: MouseEvent) => {
            ev.stopPropagation();
            ev.preventDefault();
          }}
          onmount={
            dropDownMenu(
              () =>
                textBlockTypes.map((type) => ({
                  label: i18n.get(I18nBlocks, `BlockTypes.${type}`) || '',
                  onmousedown: (ev: MouseEvent) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                  },
                  onclick: () => {
                    if (props.selection) {
                      const selection = props.selection;
                      props.editor.processor.updateSelection(selection, () => {
                        const element = props.editor.interface.setType(type);
                        if (element) {
                          selection.element = element;
                        }
                      });
                    }
                  },
                })),
              { showOnClick: true },
            ).attach
          }
        >
          {getBlockIcon(props.editor.interface.getType())({
            weight: 'duotone',
          })}
          <span>
            {() =>
              i18n.get(
                I18nBlocks,
                `BlockTypes.${props.editor.interface.getType() || 'P'}`,
              )
            }
          </span>
        </button>
      </div>
      <div role="group">
        <button
          type="button"
          onmousedown={preventDefault(() => {
            props.editor.interface.setAlign('left');
          })}
          onmount={tooltip(() => i18n.get(I18nBlocks, 'Markup.Align.Left'))}
          class={bind(() => ({
            selected: props.editor.interface.getAlign() === 'left',
          }))}
        >
          <PhTextAlignLeft weight="bold" />
        </button>
        <button
          type="button"
          onmousedown={preventDefault(() => {
            props.editor.interface.setAlign('center');
          })}
          onmount={tooltip(() => i18n.get(I18nBlocks, 'Markup.Align.Center'))}
          class={bind(() => ({
            selected: props.editor.interface.getAlign() === 'center',
          }))}
        >
          <PhTextAlignCenter weight="bold" />
        </button>
        <button
          type="button"
          onmousedown={preventDefault(() => {
            props.editor.interface.setAlign('right');
          })}
          onmount={tooltip(() => i18n.get(I18nBlocks, 'Markup.Align.Right'))}
          class={bind(() => ({
            selected: props.editor.interface.getAlign() === 'right',
          }))}
        >
          <PhTextAlignRight weight="bold" />
        </button>
      </div>
    </>
  );
}

function Formatting(props: MarkupToolbarProps) {
  const i18n = getI18n(true);

  const state = store({
    get formatting() {
      return props.selection != null
        ? props.editor.processor.getFormatting(props.selection.from)
        : [];
    },
    link: undefined as string | undefined,
  });

  const toggleBold = () => {
    if (props.selection)
      props.editor.processor.updateSelection(props.selection, () =>
        props.editor.processor.toggleFormatting(
          props.selection!.from,
          props.selection!.to,
          'b',
        ),
      );
  };

  const toggleItalic = () => {
    if (props.selection)
      props.editor.processor.updateSelection(props.selection, () =>
        props.editor.processor.toggleFormatting(
          props.selection!.from,
          props.selection!.to,
          'i',
        ),
      );
  };

  const toggleStrikeThrough = () => {
    if (props.selection)
      props.editor.processor.updateSelection(props.selection, () =>
        props.editor.processor.toggleFormatting(
          props.selection!.from,
          props.selection!.to,
          's',
        ),
      );
  };

  return (
    <div role="group">
      <button
        type="button"
        class={bind(() => ({
          selected: state.formatting.includes('b'),
        }))}
        onmount={tooltip(() => i18n.get(I18nBlocks, 'Markup.Bold'))}
        onmousedown={preventDefault(toggleBold)}
      >
        <PhTextB weight="bold" />
      </button>
      <button
        type="button"
        class={bind(() => ({
          selected: state.formatting.includes('i'),
        }))}
        onmount={tooltip(() => i18n.get(I18nBlocks, 'Markup.Italic'))}
        onmousedown={preventDefault(toggleItalic)}
      >
        <PhTextItalic weight="bold" />
      </button>
      <button
        type="button"
        class={bind(() => ({
          selected: state.formatting.includes('s'),
        }))}
        onmount={tooltip(() => i18n.get(I18nBlocks, 'Markup.Strikethrough'))}
        onmousedown={preventDefault(toggleStrikeThrough)}
      >
        <PhTextStrikethrough weight="bold" />
      </button>
    </div>
  );
}

function Entities(props: MarkupToolbarProps) {
  const state = store({
    get entities() {
      return props.selection == null
        ? []
        : props.editor.processor.getEntities(props.selection.from);
    },
    get inLink() {
      return state.entities.find((e): e is MarkupLinkType => 'to' in e);
    },
    linkDialog: false as boolean | MarkupLinkType,
  });

  let selection: ScopedDocumentSelection | null = null;

  return (
    <>
      <div role="group">
        <button
          type="button"
          onmousedown={preventDefault(() => {
            selection = props.selection;
            props.locked = true;
            state.linkDialog = state.inLink || true;
          })}
          disabled={bind(
            () =>
              !props.selection || (props.selection?.collapsed && !state.inLink),
          )}
        >
          <PhLink weight="bold" />
          <span>
            {() =>
              state.inLink || typeof state.linkDialog === 'object'
                ? text(I18nBlocks, 'Links.Edit')
                : text(I18nBlocks, 'Links.Create')
            }
          </span>
        </button>
      </div>

      {() => {
        if (!state.linkDialog) return;

        function deleteLink(link: MarkupLinkType) {
          const resolved = resolveLink(link.to);
          if (resolved?.type === 'internal') {
            meta(
              props.editor.links?.find(
                (link) => (link[resolved.key] as any)?.id === resolved.id,
              ),
            )?.delete();
          }
        }

        const link = state.linkDialog === true ? undefined : state.linkDialog;
        return (
          <LinkEditorDialog
            to={link?.to}
            tg={link?.tg}
            onDelete={bind(() =>
              link
                ? () => {
                    deleteLink(link);
                    props.editor.processor.removeEntity(link);
                  }
                : undefined,
            )}
            onClose={(value) => {
              if (value && link && value.to !== link.to) {
                deleteLink(link);
              }

              if (value) {
                const resolved = resolveLink(value.to);
                if (resolved?.type === 'internal') {
                  props.editor.links?.push({
                    [resolved.key]: resolved.id,
                  });
                }
              }

              if (value && link) {
                props.editor.processor.updateEntity(link, value);
              } else if (value && selection) {
                props.editor.processor.applyLink(
                  selection.from,
                  selection.to,
                  value,
                );
              }

              state.linkDialog = false;

              props.locked = false;
              if (selection) {
                documentSelection.set(selection);
                selection = null;
              }
            }}
          />
        );
      }}
    </>
  );
}
