import { getI18n } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { useEditions } from '@donkeyjs/module-festival';
import { Decoration, DecorationRight } from './Decoration';
import styles from './Header.module.css';
import { Logo } from './Logo';

export function Header() {
  const edition = useEditions();
  const i18n = getI18n();

  return (
    <div class={styles.header}>
      <h1>Muze van Zuid</h1>
      <span class={styles.logo}>
        <Logo
          year={bind(
            () =>
              edition.featured.firstDay.getFullYear().toString() ||
              new Date().getFullYear().toString(),
          )}
        />
      </span>
      <span class={styles.festival}>Muziekfestival</span>
      <span class={styles.zuid}>Amsterdam Zuid</span>
      {() =>
        edition.featured && (
          <>
            <span class={styles.days}>
              {`${edition.featured.firstDay.getDate()}-${edition.featured.lastDay.getDate()}`}
            </span>
            <span class={styles.date}>
              {() =>
                `${edition.featured.firstDay.getDate()}-${edition.featured.lastDay.getDate()} ${i18n.formatDate(
                  edition.featured.firstDay,
                  'MMMM',
                )}`
              }
            </span>
            <span class={styles.month}>
              {i18n
                .formatDate(edition.featured.firstDay, 'MMM')
                .replace('sep.', 'sept')}
            </span>
          </>
        )
      }
      <Decoration />
      <DecorationRight />
    </div>
  );
}
