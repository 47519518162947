import { traceInput } from '@donkeyjs/core';
import { bind, jsxx } from '@donkeyjs/jsx-runtime';
import { map, store, type Culture } from '@donkeyjs/proxy';
import type { FieldRenderProps } from '../..';
import { Flag } from '../../../i18n/Flag';
import { session } from '../../../session';
import { getTheme } from '../../../styles';
import { Label } from '../../components/Label';
import styles from './HtmlString.module.css';
import { OptionButtons } from './OptionButtons';
import { useMask } from './useMask';
import { useStringEditor } from './useStringEditor';

export function HtmlString(props: FieldRenderProps<'string'>) {
  return (
    <Label
      class={bind(() => ['input', props.class])}
      label={bind(() => props.label)}
      helper={bind(() => props.helper)}
      required={!props.field.schema?.optional}
      onmount={props.onmount}
    >
      {() =>
        session.app.routerFollowsCultures && props.field.cultures ? (
          <div class={[styles.cultures, 'cultures']}>
            {Object.entries(props.field.cultures).map(([culture, field]) => (
              <div class={[styles.culture, 'culture']}>
                {jsxx(
                  Value,
                  store.clone(props, { culture: culture as Culture, field }),
                )}
              </div>
            ))}
          </div>
        ) : (
          jsxx(Value, props)
        )
      }
    </Label>
  );
}

function Value(props: FieldRenderProps<'string'> & { culture?: Culture }) {
  const theme = getTheme();

  const state = useStringEditor(props);

  const oninput = () => {
    if (props.field.parent)
      traceInput(`${props.field.parent.typename}/${props.field.parent.key}`);
  };

  const { onchange, onkeydown, onvalueupdate, placeholder } = props.mask
    ? useMask({
        mask: props.mask,
        onkeydown: props.onkeydown,
        onchange: (value) => {
          state.value = value;
        },
      })
    : {
        onkeydown: props.onkeydown,
        placeholder: props.placeholder,
        onchange: oninput,
        onvalueupdate: undefined,
      };

  return (
    <>
      {props.prefix}
      {() =>
        !!props.culture && (
          <span class={[styles.flag, 'flag']}>
            <Flag culture={props.culture} />
          </span>
        )
      }
      {() =>
        state.options?.length && props.optionButtons ? (
          <OptionButtons
            value={bind(state, 'value')}
            options={bind(() => state.options!)}
            optionButtons={props.optionButtons}
          />
        ) : state.options ? (
          <select
            class={theme.class.select}
            value={bind(state, 'value')}
            onchange={oninput}
            onfocus={props.onfocus}
            onblur={props.onblur}
            onclick={props.onclick}
            onmousedown={props.onmousedown}
            onkeydown={props.onkeydown}
            disabled={bind(() => props.readonly)}
            placeholder={bind(() => props.placeholder)}
            name={bind(() => props.name)}
            autofocus={bind(() => props.autofocus)}
          >
            {() =>
              (!props.field.value || !!props.field.schema?.optional) &&
              !props.defaultOption && (
                <option value="">{props.placeholder || ''}</option>
              )
            }
            {map(
              () => state.options,
              (option) => (
                <option
                  value={option.value}
                  selected={bind(() =>
                    state.value === option.value ? true : undefined,
                  )}
                >
                  {option.label}
                </option>
              ),
            )}
          </select>
        ) : props.multiline ? (
          <textarea
            class={theme.class.textarea}
            value={bind(state, 'value')}
            oninput={oninput}
            onfocus={props.onfocus}
            onblur={props.onblur}
            onclick={props.onclick}
            onmousedown={props.onmousedown}
            onkeydown={props.onkeydown}
            disabled={bind(() => props.readonly)}
            placeholder={bind(() => props.placeholder)}
            name={bind(() => props.name)}
            autofocus={bind(() => props.autofocus)}
          />
        ) : (
          <input
            class={theme.class.input}
            value={bind(state, 'value')}
            type={bind(() =>
              props.password
                ? 'password'
                : props.email
                  ? 'email'
                  : props.inputType || 'text',
            )}
            autocomplete={bind(() => (props.email ? 'email' : undefined))}
            oninput={onchange}
            onfocus={props.onfocus}
            onblur={props.onblur}
            onclick={props.onclick}
            onmousedown={props.onmousedown}
            onkeydown={onkeydown}
            onvalueupdate={onvalueupdate}
            disabled={bind(() => props.readonly)}
            placeholder={bind(() => placeholder)}
            name={bind(() => props.name)}
            autofocus={bind(() => props.autofocus)}
          />
        )
      }
      {props.suffix}
    </>
  );
}
