import { computed, signal } from '@donkeyjs/proxy';
import { withContext } from '../component';
import type { DomElement, DomNode, JSXNode, JSXVirtualNode } from '../dom';
import { Fragment, processBeforeMount } from '../index';
import { componentContext } from '../mount/mount';
import { createJSXNode } from './createJSXNode';

export function createFragment(
  fragment: JSX.Element[],
  parent?: DomElement,
  nextSsrCandidate?: Node | null,
): JSXVirtualNode {
  const parentContext = componentContext.current!;
  let context = parentContext;
  if (!context) throw new Error('Cannot render a JSX element without context');

  if (parent) {
    context = Object.create(context);
    context.onMount = [];
    context.onUnmount = [];
  }
  let ssrCandidateFromCursor = context.dom.ssrCursor?.get(
    parent as unknown as Node,
  );
  let ssrCandidate = parent ? ssrCandidateFromCursor : nextSsrCandidate;

  const previousEach: {
    jsx: JSXVirtualNode;
    nodes: DomNode[];
  }[] = [];

  const current = signal(fragment);

  const value = computed(() =>
    withContext(context, () => {
      let i = 0;

      if (!context.dom.ssrCursor) ssrCandidate = undefined;
      // if (parent) documentSelection.store(parent as Element);

      const next: DomNode[] = [];
      for (const node of current.value) {
        const currentValue = previousEach[i];
        if (!currentValue) {
          const jsx = createJSXNode(node, ssrCandidate);
          const nodes = jsx.nodes;
          previousEach.push({
            jsx,
            nodes,
          });
          next.push(...nodes);
          if (ssrCandidate && context.dom.ssrCursor) {
            for (const domNode of nodes) {
              if (domNode === ssrCandidate) {
                ssrCandidate = ssrCandidate.nextSibling || undefined;
              } else {
                parent?.insertBefore(domNode, ssrCandidate as DomNode);
              }
            }
          } else {
            parent?.append(...nodes);
          }
          i++;
          continue;
        }

        const { jsx, nodes } = currentValue;
        const nextNode = processBeforeMount(node);
        const result = jsx.update(nextNode);
        if (result !== true) {
          console.warn(`Update failed: ${result}`);
          console.log('Previous:', jsx.currentValue);
          console.log('Next:', nextNode);
          // Debugging opportunity:
          jsx.testUpdate(nextNode);
        }
        const nextNodes = jsx.nodes;

        if (isSameArray(nextNodes, nodes)) {
          next.push(...nodes);
          i++;
          continue;
        }

        currentValue.nodes = nextNodes;
        if (parent) updateArray(parent, next.at(-1), nodes, nextNodes);
        next.push(...nextNodes);

        i++;
      }

      if (!context.dom.ssr && parent && context.onMount.length) {
        if ((parent as HTMLElement).isConnected) {
          for (const onMount of context.onMount) {
            const mountContext = onMount[1];
            if (mountContext.disposed) continue;
            const onUnmount = withContext(mountContext, onMount[0]);
            if (onUnmount) mountContext.onUnmount.push(onUnmount);
          }
        } else {
          parentContext.onMount.push(...context.onMount);
        }
        context.onMount.length = 0;
      }

      if (parent && ssrCandidateFromCursor) {
        ssrCandidateFromCursor = undefined;
        context.dom.ssrCursor!.set(
          parent as unknown as Node,
          ssrCandidate || null,
        );
        ssrCandidate = undefined;
      }

      // if (parent) documentSelection.restore(parent as Element);

      return next;
    }),
  );

  const result: JSXVirtualNode = {
    __type: 'node',
    currentValue: fragment,
    testUpdate(fragment: JSXNode | JSXNode[]) {
      const array = Array.isArray(fragment)
        ? fragment
        : fragment && typeof fragment === 'object' && fragment.tag === Fragment
          ? fragment.children
          : undefined;
      if (typeof array !== 'object' || !Array.isArray(array)) {
        return 'non-array value passed to a DOM fragment';
      }
      if (array.length !== previousEach.length) {
        return 'children of a fragment changed unexpectedly';
      }
      for (let i = 0; i < array.length; i++) {
        const current = previousEach[i];
        const test = current.jsx.testUpdate(processBeforeMount(array[i]));
        if (test !== true) {
          return `child ${i} of a fragment failed to update: ${test}`;
        }
      }
      return true;
    },
    update(value: JSXNode | JSXNode[]) {
      const fragment = processBeforeMount(value) as JSXNode | JSXNode[];
      const test = result.testUpdate(fragment);
      if (test !== true) return test;
      result.currentValue = fragment;
      const array = Array.isArray(fragment) ? fragment : fragment.children;
      // for (let i = 0; i < array.length; i++) {
      //   previousEach[i].jsx.update(array[i]);
      // }
      current.value = array;
      return true;
    },
    dispose() {
      if (parent) {
        for (const onUnmount of context.onUnmount) onUnmount();
        context.disposed = true;
        context.onUnmount.length = 0;
        for (const prev of previousEach) {
          prev.jsx.dispose();
          for (const node of prev.nodes) {
            if (node.parentNode) {
              parent.removeChild(node);
            }
          }
        }
      }
      for (const { jsx } of previousEach) {
        jsx.dispose();
      }
    },
    get nodes() {
      return value?.value || [];
    },
  };

  return result;
}

function isSameArray(a: any[], b: any[]) {
  return a.length === b.length && a.every((item, index) => item === b[index]);
}

function updateArray(
  parent: DomElement,
  after: DomNode | undefined,
  previous: DomNode[],
  next: DomNode[],
) {
  const oldChildren = Array.from(previous);
  const keyIndices = new Map(next.map((node, index) => [node, index]));
  const toMove = new Array(oldChildren.length).fill(-1);

  oldChildren.forEach((child, index) => {
    if (keyIndices.has(child)) {
      toMove[index] = keyIndices.get(child);
    } else {
      parent.removeChild(child);
    }
  });

  const lis = findLIS(toMove.filter((index) => index !== -1));
  let lisIndex = 0;

  let parentIndex = after
    ? Array.from(parent.childNodes).indexOf(after as ChildNode) + 1
    : 0;
  for (const newChild of next) {
    const oldIndex = oldChildren.indexOf(newChild);
    if (
      oldIndex !== -1 &&
      lisIndex >= 0 &&
      toMove[oldIndex] === lis[lisIndex]
    ) {
      // Node is part of LIS and at the correct position, no need to move
      lisIndex++;
    } else {
      // Node needs to be moved or is a new node
      const refNode = parent.childNodes.item(parentIndex) || null;
      parent.insertBefore(newChild, refNode as DomNode);
    }
    parentIndex++;
  }
}

function findLIS(arr: number[]): number[] {
  if (arr.length === 0) return [];

  // Array to store our subsequences
  const dp: number[] = new Array(arr.length).fill(1);
  const predecessor: number[] = new Array(arr.length).fill(-1);

  let maxIndex = 0; // This will store the index of the longest subsequence found

  // Build the dp array and update predecessors for reconstruction of sequence
  for (let i = 1; i < arr.length; i++) {
    for (let j = 0; j < i; j++) {
      if (arr[i] > arr[j] && dp[i] < dp[j] + 1) {
        dp[i] = dp[j] + 1;
        predecessor[i] = j;
      }
    }

    // Update the maxIndex if we found a new longest subsequence
    if (dp[i] > dp[maxIndex]) {
      maxIndex = i;
    }
  }

  // Reconstruct the longest increasing subsequence
  const lis: number[] = [];
  for (let i = maxIndex; i !== -1; i = predecessor[i]) {
    lis.push(arr[i]);
  }
  lis.reverse(); // reverse to get the correct order

  return lis;
}
