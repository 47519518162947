import {
  Heading,
  IncreaseHeadingLevel,
  View,
  createView,
  dropZone,
  getGoogleMapsUrl,
  getI18n,
  getNow,
  getTheme,
  getUserContext,
  session,
  text,
} from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { EmbeddedGallery } from '@donkeyjs/module-gallery';
import {
  PhBookmarks,
  PhMapTrifold,
  PhTicket,
  PhX,
} from '@donkeyjs/phosphor-icons';
import { isDataNode, store } from '@donkeyjs/proxy';
import { I18nApp } from '../../i18n';
import { Tag } from '../components/Tag';
import { ViewEventArtistThumbnail } from './ViewEventArtistThumbnail';
import styles from './ViewEventDetails.module.css';
import { ViewEventTag } from './ViewEventTag';

export const viewEventDetails = createView<DataSchema, 'Event'>(
  { name: () => 'Details' },

  function ViewEventDetails(props) {
    const user = getUserContext();
    const theme = getTheme();
    const i18n = getI18n();

    const state = store({
      get date() {
        const day = i18n.formatDate(props.node.starts, 'EEEE');
        const starts = i18n.formatDate(props.node.starts, 'p');
        const ends = i18n.formatDate(props.node.ends, 'p');
        const time = starts === ends ? starts : `${starts} - ${ends}`;
        return `${day} ${time}`;
      },
      get isHistory() {
        return props.node.starts < getNow();
      },
    });

    return (
      <div class={styles.event}>
        <article>
          <div class={styles.image}>
            <props.node.$.image readonly ratio={3} />
          </div>
          <div class={styles.heading}>
            <Heading styleAs="title">
              <a
                href={bind(() => session.router.getPath({ route: 'current' }))}
                class={styles.close}
              >
                <PhX weight="bold" />
              </a>
              <span class={styles.date}>{() => state.date}</span>
              <br />
              <props.node.$.name />
            </Heading>
            {() =>
              !!state.isHistory &&
              (!!props.node.gallery.length ||
                user.can('insert', 'FileRef')) && (
                <div class={styles.eventGallery}>
                  <EmbeddedGallery node={props.node} mode="square" />
                  <props.node.$.photoCredits />
                </div>
              )
            }
          </div>
          <IncreaseHeadingLevel>
            <div class={styles.description}>
              <props.node.$.description />
            </div>
          </IncreaseHeadingLevel>
          {() =>
            (!!props.node.artists.length || !props.readonly) && (
              <div
                class={styles.artists}
                onmount={
                  !props.readonly
                    ? dropZone({
                        key: 'artist',
                        onDrag: (item) => {
                          if (item.type !== 'node') return;

                          const dragged = item.node;
                          if (
                            !isDataNode<DataSchema, 'Artist'>(dragged, 'Artist')
                          )
                            return;

                          return {
                            positions: ['inside'],
                            hover: () =>
                              props.node.artists.testPush({
                                artist: dragged,
                              }),
                            drop: () => {
                              props.node.artists.push({ artist: dragged });
                            },
                          };
                        },
                      })
                    : undefined
                }
              >
                {() =>
                  !props.node.artists.length && !props.readonly ? (
                    <div class={styles.empty}>Drop artists here</div>
                  ) : (
                    props.node.artists.map((rel) => (
                      <View view={ViewEventArtistThumbnail} node={rel} />
                    ))
                  )
                }
              </div>
            )
          }
          {() =>
            (!!props.node.tags.length ||
              props.node.district ||
              !props.readonly) && (
              <div
                class={styles.tags}
                onmount={
                  !props.readonly
                    ? dropZone({
                        key: 'tag',
                        onDrag: (item) => {
                          if (item.type !== 'node') return;

                          const dragged = item.node;
                          if (!isDataNode<DataSchema, 'Tag'>(dragged, 'Tag'))
                            return;

                          return {
                            positions: ['inside'],
                            hover: () =>
                              props.node.tags.testPush({ tag: dragged }),
                            drop: () => {
                              props.node.tags.push({ tag: dragged });
                            },
                          };
                        },
                      })
                    : undefined
                }
              >
                {() =>
                  !!props.node.district && <Tag>{props.node.district.name}</Tag>
                }
                {() =>
                  !props.node.tags.length && !props.readonly ? (
                    <div class={styles.empty}>Drop tags here</div>
                  ) : (
                    props.node.tags.map((rel) => (
                      <View view={ViewEventTag} node={rel} />
                    ))
                  )
                }
              </div>
            )
          }
          <div class={styles.moreInfo}>
            <props.node.$.moreInfo />
          </div>
          <div class={styles.buttons}>
            {() =>
              !state.isHistory &&
              !!props.node.linkToUrl && (
                <a
                  class={[theme.class.button, 'default']}
                  href={props.node.linkToUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <PhTicket weight="fill" />
                  <span>
                    {() =>
                      props.node.linkToUrlText || text(I18nApp, 'BuyTickets')
                    }
                  </span>
                </a>
              )
            }
            {() =>
              !!props.node.venue && (
                <a
                  class={theme.class.button}
                  href={bind(() => getGoogleMapsUrl(props.node.venue))}
                  target="_blank"
                  rel="noreferrer"
                >
                  <PhMapTrifold weight="fill" />
                  <span>{props.node.venue.name}</span>
                </a>
              )
            }
            {() =>
              (!!props.node.program.length || !props.readonly) && (
                <div class={[styles.program, theme.class.button]}>
                  <PhBookmarks weight="duotone" />
                  <div>
                    <props.node.$.program />
                  </div>
                </div>
              )
            }
          </div>
        </article>
      </div>
    );
  },
);
