import { bind } from '@donkeyjs/jsx-runtime';
import styles from './Decoration.module.css';

export function Decoration2023(props: { mirrored?: boolean }) {
  return (
    <svg
      class={bind(() => [
        styles.decoration,
        { [styles.mirrored]: props.mirrored },
      ])}
      xmlns="http://www.w3.org/2000/svg"
      style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2"
      viewBox="0 0 232 167"
    >
      <title>Decoration</title>
      <path
        d="M42.313 146.817h17.031v4H42.313v42.201h-4V87.128h4v59.689Zm17.031 4h4v17.101h17.031v-38.296h4v38.296h17.031v-38.296h3.815v-3.684h17.216V45.511h4v101.306h17.216v4h3.815v17.299h17.031v-59.587h4v38.288h38.062V87.128h4v59.689h17.051v4h3.98v17.299h17.031v3.802h4v21.1h-4v-20.902h-17.031v20.902h-4v-20.902h-17.087v-3.689h-3.944v-17.61H189.53v42.201h-4v-42.201h-17.031v17.61h-3.856v3.689h-17.175v20.902h-4v-20.902h-17.031v20.902h-4v-63.08h-17.031v38.489h-3.834v3.491H84.375v21.1h-4v-21.1H63.344v21.1h-4v-42.201Zm67.093 17.299h17.031v-17.299h-17.031v17.299Zm84.124 0h17.031v-17.299h-17.031v17.299Z"
        transform="translate(-21.572 -26.861)"
      />
      <path
        d="M11.97 138.388H22.4v10.43H11.97z"
        transform="matrix(.60656 -.60656 .63724 .63724 -95.446 41.164)"
      />
      <path
        d="M11.97 138.388H22.4v10.43H11.97z"
        transform="matrix(.60656 -.60656 .63724 .63724 -95.447 -20.24)"
      />
      <path
        d="M11.97 138.388H22.4v10.43H11.97z"
        transform="matrix(.60656 -.60656 .63724 .63724 -11.476 -62.435)"
      />
      <path
        d="M11.97 138.388H22.4v10.43H11.97z"
        transform="matrix(.60656 -.60656 .63724 .63724 30.719 .731)"
      />
      <path
        d="M11.97 138.388H22.4v10.43H11.97z"
        transform="matrix(.60656 -.60656 .63724 .63724 -71.119 -20.24)"
      />
      <path
        d="M11.97 138.388H22.4v10.43H11.97z"
        transform="matrix(.60656 -.60656 .63724 .63724 12.851 -62.435)"
      />
      <path
        d="M11.97 138.388H22.4v10.43H11.97z"
        transform="matrix(.60656 -.60656 .63724 .63724 55.045 .731)"
      />
      <path
        d="M11.97 138.388H22.4v10.43H11.97z"
        transform="matrix(.60656 -.60656 .63724 .63724 84.909 -32.32)"
      />
      <path
        d="M11.97 138.388H22.4v10.43H11.97z"
        transform="matrix(.60656 -.60656 .63724 .63724 -41.34 9.371)"
      />
      <path
        d="M11.97 138.388H22.4v10.43H11.97z"
        transform="matrix(.60656 -.60656 .63724 .63724 -83.283 -32.404)"
      />
      <path
        d="M11.97 138.388H22.4v10.43H11.97z"
        transform="matrix(.60656 -.60656 .63724 .63724 .687 -74.599)"
      />
      <path
        d="M11.97 138.388H22.4v10.43H11.97z"
        transform="matrix(.60656 -.60656 .63724 .63724 42.882 -11.432)"
      />
    </svg>
  );
}

export function Decoration() {
  return (
    <svg
      class="decoration"
      xmlns="http://www.w3.org/2000/svg"
      xml:space="preserve"
      fill-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="2"
      clip-rule="evenodd"
      viewBox="0 0 210 146"
    >
      <title>Decoration</title>
      <path
        fill="#a29266"
        fill-rule="nonzero"
        d="m119.562 35.315-3.19 3.189 3.19 3.189 3.189-3.189-3.189-3.189Zm69.83-14.001-3.19 3.189 3.19 3.19 3.188-3.19-3.188-3.189Zm-22.096 57.511-3.19 3.186 3.19 3.192 3.188-3.192-3.188-3.186Zm-38.109 55.247-.78-2.725 67.893-19.41.779 2.725-67.892 19.41Z"
      />
      <path
        fill="#a29266"
        fill-rule="nonzero"
        d="m128.036 133.906-59.56-37.865 1.521-2.393 59.561 37.865-1.522 2.393Z"
      />
      <path
        fill="#a29266"
        fill-rule="nonzero"
        d="m70.65 94.939-2.828-.189 5.518-82.09 2.828.19-5.518 82.089Z"
      />
      <path
        fill="#a29266"
        fill-rule="nonzero"
        d="M68.373 95.968 11.892 52.535l1.729-2.248L70.1 93.72l-1.727 2.248Zm-40.778 30.756-3.189 3.19 3.189 3.187 3.19-3.189-3.19-3.188Z"
      />
      <path
        fill="#787e95"
        fill-rule="nonzero"
        d="M83.774 3.736c4.982 4.981 4.982 13.058 0 18.039-4.982 4.982-13.059 4.982-18.04.002-4.982-4.983-4.982-13.06.001-18.041 4.98-4.981 13.057-4.981 18.039 0Z"
      />
      <path
        fill="#a29266"
        fill-rule="nonzero"
        d="m74.754 6.378 6.379 6.377-6.379 6.378-6.378-6.378 6.378-6.377Z"
      />
      <path
        fill="#787e95"
        fill-rule="nonzero"
        d="M21.776 42.39c4.981 4.982 4.98 13.06 0 18.039-4.982 4.983-13.059 4.983-18.04.001-4.982-4.98-4.982-13.058.001-18.04 4.98-4.98 13.057-4.98 18.039 0Z"
      />
      <path
        fill="#a29266"
        fill-rule="nonzero"
        d="m12.756 45.033 6.379 6.377-6.379 6.378-6.378-6.378 6.378-6.377Z"
      />
      <path
        fill="#787e95"
        fill-rule="nonzero"
        d="M78.256 85.825c4.982 4.981 4.981 13.057-.001 18.038-4.981 4.983-13.057 4.983-18.039 0-4.981-4.981-4.981-13.057 0-18.038 4.982-4.982 13.059-4.984 18.04 0Z"
      />
      <path
        fill="#a29266"
        fill-rule="nonzero"
        d="m69.236 88.465 6.379 6.38-6.379 6.375-6.377-6.375 6.377-6.38Z"
      />
      <path
        fill="#787e95"
        fill-rule="nonzero"
        d="M137.818 123.689c4.982 4.982 4.981 13.059-.001 18.041-4.981 4.98-13.057 4.98-18.039 0-4.981-4.982-4.981-13.059-.001-18.041 4.982-4.98 13.06-4.98 18.041 0Z"
      />
      <path
        fill="#a29266"
        fill-rule="nonzero"
        d="m128.798 126.332 6.379 6.378-6.379 6.375-6.378-6.375 6.378-6.378Z"
      />
      <path
        fill="#787e95"
        fill-rule="nonzero"
        d="M205.711 104.279c4.981 4.983 4.98 13.058-.001 18.041-4.982 4.98-13.058 4.98-18.039 0-4.983-4.983-4.983-13.058-.001-18.041 4.981-4.98 13.059-4.98 18.041 0Z"
      />
      <path
        fill="#a29266"
        fill-rule="nonzero"
        d="m196.69 106.921 6.378 6.379-6.378 6.377-6.378-6.377 6.378-6.379Z"
      />
    </svg>
  );
}

export function DecorationRight() {
  return (
    <svg
      class="decoration right"
      xmlns="http://www.w3.org/2000/svg"
      xml:space="preserve"
      fill-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="2"
      clip-rule="evenodd"
      viewBox="0 0 211 137"
    >
      <title>Decoration</title>
      <g fill-rule="nonzero">
        <path
          fill="#a29266"
          d="m73.974 18.487-3.187-3.188-3.191 3.188 3.19 3.189 3.188-3.189Zm-58 12-3.187-3.188-3.191 3.188 3.19 3.189 3.188-3.189Zm69.147 93.67-2.5-1.337 59.117-110.732 2.5 1.334-59.117 110.735Z"
        />
        <path
          fill="#a29266"
          d="m82.659 124.223-33.73-55.684 2.425-1.47 33.729 55.686-2.424 1.468Zm114.863-93.592L142.58 14.112l.816-2.714 54.942 16.519-.816 2.714Z"
        />
        <path
          fill="#a29266"
          d="m13.829 111.954-2.145-1.856L49.07 66.876l2.143 1.856-37.384 43.222Z"
        />
        <path
          fill="#a29266"
          d="m83.627 124.886-71.115-12.463.489-2.793 71.115 12.463-.489 2.793ZM163.32 69.33l-3.189-3.191-3.189 3.191 3.189 3.186 3.189-3.186Zm-23.498 48.499-3.19-3.19-3.189 3.19 3.189 3.188 3.19-3.188Zm61.647-5.695-3.191-3.19-3.188 3.19 3.188 3.186 3.191-3.186Z"
        />
        <path
          fill="#787e95"
          d="M21.776 120.046c-4.982 4.982-13.058 4.98-18.039 0-4.983-4.984-4.983-13.06-.001-18.039 4.981-4.983 13.058-4.983 18.04 0 4.981 4.979 4.981 13.058 0 18.039Z"
        />
        <path
          fill="#a29266"
          d="m19.134 111.026-6.378 6.379-6.378-6.379 6.378-6.38 6.378 6.38Z"
        />
        <path
          fill="#787e95"
          d="M92.89 132.509c-4.981 4.982-13.058 4.98-18.039 0-4.981-4.983-4.981-13.059 0-18.039 4.981-4.984 13.058-4.984 18.039 0 4.982 4.98 4.982 13.056 0 18.039Z"
        />
        <path
          fill="#a29266"
          d="m90.25 123.489-6.379 6.379-6.377-6.379 6.377-6.378 6.379 6.378Z"
        />
        <path
          fill="#787e95"
          d="M59.162 76.822c-4.982 4.984-13.058 4.984-18.04 0-4.981-4.979-4.982-13.057-.001-18.037 4.982-4.981 13.059-4.981 18.041 0 4.981 4.98 4.981 13.058 0 18.037Z"
        />
        <path
          fill="#a29266"
          d="m56.519 67.805-6.378 6.377-6.377-6.377 6.377-6.378 6.378 6.378Z"
        />
        <path
          fill="#787e95"
          d="M152.009 21.775c-4.982 4.983-13.058 4.983-18.039 0-4.982-4.982-4.983-13.058-.001-18.038 4.981-4.983 13.058-4.983 18.04 0 4.981 4.98 4.981 13.058 0 18.038Z"
        />
        <path
          fill="#a29266"
          d="m149.367 12.756-6.378 6.379-6.378-6.379 6.378-6.377 6.378 6.377Z"
        />
        <path
          fill="#787e95"
          d="M206.95 38.294c-4.981 4.982-13.058 4.982-18.039 0-4.982-4.982-4.982-13.058-.001-18.04 4.981-4.981 13.059-4.981 18.04 0 4.982 4.982 4.982 13.058 0 18.04Z"
        />
        <path
          fill="#a29266"
          d="m204.308 29.275-6.378 6.378-6.377-6.378 6.378-6.379 6.377 6.379Z"
        />
      </g>
    </svg>
  );
}
