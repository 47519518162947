import {
  bind,
  bindContext,
  documentSelection,
  jsxx,
} from '@donkeyjs/jsx-runtime';
import {
  MarkupStringProcessor,
  store,
  type DataList,
  type MarkupString,
} from '@donkeyjs/proxy';
import type { OutlineEntry } from '../..';
import { Toolbar } from '../../donkey';
import { MarkupToolbar } from './MarkupToolbar';
import { MarkupTree, type MarkupTreeProps } from './MarkupTree';
import {
  singleBlockMarkupInterface,
  type MarkupInterface,
} from './interfaces/singleBlockMarkupInterface';
import { keyDownHandler } from './keyDownHandler';

export interface MarkupProps<T extends MarkupString | string> {
  value?: T | null;
  element?: HTMLElement | null;
  hasFocus?: boolean;
  readonly readonly?: boolean;
  readonly outline?: OutlineEntry<DataSchema, 'Block'>;
  readonly autofocus?: boolean;
  readonly interface?: MarkupInterface;
  readonly placeholder?: JSX.Element;
  processor?: MarkupStringProcessor;
  readonly links?: DataList<DataSchema, 'BlockLink'>;
  onkeydown?(ev: KeyboardEvent): void;
  onfocus?(element: HTMLElement): void;
  onblur?(element: HTMLElement): void;
  onEdgeDelete?(direction: 'forward' | 'backward'): void;
}

export function Markup<T extends MarkupString | string>(props: MarkupProps<T>) {
  const processor = (props.processor ??= new MarkupStringProcessor(
    store({
      get value() {
        return props.value || null;
      },
      set value(value) {
        if (!props.readonly) {
          props.value = value;
        }
      },
    }),
  ));

  const state = store({
    get selection() {
      return props.element ? documentSelection.relateTo(props.element) : null;
    },
    get selectedEntities() {
      return this.selection == null
        ? []
        : processor.getEntities(this.selection.from);
    },
  });

  const markupTreeProps = store<MarkupTreeProps>({
    processor,
    links: props.links,
    get readonly() {
      return props.readonly;
    },
    get tree() {
      return processor.tree;
    },
    get interface() {
      return props.interface || singleBlockMarkupInterface;
    },
    get selectedEntities() {
      return state.selectedEntities;
    },
  });

  props.onkeydown = bindContext(keyDownHandler(props, markupTreeProps));

  if (props.outline) {
    props.outline.taskbar = (
      <Toolbar>
        <MarkupToolbar
          editor={markupTreeProps}
          locked={bind(
            () => !!props.outline?.selectionLocked || false,
            (value) => {
              if (props.outline) props.outline.selectionLocked = value;
            },
          )}
          selection={bind(() => state.selection)}
        />
      </Toolbar>
    );
  }

  // let hasFocus = false;

  // const onfocus = props.onfocus;
  // props.onfocus = () => {
  //   if (props.outline) props.outline.selected = true;
  //   if (!hasFocus) {
  //     hasFocus = true;
  //     onfocus?.();
  //   }
  // };

  // const onblur = props.onblur;
  // props.onblur = () => {
  //   if (props.outline) props.outline.selected = false;
  //   if (hasFocus) {
  //     hasFocus = false;
  //     onblur?.();
  //   }
  // };

  // live((first) => {
  //   const isOutlineSelected = props.outline?.selected;

  //   dontWatch(() => {
  //     if ((first && props.autofocus) || (isOutlineSelected && !hasFocus)) {
  //       if (!hasFocus) {
  //         hasFocus = true;
  //         onfocus?.();
  //       }
  //       dom.tick(() => {
  //         if (!props.element) return;
  //         if (
  //           (first && props.autofocus) ||
  //           (hasFocus && props.outline?.selected)
  //         ) {
  //           documentSelection.set({
  //             element: props.element,
  //             from: 0,
  //             to: props.element!.textContent?.length ?? 0,
  //           });
  //         }
  //       });
  //     } else if (!isOutlineSelected && hasFocus) {
  //       if (hasFocus) {
  //         hasFocus = false;
  //         onblur?.();
  //       }
  //       dom.tick(() => {
  //         if (
  //           !hasFocus &&
  //           props.element &&
  //           documentSelection.relateTo(props.element)
  //         ) {
  //           documentSelection.clear();
  //         }
  //       });
  //     }
  //   });
  // });

  // // Fix selection after placeholder focus

  return (
    <>
      {jsxx(MarkupTree, markupTreeProps)}
      {
        // Browser doesn't render a single trailing newline
        () => (props.value?.endsWith('\n') ? '\n' : null)
      }
    </>
  );
}
