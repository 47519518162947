import {
  getNow,
  getUserContext,
  session,
  setFormulaContext,
} from '@donkeyjs/client';
import { bindContext, getContext, setContext } from '@donkeyjs/jsx-runtime';
import { meta, store, type DataList, type DataNode } from '@donkeyjs/proxy';
import { addMinutes } from 'date-fns';
import slug from 'slug';

const key = Symbol('edition');

export const initializeEditions = () => {
  const user = getUserContext();

  const updateFormulaContext = bindContext((edition: string) => {
    setFormulaContext({ edition });
  });

  const editions = session.data.getEditions({});

  meta(editions).request({ name: true });

  return setContext(
    key,
    store({
      all: editions,

      get current() {
        const edition = session.router.query.edition?.[0];
        const result =
          (edition &&
            editions.find((e) => e.name && slug(e.name) === edition)) ||
          editions.find((e) => e.published)!;
        updateFormulaContext(result.id);
        return result;
      },

      get featured() {
        const result =
          editions.find((e) => e.lastDay.getTime() > getNow().getTime()) ||
          editions[0]!;
        return result;
      },

      get isInProgress() {
        const now = getNow();
        return !!(
          this.current &&
          this.current.firstDay.getTime() <= now.getTime() &&
          this.current.lastDay.getTime() >= now.getTime()
        );
      },

      get isPast() {
        const now = getNow();
        return !!(
          this.current && this.current.lastDay.getTime() < now.getTime()
        );
      },

      get isEventHidden() {
        return (event: DataNode<DataSchema, 'Event'>) => {
          return (
            this.isInProgress &&
            event.starts < new Date(getNow().getTime() - 1000 * 60 * 60)
          );
        };
      },

      get isEventInProgress() {
        return (event: DataNode<DataSchema, 'Event'>) => {
          return (
            event.starts < getNow() && addMinutes(event.starts, 60) > getNow()
          );
        };
      },

      get isPastEvent() {
        return (event: DataNode<DataSchema, 'Event'>) => {
          return event.starts <= getNow();
        };
      },

      checkStartsEnds(events: DataList<DataSchema, 'Event'>) {
        const current = this.current;

        if (
          !events.length ||
          meta(events).isLoading ||
          !current ||
          meta(current).isLoading ||
          !user.can('update', 'Edition')
        )
          return;

        const starts = events.reduce<Date | undefined>(
          (prev, curr) =>
            curr.edition === current &&
            curr.starts &&
            (!prev || curr.starts < prev)
              ? curr.starts
              : prev,
          undefined,
        );
        let ends = events.reduce<Date | undefined>(
          (prev, curr) =>
            curr.edition === current && curr.ends && (!prev || curr.ends > prev)
              ? curr.ends
              : prev,
          undefined,
        );
        if (ends) ends = addMinutes(ends, 15);

        if (starts && starts.getTime() !== this.current.firstDay.getTime()) {
          this.current.firstDay = starts;
        }

        if (ends && ends.getTime() !== this.current.lastDay.getTime()) {
          this.current.lastDay = ends;
        }
      },
    }),
  );
};

export const useEditions = (): ReturnType<typeof initializeEditions> =>
  getContext(key);
