import { Heading, createView, session } from '@donkeyjs/client';
import { bind, getContext } from '@donkeyjs/jsx-runtime';
import { NewsContainer, type NewsContext } from './NewsContainer';
import styles from './ViewArticle.module.css';
import { text } from '@donkeyjs/client';

export const ViewArticle = createView<DataSchema, 'Article'>(
  {
    name: () => 'Default',
    renderContainer: (list, children) => (
      <NewsContainer data={list.data!}>{children}</NewsContainer>
    ),
  },

  function ViewArticle(props) {
    const state = getContext<NewsContext>('news');

    return () =>
      state.feature === props.node ||
      (props.node.archived && props.readonly) ? null : (
        <article
          class={bind(() => [
            styles.article,
            {
              [styles.archived]: props.node.archived,
            },
          ])}
        >
          <div class={styles.image}>
            <props.node.$.image ratio={1.5} />
          </div>
          <div class={styles.date}>
            <props.node.$.publishedAt formatter="PPPP" readonly />
          </div>
          <Heading styleAs="heading1">
            <props.node.$.headline readonly />
          </Heading>
          <div class={styles.lead}>
            <props.node.$.lead readonly />
          </div>
          <a
            class={styles.link}
            href={bind(() => session.router.getPath({ node: props.node }))}
          >
            <span>{text('Common.ReadMore')}</span>
          </a>
        </article>
      );
  },
);
