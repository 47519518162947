import type { ClientSchemaMeta } from '@donkeyjs/client';
import { PhCalendarX } from '@donkeyjs/phosphor-icons';
import type { SchemaMeta } from '@donkeyjs/proxy';

export const schemaMeta: SchemaMeta<DataSchema> = {
  Event: {
    searchVectors: {
      name: 'A',
      'venue.name': 'B',
    },
  },
};

export const clientSchemaMeta: ClientSchemaMeta<DataSchema> = {
  Edition: {
    icon: () => PhCalendarX,
  },
};
