import type { I18nBlocksEnGB } from '@donkeyjs/i18n-en-gb';

export const I18nBlocksNl: typeof I18nBlocksEnGB = {
  values: {
    Actions: {},
    BlockTypes: {
      H1: 'Kop',
      H2: 'Subkop',
      OL: 'Genummerde lijst',
      P: 'Paragraaf',
      QUOTE: 'Citaat',
      T2: 'Ondertitel',
      T1: 'Titel',
      UL: 'Lijst',
      EMBED: 'Media',
      CELL: 'Cel',
      LAYOUT: 'Layout',
      IMAGE: 'Afbeelding',
      MODULE: 'Module',
    },
    Links: {
      Create: 'Link maken',
      Edit: 'Link bewerken',
      Remove: 'Link verwijderen',
      Instructions:
        'Voer een URL of e-mailadres in, of selecteer een item uit de lijst.',
    },
    Markup: {
      Align: {
        Center: 'Centreren',
        Left: 'Links uitlijnen',
        Right: 'Rechts uitlijnen',
      },
      Bold: 'Vet',
      Italic: 'Cursief',
      Strikethrough: 'Doorhalen',
    },
  },
};
