import { formatName } from '@donkeyjs/core';
import {
  PhFile,
  PhFileText,
  PhGlobe,
  PhImage,
  PhSpeakerHigh,
  PhTextT,
  PhVideoCamera,
} from '@donkeyjs/phosphor-icons';
import { getBlockIcon } from '../layout/fields/blocks/helpers/getBlockIcon';
import type { ClientSchemaMeta } from './clientSchemaMetaUtils';

export const baseClientSchemaMeta: ClientSchemaMeta<DataSchema> = {
  App: {
    icon: () => PhGlobe,
  },
  Block: {
    icon: (node) => (node && getBlockIcon(node.type)) || PhTextT,
    asString: (node) => node.text?.toString() || '',
  },
  File: {
    icon: (node) =>
      !node
        ? PhFile
        : node.fileType.startsWith('image/')
          ? PhImage
          : node.fileType.startsWith('text/')
            ? PhFileText
            : node.fileType.startsWith('audio/')
              ? PhSpeakerHigh
              : node.fileType.startsWith('video/')
                ? PhVideoCamera
                : PhFile,
  },
  FileRef: {
    asString: (node) => node.name || node.file?.toString() || '',
  },
  Message: {
    asString: (node) => node.subject,
  },
  Place: {
    asString: (node) =>
      [node.name, node.city, node.country].filter(Boolean).join(', '),
  },
  Route: {
    icon: () => PhFile,
  },
  User: {
    asString: (node) => {
      const name = formatName(node);
      return name
        ? [name, node.email && `(${node.email})`].filter(Boolean).join(' ')
        : node.email;
    },
  },
};
