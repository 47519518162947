import { defaultPermissions } from '@donkeyjs/core';

export const permissions = defaultPermissions().with({
  visitor: {
    Article: {
      read: 'allow',
    },
    Artist: {
      read: 'allow',
    },
    District: {
      read: 'allow',
    },
    EventArtist: {
      read: 'allow',
    },
    EventTag: {
      read: 'allow',
    },
    Place: {
      read: 'allow',
    },
  },
});
