import { session, type MenuRoute } from '@donkeyjs/client';
import { bind } from '@donkeyjs/jsx-runtime';
import { ClickAway } from './ClickAway';
import styles from './PopperMenu.module.css';

interface PopperMenuProps {
  open: boolean;
  onmount: JSX.OnMount<HTMLDivElement>;
  routes: MenuRoute[];
}

export function PopperMenu(props: PopperMenuProps) {
  return (
    <>
      <div
        class={bind(() => [styles.popper, { [styles.open]: props.open }])}
        onmount={props.onmount}
      >
        {() =>
          props.routes
            .filter((route) => route.route.node?.key !== 'festival')
            .map((route) => (
              <a
                href={bind(() =>
                  session.router.getPath({ route: route.route }),
                )}
                class={bind(() => ({ active: route.isActive }))}
                onclick={() => {
                  props.open = false;
                }}
              >
                {() => route.route.name}
              </a>
            ))
        }
      </div>
      {() => props.open && <ClickAway onclick={() => (props.open = false)} />}
    </>
  );
}
