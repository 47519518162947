import { getContext, getGlobal, setContext } from '@donkeyjs/jsx-runtime';
import {
  meta,
  type DataNode,
  type FieldSchema,
  type Schema,
} from '@donkeyjs/proxy';

const key = Symbol('node-context');
const providersKey = Symbol('node-context-providers');

export interface NodeContext {
  readonly node: DataNode<Schema> | null;
  readonly fields: string[];
  fieldSchema(name: string): FieldSchema | undefined;

  readonly steps?: DataNode<DataSchema, 'Block'>[] | undefined;
  step?: DataNode<DataSchema, 'Block'> | undefined;
  goNextStep(): void;
  goPreviousStep(): void;
}

interface NodeContextProvider {
  name: () => string;
  get: () => DataNode<Schema> | null | undefined;
}

export interface NodeContextProviders {
  providers: Record<string, NodeContextProvider>;
  register: (key: string, provider: NodeContextProvider) => void;
}

export function getNodeContextProviders() {
  return getGlobal<NodeContextProviders>(providersKey, () => ({
    providers: {},
    register(name, provider) {
      this.providers[name] = provider;
    },
  }));
}

export function setNodeContext(
  context: Pick<NodeContext, 'node' | 'steps' | 'step'>,
) {
  setContext<NodeContext>(key, {
    get node() {
      return context.node;
    },
    get steps() {
      return context.steps;
    },
    get step() {
      return context.step;
    },
    set step(value) {
      context.step = value;
    },
    get fields() {
      return context.node
        ? [
            ...Object.keys(meta(context.node).schema?.fields || {}),
            ...Object.keys(meta(context.node).schema?.reverseFields || {}),
          ]
        : [];
    },
    goNextStep() {
      const steps = context.steps;
      if (steps && context.step) {
        const index = steps.indexOf(context.step);
        if (index < steps.length - 1) {
          context.step = steps[index + 1];
        }
      }
    },
    goPreviousStep() {
      const steps = context.steps;
      if (steps && context.step) {
        const index = steps.indexOf(context.step);
        if (index > 0) {
          context.step = steps[index - 1];
        }
      }
    },
    fieldSchema(name) {
      const nodeSchema = meta(context.node)?.schema;
      return nodeSchema?.fields[name] || nodeSchema?.reverseFields[name];
    },
  });
}

export function getNodeContext() {
  return getContext<NodeContext | undefined>(key);
}
