import { meta, type Culture, type DataNode } from '@donkeyjs/proxy';
import slug from 'slug';
import type { RouteDefinition } from '../createRouter';

export type RoutesFromDbCache = {
  [culture in Culture]?: WeakMap<
    DataNode<DataSchema, 'Route'>,
    RouteDefinition
  >;
};

export const createRouteDefinitionsFromDb = (
  routes: DataNode<DataSchema, 'Route'>[],
  systemRoutes: RouteDefinition[],
  cultures: Culture[],
  cache: RoutesFromDbCache,
): { [culture in Culture]?: RouteDefinition[] } => {
  const result: { [culture in Culture]?: RouteDefinition[] } = {};
  for (const culture of cultures) {
    result[culture] = mapCulture(
      routes,
      systemRoutes,
      culture,
      (cache[culture] ??= new WeakMap()),
    );
  }

  return result;
};

function mapCulture(
  routes: DataNode<DataSchema, 'Route'>[],
  systemRoutes: RouteDefinition[],
  culture: Culture,
  cache: NonNullable<RoutesFromDbCache[Culture]>,
) {
  const result: RouteDefinition[] = [];

  const mapLevel = (
    parent: RouteDefinition | undefined,
    routes: DataNode<DataSchema, 'Route'>[],
    prefix: string,
    firstPathname: string | false,
  ) => {
    routes.forEach((input, index) => {
      const route = meta(input).getCulture(culture);
      const hasChildren = !meta(route).isLoading && !!route.children.length;
      const isLinkToFirstChild = hasChildren && route.redirect !== '-';

      const segment = `${route.pathPrefix || ''}${slug(
        route.name || route.id,
      )}`;
      const newPathname = `${prefix}/${segment}`;
      const resultRoute =
        cache.get(route) ??
        ({
          id: route.id,
          node: route,
          get name() {
            return route.name;
          },
        } as RouteDefinition);
      Object.assign(resultRoute, {
        parent,
        pathname:
          index === 0 && firstPathname !== false
            ? firstPathname || '/'
            : newPathname,
        fullPathname: newPathname,
        children: [],
      });
      cache.set(route, resultRoute);
      result.push(resultRoute);
      if (hasChildren) {
        mapLevel(
          resultRoute,
          route.children,
          newPathname,
          !isLinkToFirstChild
            ? false
            : index === 0
              ? firstPathname
              : newPathname,
        );
      }
      if (parent && resultRoute) {
        (parent.children ??= []).push(resultRoute);
      }
    });
  };

  mapLevel(
    undefined,
    routes.filter((route) => meta(route).isLoading || !route.parentRoute),
    '',
    '',
  );

  result.push(...systemRoutes);

  return result;
}
