import {
  store,
  type AppSchema,
  type Culture,
  type I18nSchema,
  type NodeTypename,
} from '@donkeyjs/proxy';
import { humanify } from './humanify';

export const getSchemaI18n = <S extends AppSchema>(
  schema: S,
  culture: Culture,
): SchemaI18n<S> => {
  schema.i18n.values ??= {};
  return (schema.i18n.values[culture] ??= new SchemaI18n(schema, culture));
};

class SchemaI18n<S extends AppSchema> {
  private state: { loading: boolean; value?: I18nSchema<any, any> };
  public readonly schema: S;
  public readonly culture: Culture;

  constructor(schema: S, culture: Culture) {
    this.schema = schema;
    this.culture = culture;
    this.state = store({ loading: true });
    const loaders = schema.i18n.loaders.map((l) => l[culture]!).filter(Boolean);
    // TODO: something like getLazyResource(...) to fix SSR hydration
    Promise.all(loaders.map((l) => l())).then((values) => {
      this.state.loading = false;
      this.state.value = values.reduce<Required<I18nSchema<any, any>>>(
        (acc, val) => {
          Object.assign(acc.enums, val.enums);
          Object.assign(acc.groups, val.groups);
          Object.assign(acc.nodes, val.nodes);
          return acc;
        },
        {
          enums: {},
          groups: {},
          nodes: {},
        },
      );
    });
  }

  public getNodeName(
    type: NodeTypename<S>,
    options?: { pluralize?: (value: string) => string },
  ): string {
    if (this.state.loading || !type) return '';

    const result =
      this.state.value?.nodes?.[type]?.__typename || humanify(type) || '';
    const [singular, plural] =
      typeof result === 'string' ? [result, undefined] : result;
    return options?.pluralize
      ? plural || options.pluralize(singular)
      : singular;
  }

  public getFieldName<Typename extends NodeTypename<S>>(
    type: Typename,
    field: Extract<keyof S['nodes'][Typename]['fields'], string>,
  ): string {
    if (this.state.loading) return '';

    const result =
      this.state.value?.nodes?.[type]?.[field] || humanify(field) || '';
    const [singular] = typeof result === 'string' ? [result] : result;
    return singular;
  }

  public getGroupName<Typename extends NodeTypename<S>>(
    type: Typename,
    group: string,
  ): string {
    if (this.state.loading) return '';

    return this.state.value?.groups?.[type]?.[group] || humanify(group) || '';
  }

  public getEnumValue<Enum extends keyof S['enums']>(
    name: Enum,
    key: Extract<
      S['enums'][Enum]['values'] extends readonly (infer U)[] ? U : never,
      string
    >,
  ): string {
    if (this.state.loading) return '';

    return this.state.value?.enums?.[name]?.[key] || humanify(key);
  }
}
