import type { Views } from '@donkeyjs/client';
import { ViewArticle } from './ViewArticle';
import { ViewArticleHome } from './ViewArticleHome';
import { ViewArtistThumbnail } from './ViewArtist';
import { ViewEvent } from './ViewEvent';
import { ViewEventArtistProperties } from './ViewEventArtistProperties';

export const views: Views = {
  Article: {
    default: ViewArticle,
    home: ViewArticleHome,
  },
  Artist: {
    default: ViewArtistThumbnail,
  },
  Event: {
    default: ViewEvent,
  },
  EventArtist: {
    'properties-list': ViewEventArtistProperties,
  },
};
