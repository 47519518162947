import { WithHeading, createView, session } from '@donkeyjs/client';
import { bind, live } from '@donkeyjs/jsx-runtime';
import { useEditions } from '@donkeyjs/module-festival';
import { PhCaretRight } from '@donkeyjs/phosphor-icons';
import {
  map,
  meta,
  store,
  type DataList,
  type DataNode,
} from '@donkeyjs/proxy';
import { getEventsContext } from '../helpers/getEventsContext';
import { FestivalContainer } from './FestivalContainer';
import styles from './ViewEvent.module.css';

export const ViewEvent = createView<DataSchema, 'Event'>(
  {
    name: () => 'Summary',
    groupings: [
      {
        key: 'date',
        name: () => 'Date',
        format(node, i18n) {
          return i18n.formatDate(node.starts, 'EEEE d MMMM');
        },
        renderContainer: (group, _props, children) => (
          <GroupContainer group={group}>{children}</GroupContainer>
        ),
      },
    ],
    renderContainer: (list, children) => (
      <FestivalContainer
        data={bind(() => list.data as DataList<DataSchema, 'Event'>)}
      >
        {children}
      </FestivalContainer>
    ),
  },

  function ViewEvent(props) {
    const editions = useEditions();
    const context = getEventsContext();
    const pastKey = `showPast-${props.node.starts.getDate()}`;

    meta(props.node).request({
      district: {
        name: true,
      },
    });
    live(() => {
      if (props.readonly) return;
      if (props.node.ends !== null && props.node.ends < props.node.starts) {
        props.node.ends = props.node.starts;
      }
    });

    const state = store({
      get isHistory() {
        return editions.isInProgress && editions.isPastEvent(props.node);
      },
    });

    return (
      <a
        class={bind(() => [
          styles.event,
          {
            [styles.hidden]:
              !context[pastKey] && editions.isEventHidden(props.node),
          },
        ])}
        href={session.router.getPath(
          { node: props.node },
          { query: session.router.query },
        )}
        onmount={props.onmount}
      >
        <article
          class={bind(() => ({
            [styles.selected]: props.selected,
            [styles.history]: state.isHistory,
            [styles.hasPhotos]: !!props.node.gallery.length,
          }))}
        >
          <div class={styles.image}>
            <props.node.$.image ratio="adaptive" readonly />
          </div>
          <div class={styles.info}>
            <WithHeading
              styleAs="heading1"
              heading={
                <>
                  <span>
                    <props.node.$.starts readonly formatter="p" />
                  </span>
                  {() => props.node.name}
                </>
              }
            >
              <p>
                <props.node.$.description readonly />
              </p>
            </WithHeading>
          </div>
          {() =>
            !!props.node.gallery.length && (
              <div class={styles.photos}>
                <Photos refs={props.node.gallery} />
              </div>
            )
          }
          <div
            class={styles.tags}
            style={props.node.gallery.length ? 'display: none' : ''}
          >
            {() => props.node.venue && <span>{props.node.venue.name}</span>}
            {() =>
              props.node.artists
                .slice(0, 3)
                .map((artist) => <span>{artist.artist.name}</span>)
            }
          </div>
        </article>
      </a>
    );
  },
);

interface GroupContainerProps {
  group: { name: string; items: DataNode<DataSchema, 'Event'>[] };
  children?: JSX.Children | undefined;
}

function GroupContainer(props: GroupContainerProps) {
  const editions = useEditions();
  const context = getEventsContext();
  const pastKey = `showPast-${props.group.items[0].starts.getDate()}`;

  const state = store({
    get hidden() {
      return props.group.items.filter(editions.isEventHidden);
    },
    get photos() {
      return props.group.items
        .reduce<DataNode<DataSchema, 'FileRef'>[]>((photos, event) => {
          photos.push(...event.gallery);
          return photos;
        }, [])
        .slice(0, 10);
    },
  });

  return (
    <WithHeading
      heading={bind(() => props.group.name)}
      styleAs="subtitle"
      onmount={(el) => {
        if (state.hidden.length < props.group.items.length) {
          const heading = {
            date: props.group.items[0].starts,
            name: props.group.name.split(' ')[0],
            element: el,
          };
          context.headings = [...(context.headings ?? []), heading].sort(
            (a, b) => a.date.getTime() - b.date.getTime(),
          );
          context.currentHeading = context.headings[0];
          return () => {
            context.headings = context.headings?.filter((h) => h !== heading);
          };
        }
      }}
    >
      {() =>
        !!state.photos.length &&
        !context[pastKey] && <Photos refs={state.photos} />
      }
      {() =>
        state.hidden.length ? (
          <button
            type="button"
            class={styles.containerHidden}
            onclick={(ev: MouseEvent) => {
              ev.preventDefault();
              context[pastKey] = !context[pastKey];
            }}
          >
            <span>
              {() =>
                state.photos.length
                  ? 'Bekijk de foto’s'
                  : `${state.hidden.length} verborgen`
              }
            </span>
            <span
              class={bind(() => [
                styles.chevron,
                { [styles.open]: context[pastKey] },
              ])}
            >
              <PhCaretRight weight="bold" />
            </span>
          </button>
        ) : null
      }
      {props.children}
    </WithHeading>
  );
}

interface PhotosProps {
  refs: DataNode<DataSchema, 'FileRef'>[];
}

function Photos(props: PhotosProps) {
  return (
    <div class={styles.gallery}>
      {map(
        () => props.refs,
        (photo) => (
          <div class={styles.galleryPhoto}>
            <photo.$.file ratio={1} readonly />
          </div>
        ),
      )}
    </div>
  );
}
