import { createI18nCulture } from '@donkeyjs/core';

export const I18nSystemEnGB = createI18nCulture({
  values: {
    Account: {
      ActionChangePassword: 'Change password',
      ActionLogin: 'Sign in',
      ActionLogout: 'Sign out',
      ActionRegister: 'Create account',
      ActionResetPassword: 'Reset password',
      ConfirmNewPassword: 'Confirm new password',
      ConfirmPassword: 'Confirm password',
      CurrentPassword: 'Current password',
      EmailAddress: 'Email address',
      ForgotPassword: 'Forgot your password?',
      HeaderLogin: 'Login',
      HeaderRegister: 'Register account',
      NewAccountEmail:
        'Enter your email address to start setting up an account.',
      NewPassword: 'New password',
      Password: 'Password',
      PasswordUpdated:
        'Your password has been updated. From now on, use your new password to log in.',
      ResetPasswordExpired:
        'The link you used for resetting your password is invalid or has expired.',
      ResetPasswordInstruction: 'Please enter your new password below.',
      ResetPasswordRequested:
        'If there’s an account associated with the provided email address, you’ll receive a link in your inbox to reset your password.',
      WarnPasswordEmpty: 'Enter your password.',
      WarnPasswordInvalid:
        'Your new password should be at least 8 characters long and contain both letters and numbers.',
      WarnPasswordNoMatch:
        "The password fields don't match. Enter the same password twice.",

      WarnUsernameEmpty: 'Enter your username.',
    },
    Common: {
      Add: 'Add',
      And: 'and',
      Apply: 'Apply',
      BackToList: 'Back to list',
      Cancel: 'Cancel',
      Change: 'Change',
      Clear: 'Clear',
      Close: 'Close',
      Continue: 'Continue',
      Create: 'Create',
      CreateNode: (type: string) => `Create ${type}`,
      Default: 'Default',
      Delete: 'Delete',
      DeleteNode: (type: string) => `Delete ${type}`,
      Download: 'Download',
      Draft: 'Draft',
      DraftsCount: (count: number, open?: boolean) =>
        [
          open === false ? 'show' : 'hide',
          `${count} draft${count === 1 ? '' : 's'}`,
        ]
          .filter(Boolean)
          .join(' '),
      DraftXofY: (x: number, y: number) => `Draft ${x} of ${y}`,
      Edit: 'Edit',
      Find: 'Find',
      Insert: 'Insert',
      Loading: 'Loading',
      MoreInfo: 'More information',
      MoreInfoShort: 'More info',
      Next: 'Next',
      None: 'None',
      Ok: 'Ok',
      Or: 'or',
      Publish: 'Publish',
      Previous: 'Previous',
      ReadMore: 'Read more',
      Remove: 'Remove',
      Save: 'Save',
      Select: 'Select',
      ScrollToTop: 'Scroll to top',
      Search: 'Search',
      Send: 'Send',
      Share: 'Share',
      ShowMore: 'Show more',
      ShowLess: 'Show less',
      Submit: 'Submit',
      Unknown: 'Unknown',
      Update: 'Update',
    },
    ContactForm: {
      Email: 'Email',
      Message: 'Message',
      Name: 'Name',
      Subject: 'Subject',
      OptinMailingList: 'Subscribe to our newsletter',
      SentMessage: 'Thank you for your message!',
    },
    Data: {
      Filters: 'Filters',
      CloseFilters: 'Close filters',
    },
  },
  errors: {
    'auth-invalid-login': 'Invalid email address or password.',
    boundary: 'An error occurred while displaying this section.',
    // 'network-error':
    //   'A network error occurred. Check your internet connection and try again.',
    'no-embed-found': 'No embed found for this URL.',
    // 'page-not-found': 'Page not found.',
    unexpected: 'An unexpected error occurred.',

    // Validation
    'expected-array': 'This field expects an array of values.',
    'invalid-email': 'The email address is invalid.',
    'invalid-operation': 'Invalid operation.',
    'invalid-phone-number': 'The phone number is invalid.',
    required: 'This field is required.',
  },
});
