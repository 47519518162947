import type { Component } from '@donkeyjs/jsx-runtime';
import {
  PhArrowSquareOut,
  PhArrowsOut,
  PhEnvelopeSimple,
  PhFilmSlate,
  PhListBullets,
  PhQrCode,
  PhSignIn,
  PhSquaresFour,
  PhTable,
  PhTextAa,
} from '@donkeyjs/phosphor-icons';
import type { BlockPreset, BlockProps, BlocksType } from '.';
import { BlColumns } from '../icons/BlockIcons';
import { DataForm } from '../layout/components/DataForm';
import { DataFormStep } from '../layout/components/DataFormStep';
import { ButtonBlock } from './ButtonBlock';
import { CellBlock } from './CellBlock';
import { ColumnsBlock } from './ColumnsBlock';
import { ContactFormBlock } from './ContactFormBlock';
import { DataBlock } from './DataBlock';
import { DirectoryBlock } from './DirectoryBlock';
import { EmbedBlock } from './EmbedBlock';
import { FileBlock } from './FileBlock';
import { LayoutBlock } from './LayoutBlock';
import { LoginFormBlock } from './LoginFormBlock';
import { NodeFieldBlock } from './NodeFieldBlock';
import { QRCodeBlock } from './QRCodeBlock';
import { ReadMoreBlock } from './ReadMoreBlock';
import { SlideShowBlock } from './SlideShowBlock';

export const defaultBlocks: BlocksType = {
  button: { component: ButtonBlock },
  cell: { component: CellBlock },
  columns: { component: ColumnsBlock },
  'contact-form': { component: ContactFormBlock },
  data: { component: DataBlock },
  'data-form': { component: DataForm as Component<BlockProps> },
  'data-form-step': { component: DataFormStep },
  directory: { component: DirectoryBlock },
  embed: { component: EmbedBlock },
  file: { component: FileBlock },
  layout: { component: LayoutBlock },
  'qr-code': { component: QRCodeBlock },
  readmore: { component: ReadMoreBlock },
  'login-form': { component: LoginFormBlock },
  'slide-show': { component: SlideShowBlock },
  'node-field': { component: NodeFieldBlock },
};

export const defaultBlockPresets: BlockPreset[] = [
  {
    name: () => ' Text',
    icon: () => <PhTextAa weight="duotone" />,
    allowInMail: true,
    values: {
      type: 'P',
    },
  },
  {
    name: () => 'Read More',
    icon: () => <PhArrowsOut weight="duotone" />,
    values: {
      type: 'readmore',
    },
  },
  {
    name: () => 'Button',
    icon: () => <PhArrowSquareOut weight="duotone" />,
    allowInMail: true,
    values: {
      type: 'button',
    },
  },
  {
    name: () => 'Embed',
    icon: () => <PhFilmSlate weight="duotone" />,
    values: {
      type: 'embed',
    },
  },
  {
    name: () => 'Text Columns',
    group: () => 'Layout',
    icon: () => <BlColumns />,
    values: {
      type: 'columns',
      children: [{ type: 'P' }],
    },
  },
  {
    name: () => 'Layout',
    group: () => 'Layout',
    icon: () => <PhSquaresFour weight="duotone" />,
    values: {
      type: 'layout',
      children: [{ type: 'cell' }, { type: 'cell' }],
    },
  },
  {
    name: () => 'Directory',
    group: () => 'Widgets',
    icon: () => <PhListBullets weight="duotone" />,
    values: {
      type: 'directory',
    },
  },
  {
    name: () => 'Login Form',
    group: () => 'Widgets',
    icon: () => <PhSignIn weight="duotone" />,
    values: {
      type: 'login-form',
    },
  },
  {
    name: () => 'Contact Form',
    group: () => 'Widgets',
    icon: () => <PhEnvelopeSimple weight="duotone" />,
    values: {
      type: 'contact-form',
    },
  },
  {
    name: () => 'QR Code',
    group: () => 'Widgets',
    icon: () => <PhQrCode weight="duotone" />,
    values: {
      type: 'qr-code',
    },
  },
  {
    protected: true,
    name: () => 'Form',
    group: () => 'Forms',
    icon: () => <PhTable weight="duotone" />,
    values: {
      type: 'data-form',
      settings: { action: 'create', buttons: 'create', layout: 'html:flow' },
      children: [{ type: 'P' }],
    },
  },
];
