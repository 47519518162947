import { live, setState } from '@donkeyjs/jsx-runtime';
import { PhEnvelopeSimple, PhGlobeSimple } from '@donkeyjs/phosphor-icons';
import {
  EMAIL_REGEX,
  URL_REGEX_FORGIVING,
  batch,
  bind,
  meta,
  type DataNode,
  type Node,
} from '@donkeyjs/proxy';
import { useNodeSelect } from '../../data/useNodeSelect';
import { getI18n } from '../../i18n/getI18n';
import { isNodeType, session } from '../../session';
import styles from './LinkSelect.module.css';
import { Select } from './Select';

interface LinkSelectProps {
  value: Node | string | null;
  type?: 'node' | 'email' | 'url';
  autofocus?: boolean;
}

export function fixLinkSelectInput(value: any) {
  const type = getType(value);
  if (type === 'email') {
    return `mailto:${(value as string).trim()}`;
  }
  if (type === 'url') {
    let result = (value as string).trim();
    if (!result.startsWith('http://') && !value.startsWith('https://')) {
      result = `https://${result}`;
    }
    return result;
  }
  return value;
}

function getType(value: any) {
  if (!value) return undefined;
  if (typeof value !== 'string') return 'node';
  if (!value.trim()) return undefined;
  if (EMAIL_REGEX.test(value.trim())) return 'email';
  if (URL_REGEX_FORGIVING.test(value.trim())) return 'url';
  return undefined;
}

export function LinkSelect(props: LinkSelectProps) {
  const i18n = getI18n();

  const state = setState({
    search: typeof props.value === 'string' ? props.value : '',

    get text() {
      return state.search;
    },

    set text(value: string) {
      batch(() => {
        if (state.search === value) return;
        state.search = value;
        if (value) props.value = value;
      });
    },

    get value() {
      return typeof props.value === 'string' ? null : props.value;
    },

    set value(value) {
      props.value = value || null;
    },
  });

  live(() => {
    props.type = getType(props.value);
  });

  const types = Object.values(session.app.schema.nodes.BlockLink.fields)
    .map((value) => value.type)
    .filter((type) => type !== 'Block' && isNodeType(type));

  const nodes = useNodeSelect({
    types,
    get search() {
      return state.text
        ? {
            text: `${state.text.trim().split(/\s+/g).join(' & ')}:*`,
            culture:
              session.app.schema.cultures.length > 1 ? i18n.culture : undefined,
            mode: { __literal: 'TOKENS' } as const,
          }
        : undefined;
    },
  });

  return (
    <Select
      value={bind(state, 'value')}
      search={bind(state, 'text')}
      options={() => {
        nodes.load();
        return nodes.merged;
      }}
      prefix={(node) => <Prefix node={node} />}
      inputPrefix={() => {
        if (props.type === 'email') {
          return (
            <span class={styles.svgPrefix}>
              <PhEnvelopeSimple weight="duotone" />
            </span>
          );
        }
        if (props.type === 'url') {
          return (
            <span class={styles.svgPrefix}>
              <PhGlobeSimple weight="duotone" />
            </span>
          );
        }
        return null;
      }}
      allowEmpty
      allowSearchAfterBlur
      autofocus={props.autofocus}
    />
  );
}

function Prefix(props: { node: DataNode<DataSchema> }) {
  const i18n = getI18n();

  return () =>
    meta(props.node).isLoading ? null : (
      <span class={styles.prefix}>
        {() => i18n.getNodeName(props.node.__typename)}
      </span>
    );
}
